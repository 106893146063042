<template>
  <path
    d="M5.5 5C5.77614 5 6 5.22386 6 5.5V10.5C6 10.7761 5.77614 11 5.5 11C5.22386 11 5 10.7761 5 10.5V5.5C5 5.22386 5.22386 5 5.5 5Z"
    fill="#F88E86" />
  <path
    d="M8.5 5C8.77614 5 9 5.22386 9 5.5V10.5C9 10.7761 8.77614 11 8.5 11C8.22386 11 8 10.7761 8 10.5V5.5C8 5.22386 8.22386 5 8.5 5Z"
    fill="#F88E86" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10 1.5V2H13C13.2761 2 13.5 2.22386 13.5 2.5C13.5 2.77614 13.2761 3 13 3H12V11.5C12 12.8807 10.8807 14 9.5 14H4.5C3.11929 14 2 12.8807 2 11.5V3H1C0.723858 3 0.5 2.77614 0.5 2.5C0.5 2.22386 0.723858 2 1 2H4V1.5C4 0.671573 4.67157 0 5.5 0H8.5C9.32843 0 10 0.671573 10 1.5ZM3 3V11.5C3 12.3284 3.67157 13 4.5 13H9.5C10.3284 13 11 12.3284 11 11.5V3H3ZM5 2V1.5C5 1.22386 5.22386 1 5.5 1H8.5C8.77614 1 9 1.22386 9 1.5V2H5Z"
    fill="#F88E86" />
</template>
