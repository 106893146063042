<template>
  <path
    d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H69.3333C73.4755 0.5 76.8333 3.85787 76.8333 8V44C76.8333 48.1421 73.4755 51.5 69.3333 51.5H8.00001C3.85787 51.5 0.5 48.1421 0.5 44V8Z"
    fill="#212121" />

  <rect x="8" y="12" width="61.3333" height="28" rx="3" fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16.6646 22.5932C16.7785 22.7175 16.7785 22.9189 16.6646 23.0432L13.9541 26L16.6646 28.9568C16.7785 29.0811 16.7785 29.2825 16.6646 29.4068C16.5507 29.5311 16.366 29.5311 16.2521 29.4068L13.3354 26.225C13.2215 26.1007 13.2215 25.8993 13.3354 25.775L16.2521 22.5932C16.366 22.4689 16.5507 22.4689 16.6646 22.5932Z"
    fill="#9E9E9E" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M60.3354 22.5932C60.4493 22.4689 60.634 22.4689 60.7479 22.5932L63.6646 25.775C63.7785 25.8993 63.7785 26.1007 63.6646 26.225L60.7479 29.4068C60.634 29.5311 60.4493 29.5311 60.3354 29.4068C60.2215 29.2825 60.2215 29.0811 60.3354 28.9568L63.0459 26L60.3354 23.0432C60.2215 22.9189 60.2215 22.7175 60.3354 22.5932Z"
    fill="#9E9E9E" />
</template>
