<template>
  <path
    d="M0 8C0 3.58172 3.58172 0 8 0H58C62.4183 0 66 3.58172 66 8V40C66 44.4183 62.4183 48 58 48H8C3.58172 48 0 44.4183 0 40V8Z"
    fill="#212121" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.9749 9.52513C16.608 8.15829 14.392 8.15829 13.0251 9.52513C11.6583 10.892 11.6583 13.108 13.0251 14.4749C14.392 15.8417 16.608 15.8417 17.9749 14.4749C19.3417 13.108 19.3417 10.892 17.9749 9.52513ZM17.1637 10.5253C17.2711 10.612 17.2879 10.7694 17.2011 10.8768L15.2291 13.3183C15.1858 13.3719 15.1224 13.4052 15.0538 13.4105C14.9851 13.4158 14.9174 13.3925 14.8664 13.3463L13.8182 12.3937C13.716 12.3009 13.7085 12.1428 13.8013 12.0406C13.8942 11.9384 14.0523 11.9308 14.1545 12.0237L15.0066 12.798L16.8121 10.5626C16.8989 10.4552 17.0563 10.4385 17.1637 10.5253Z"
    fill="#AAAAAA" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.9749 21.5251C16.608 20.1583 14.392 20.1583 13.0251 21.5251C11.6583 22.892 11.6583 25.108 13.0251 26.4749C14.392 27.8417 16.608 27.8417 17.9749 26.4749C19.3417 25.108 19.3417 22.892 17.9749 21.5251ZM17.1637 22.5253C17.2711 22.612 17.2879 22.7694 17.2011 22.8768L15.2291 25.3183C15.1858 25.3719 15.1224 25.4052 15.0538 25.4105C14.9851 25.4158 14.9174 25.3925 14.8664 25.3463L13.8182 24.3937C13.716 24.3009 13.7085 24.1428 13.8013 24.0406C13.8942 23.9384 14.0523 23.9308 14.1545 24.0237L15.0066 24.798L16.8121 22.5626C16.8989 22.4552 17.0563 22.4385 17.1637 22.5253Z"
    fill="#AAAAAA" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.9749 33.5251C16.608 32.1583 14.392 32.1583 13.0251 33.5251C11.6583 34.892 11.6583 37.108 13.0251 38.4749C14.392 39.8417 16.608 39.8417 17.9749 38.4749C19.3417 37.108 19.3417 34.892 17.9749 33.5251ZM17.1637 34.5253C17.2711 34.612 17.2879 34.7694 17.2011 34.8768L15.2291 37.3183C15.1858 37.3719 15.1224 37.4052 15.0538 37.4105C14.9851 37.4158 14.9174 37.3925 14.8664 37.3463L13.8182 36.3937C13.716 36.3009 13.7085 36.1428 13.8013 36.0406C13.8942 35.9384 14.0523 35.9308 14.1545 36.0237L15.0066 36.798L16.8121 34.5626C16.8989 34.4552 17.0563 34.4385 17.1637 34.5253Z"
    fill="#AAAAAA" />
  <rect x="24.5" y="9.5" width="30" height="5" rx="2.5" fill="#474747" />
  <rect x="24.5" y="21.5" width="30" height="5" rx="2.5" fill="#474747" />
  <rect x="24.5" y="33.5" width="30" height="5" rx="2.5" fill="#474747" />
</template>
