<script setup lang="ts">
import { reactive, toRefs } from 'vue';
import type { DomainVerification } from '../../types/domain';
import { useDNSInfo } from './hooks/useDNSInfo';
import GTooltip from '../../base/GTooltip.vue';
import type { Maybe } from '../../types/common';

type Props = {
  domain: string;
  defaultDns: string;
  ipAddress: string;
  errorVerification?: Maybe<DomainVerification>[];
};

const props = defineProps<Props>();

const copyInfo = reactive<{ isCopied: boolean; copyLabel: string }>({ isCopied: false, copyLabel: '' });
const { domain, errorVerification } = toRefs<Props>(props);
let resetDnsTimeout: ReturnType<typeof setTimeout>;

const { domainInfo } = useDNSInfo(domain, errorVerification, { dns: props.defaultDns, ip: props.ipAddress });

const copyInfoToClipboard = (value: string, label: string) => {
  if (!value) return;
  navigator.clipboard.writeText(value);
  copyInfo.isCopied = true;
  copyInfo.copyLabel = label;
  clearTimeout(resetDnsTimeout);
  resetDnsTimeout = setTimeout(() => {
    copyInfo.isCopied = false;
    copyInfo.copyLabel = '';
  }, 3000);
};
</script>
<template>
  <div class="rounded-medium mt-16 flex w-full flex-row justify-between bg-white">
    <div class="flex flex-row justify-between">
      <div v-for="(info, index) in domainInfo" :key="info?.label" class="flex flex-col justify-between p-16">
        <span class="text-12 font-regular text-dark-400">{{ info?.label }}</span>
        <span class="text-14 font-regular text-text-light-300 flex items-end">
          <span class="truncate" :class="{ 'max-w-[270px]': index === 2, 'max-w-[120px]': index === 1 }">{{
            info?.value
          }}</span>
          <span class="ml-8 flex">
            <g-tooltip v-if="info?.label === 'Value' || Number(info.value?.length) > 40" placement="top">
              <span
                class="rounded-medium bg-light-300 hover:bg-light-500 cursor-pointer p-4"
                @click="copyInfoToClipboard(info.value || '', info.label)">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.27166 1.24512C5.44323 1.24512 4.77166 1.91669 4.77166 2.74512V3.75439H4.00146C3.17304 3.75439 2.50146 4.42597 2.50146 5.25439V13.2544C2.50146 14.0828 3.17304 14.7544 4.00146 14.7544H10.0015C10.8299 14.7544 11.5015 14.0828 11.5015 13.2544V12.9951H12.4998C13.3282 12.9951 13.9998 12.3235 13.9998 11.4951V2.74512C13.9998 1.91669 13.3282 1.24512 12.4998 1.24512H6.27166ZM10.5015 12.9951H6.27166C5.44323 12.9951 4.77166 12.3235 4.77166 11.4951V4.75439H4.00146C3.72532 4.75439 3.50146 4.97825 3.50146 5.25439V13.2544C3.50146 13.5305 3.72532 13.7544 4.00146 13.7544H10.0015C10.2776 13.7544 10.5015 13.5305 10.5015 13.2544V12.9951ZM5.77166 2.74512C5.77166 2.46897 5.99552 2.24512 6.27166 2.24512H12.4998C12.776 2.24512 12.9998 2.46897 12.9998 2.74512V11.4951C12.9998 11.7713 12.776 11.9951 12.4998 11.9951H6.27166C5.99552 11.9951 5.77166 11.7713 5.77166 11.4951V2.74512Z"
                    fill="#212121" />
                </svg>
              </span>
              <template #content>
                <div class="text-light-100 text-12">
                  {{
                    copyInfo.isCopied && copyInfo.copyLabel === info.label
                      ? 'Copied ' + info.label
                      : 'Copy ' + info.label
                  }}
                </div>
              </template>
            </g-tooltip>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>
