<template>
  <path
    d="M14 4.5C14.4142 4.5 14.75 4.83579 14.75 5.25C14.75 5.66421 14.4142 6 14 6C13.0942 6 12.5881 6.1695 12.3354 6.29582C12.3033 6.31186 12.275 6.32739 12.25 6.34205V13.6855C12.2706 13.6964 12.2933 13.7078 12.3182 13.7195C12.5751 13.8398 13.0875 14 14 14C14.4142 14 14.75 14.3358 14.75 14.75C14.75 15.1642 14.4142 15.5 14 15.5C12.9125 15.5 12.1749 15.3088 11.6818 15.0778C11.6167 15.0473 11.5562 15.0162 11.5 14.985C11.4438 15.0162 11.3833 15.0473 11.3182 15.0778C10.8251 15.3088 10.0875 15.5 9 15.5C8.58579 15.5 8.25 15.1642 8.25 14.75C8.25 14.3358 8.58579 14 9 14C9.91246 14 10.4249 13.8398 10.6818 13.7195C10.7067 13.7078 10.7294 13.6964 10.75 13.6855V6.34205C10.725 6.32739 10.6967 6.31186 10.6646 6.29582C10.4119 6.1695 9.9058 6 9 6C8.58579 6 8.25 5.66421 8.25 5.25C8.25 4.83579 8.58579 4.5 9 4.5C10.0942 4.5 10.8381 4.7055 11.3354 4.95418C11.3939 4.98345 11.4487 5.01316 11.5 5.04299C11.5513 5.01316 11.6061 4.98345 11.6646 4.95418C12.1619 4.7055 12.9058 4.5 14 4.5Z"
    fill="#AAAAAA" />
  <path
    d="M4.5 9.25C4.5 8.55964 5.05964 8 5.75 8H9C9.41421 8 9.75 7.66421 9.75 7.25C9.75 6.83579 9.41421 6.5 9 6.5H5.75C4.23122 6.5 3 7.73122 3 9.25V10.75C3 12.2688 4.23122 13.5 5.75 13.5H9C9.41421 13.5 9.75 13.1642 9.75 12.75C9.75 12.3358 9.41421 12 9 12H5.75C5.05964 12 4.5 11.4404 4.5 10.75V9.25Z"
    fill="#AAAAAA" />
  <path
    d="M14.125 6.5C13.7108 6.5 13.375 6.83579 13.375 7.25C13.375 7.66421 13.7108 8 14.125 8H14.25C14.9404 8 15.5 8.55964 15.5 9.25V10.75C15.5 11.4404 14.9404 12 14.25 12H14.125C13.7108 12 13.375 12.3358 13.375 12.75C13.375 13.1642 13.7108 13.5 14.125 13.5H14.25C15.7688 13.5 17 12.2688 17 10.75V9.25C17 7.73122 15.7688 6.5 14.25 6.5H14.125Z"
    fill="#AAAAAA" />
</template>
