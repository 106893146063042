<template>
  <path
    d="M17.5208 18.75C17.866 18.75 18.1458 18.4702 18.1458 18.125V1.875C18.1458 1.52982 17.866 1.25 17.5208 1.25C17.1757 1.25 16.8958 1.52982 16.8958 1.875V18.125C16.8958 18.4702 17.1757 18.75 17.5208 18.75Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.27084 7.5C6.27084 8.53553 7.11031 9.375 8.14584 9.375H14.3958C15.4314 9.375 16.2708 8.53553 16.2708 7.5V6.25C16.2708 5.21447 15.4314 4.375 14.3958 4.375H8.14584C7.11031 4.375 6.27084 5.21447 6.27084 6.25V7.5ZM8.14584 8.125C7.80067 8.125 7.52084 7.84518 7.52084 7.5V6.25C7.52084 5.90482 7.80067 5.625 8.14584 5.625H14.3958C14.741 5.625 15.0208 5.90482 15.0208 6.25V7.5C15.0208 7.84518 14.741 8.125 14.3958 8.125H8.14584Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.39584 15.625C3.36031 15.625 2.52084 14.7855 2.52084 13.75V12.5C2.52084 11.4645 3.36031 10.625 4.39584 10.625H14.3958C15.4314 10.625 16.2708 11.4645 16.2708 12.5V13.75C16.2708 14.7855 15.4314 15.625 14.3958 15.625H4.39584ZM3.77084 13.75C3.77084 14.0952 4.05067 14.375 4.39584 14.375H14.3958C14.741 14.375 15.0208 14.0952 15.0208 13.75V12.5C15.0208 12.1548 14.741 11.875 14.3958 11.875H4.39584C4.05067 11.875 3.77084 12.1548 3.77084 12.5V13.75Z"
    fill="currentColor" />
</template>
