<script setup lang="ts">
import type { icons } from '../../assets/icons';
import { computed } from 'vue';
import GBaseIcon from '../../base/GBaseIcon.vue';

const props = withDefaults(
  defineProps<{
    type?: 'informative' | 'success' | 'warning' | 'error' | 'loading';
    isShowClose?: boolean;
    content?: string;
    customIcon?: {
      iconName: keyof typeof icons;
      width: string;
      height: string;
      iconClass?: string;
    };
  }>(),
  {
    isShowClose: true,
  },
);

const emits = defineEmits<{
  (e: 'onClose'): void;
}>();

const isShowCloseButton = computed(() => props.isShowClose);

const data = computed(
  (): {
    iconName: keyof typeof icons;
    iconClass?: string;
    contentClass: string;
    class: string;
  } => {
    switch (props?.type) {
      case 'error':
        return {
          iconName: 'error24',
          contentClass: 'text-red-300',
          class: 'bg-red-100',
          iconClass: 'text-red-300',
        };
      case 'warning':
        return {
          iconName: props.customIcon ? props.customIcon?.iconName : 'Warning',
          contentClass: 'text-dark-500',
          class: 'bg-yellow-100',
          iconClass: `text-yellow-300 ${props.customIcon?.iconClass ?? ''}`,
        };

      default:
        return {
          iconName: props.customIcon ? props.customIcon?.iconName : 'info24',
          contentClass: 'text-light-500v2',
          class: 'bg-primary-100',
          iconClass: `text-primary-300 ${props.customIcon?.iconClass ?? ''}`,
        };
    }
  },
);

const handleClose = () => {
  emits('onClose');
};
</script>

<template>
  <div class="rounded-medium flex w-full items-start p-16" :class="data.class">
    <GBaseIcon
      :name="data.iconName"
      :width="customIcon?.width ?? '24'"
      :height="customIcon?.width ?? '24'"
      :view-box="`0 0 ${customIcon?.width ?? '24'} ${customIcon?.height ?? '24'}`"
      class="flex"
      :class="data.iconClass"></GBaseIcon>
    <div class="text-14 font-regular ml-8 flex-1 leading-6" :class="data.contentClass">
      <slot></slot>
    </div>
    <div
      v-if="isShowCloseButton"
      class="ml-8 w-[24px] min-w-[24px] cursor-pointer bg-transparent p-4 transition-all hover:bg-black hover:bg-opacity-10"
      @click="handleClose">
      <GBaseIcon name="close16" width="16" height="16" view-box="0 0 16 16" class="text-light-500v2 flex"></GBaseIcon>
    </div>
  </div>
</template>
