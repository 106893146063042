<template>
  <rect width="28" height="24" rx="3" fill="#4A4A4A" />
  <path
    d="M8 7.41281L11.996 6.023C11.996 6.023 12.4819 7.15631 14.004 7.15631C15.5261 7.15631 16.0066 6 16.0066 6L20 7.41281L19.3353 10.8978H17.3313V17.2H10.66V10.8957H8.666L8 7.41281Z"
    fill="#D6D6D6" />
  <rect x="34" width="28" height="24" rx="3" fill="#4A4A4A" />
  <path
    d="M42 7.41281L45.996 6.023C45.996 6.023 46.4819 7.15631 48.004 7.15631C49.5261 7.15631 50.0066 6 50.0066 6L54 7.41281L53.3353 10.8978H51.3313V17.2H44.66V10.8957H42.666L42 7.41281Z"
    fill="#D6D6D6" />
</template>
