import { onMounted, onUnmounted } from 'vue';

export const useKeyboardShortcut = ({ callback }: { callback: (event: KeyboardEvent) => void }) => {
  const keydownHandler = (event: KeyboardEvent) => {
    callback(event);
  };

  onMounted(() => {
    document.addEventListener('keydown', keydownHandler);
  });
  onUnmounted(() => {
    document.removeEventListener('keydown', keydownHandler);
  });
};
