<script lang="ts" setup>
import { GBaseIcon, GTooltip } from '@gem/uikit';
import { computed } from 'vue';
import type { GSidebarTabItemButtonProps } from '../types';
import { cn } from '../../helpers/common';
import CountBadge from '../../components/CountBadge.vue';

const emit = defineEmits<(e: 'click', event: Event) => void>();
const props = withDefaults(defineProps<GSidebarTabItemButtonProps>(), {
  icon: 'more-setting-20',
  iconViewBox: '0 0 20 20',
  active: false,
  width: '40px',
  highlight: false,
  newFeature: false,
  customColorActive: 'bg-dark-250',
  customColorHover: 'hover:bg-dark-150',
});

const widthStyle = computed(() => {
  if (props.width) {
    return props.width === 'full' ? '100%' : props.width;
  }
  return '';
});

const newFeatureCls = computed(() => {
  if (!props.newFeature) return [];

  return [
    'hover:bg-underlay-dark-blue',
    'after:absolute',
    'after:top-[9px]',
    'after:right-8',
    'after:w-8',
    'after:h-8',
    'after:bg-transparent',
    'after:rounded-full',
    "after:content-['']",
    'after:ring-4',
    'after:ring-inset',
    'after:ring-primary-300',
    ...(props.active ? ['bg-underlay-dark-blue'] : []),
  ];
});

const handleClick = (e: Event) => {
  emit('click', e);
};
</script>

<template>
  <div>
    <GTooltip
      placement="right"
      class="w-full"
      :content-styles="{
        'z-index': 201,
      }"
      :margin-left="-4"
      :disabled="active">
      <div
        :class="
          cn([
            'relative flex aspect-square cursor-pointer items-center justify-center rounded-xl',
            'hover:text-text-dark-500',
            highlight ? 'hover:bg-purple-400' : customColorHover,
            active ? 'text-text-dark-500' : 'text-text-dark-300',
            active ? (highlight ? 'bg-purple-400' : customColorActive) : '',
            ...newFeatureCls,
          ])
        "
        :style="{
          width: widthStyle,
        }"
        @click="handleClick">
        <GBaseIcon :name="icon" :view-box="iconViewBox" width="20px" height="20px" />
        <CountBadge
          v-if="props.badge"
          class="bg-primary-500 absolute right-0 top-[-4px] z-[1] px-4 text-[11px] leading-[16px]"
          :customClass="badge.customClass"
          :number="props.badge.number" />
      </div>
      <template #content>
        <div class="text-text-dark-500 text-12">{{ name }}</div>
      </template>
    </GTooltip>
  </div>
</template>
