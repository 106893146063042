<template>
  <path
    d="M4.93359 15H3L6.52051 5H8.75684L12.2822 15H10.3486L7.67774 7.05078H7.59961L4.93359 15ZM4.99707 11.0791H10.2705V12.5342H4.99707V11.0791Z"
    fill="url(#paint0_linear_3128_38447)" />
  <path d="M15 5V15H13.1885V5H15Z" fill="url(#paint1_linear_3128_38447)" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14 2.91992C14 2.91952 14.0003 2.91919 14.0007 2.91919C15.5996 2.88408 16.8901 1.59811 16.9325 0.00105335C16.9325 0.000470327 16.933 0 16.9336 0C16.9342 0 16.9347 0.000470357 16.9347 0.00105338C16.9777 1.6204 18.3039 2.91992 19.9336 2.91992C18.2767 2.91992 16.9336 4.26307 16.9336 5.91992C16.9336 4.2855 15.6266 2.95635 14.0007 2.92066C14.0003 2.92065 14 2.92032 14 2.91992ZM16.9336 6C16.933 6 16.9326 5.99953 16.9326 5.99895C16.9333 5.97269 16.9336 5.94635 16.9336 5.91992C16.9336 5.94635 16.9339 5.97269 16.9346 5.99895C16.9346 5.99953 16.9342 6 16.9336 6ZM20 2.91992C20 2.92032 19.9997 2.92064 19.9993 2.92063C19.9774 2.92016 19.9555 2.91992 19.9336 2.91992C19.9555 2.91992 19.9774 2.91969 19.9993 2.91922C19.9997 2.91921 20 2.91953 20 2.91992Z"
    fill="url(#paint2_linear_3128_38447)" />
  <defs>
    <linearGradient
      id="paint0_linear_3128_38447"
      x1="17.4615"
      y1="4.99999"
      x2="5.16702"
      y2="2.94394"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="#D8E1FF" />
      <stop offset="1" stop-color="#C8ACFF" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_3128_38447"
      x1="17.4615"
      y1="4.99999"
      x2="5.16702"
      y2="2.94394"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="#D8E1FF" />
      <stop offset="1" stop-color="#C8ACFF" />
    </linearGradient>
    <linearGradient
      id="paint2_linear_3128_38447"
      x1="21.2308"
      y1="-3.80273e-06"
      x2="15.032"
      y2="-0.863874"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="#D8E1FF" />
      <stop offset="1" stop-color="#C8ACFF" />
    </linearGradient>
  </defs>
</template>
