<template>
  <path
    d="M28.6272 2.00862L3.27047 9.94579C3.17042 9.97914 3.11484 10.0903 3.13707 10.2015L5.09357 16.6379C5.12692 16.738 5.23808 16.8047 5.33813 16.7713L23.936 10.9463C24.8253 10.6684 25.5701 10.0458 26.0037 9.2121C27.1598 6.9888 28.1158 4.67657 28.8495 2.27541C28.9162 2.09755 28.7828 1.96415 28.6272 2.00862Z"
    fill="#FFBB1C" />
  <path
    d="M22.5462 13.1363L6.79417 18.0609C6.70523 18.0942 6.64965 18.1831 6.683 18.2721L8.33936 23.708C8.37271 23.797 8.46164 23.8525 8.53945 23.8192L18.5888 20.6732C19.3447 20.4398 19.9672 19.9062 20.334 19.2059C21.3123 17.3272 22.1238 15.3707 22.7352 13.3475C22.7908 13.2141 22.6685 13.0918 22.5462 13.1363Z"
    fill="#E8486C" />
  <path
    d="M16.5555 23.0745L9.98571 25.1311C9.90789 25.1533 9.87454 25.2311 9.89678 25.3089L11.2975 29.9001C11.3197 29.9779 11.3975 30.0223 11.4753 29.989L13.2206 29.4443C13.8542 29.2442 14.3878 28.7995 14.6991 28.1992C15.5328 26.6096 16.2109 24.9643 16.7334 23.2413C16.7668 23.1412 16.6667 23.0301 16.5555 23.0745Z"
    fill="#476DF2" />
</template>
