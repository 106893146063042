<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10 7.25C9.30964 7.25 8.75 7.80964 8.75 8.5C8.75 8.91421 8.41421 9.25 8 9.25C7.58579 9.25 7.25 8.91421 7.25 8.5C7.25 6.98122 8.48122 5.75 10 5.75C11.5188 5.75 12.75 6.98122 12.75 8.5C12.75 9.66431 12.0267 10.6579 11.0077 11.0593C10.905 11.0997 10.8259 11.1569 10.7816 11.2066C10.7596 11.2312 10.7522 11.2463 10.75 11.2526C10.7486 11.6656 10.4133 12 10 12C9.58579 12 9.25 11.6642 9.25 11.25C9.25 10.3853 9.94646 9.86517 10.4579 9.6637C10.923 9.48047 11.25 9.02751 11.25 8.5C11.25 7.80964 10.6904 7.25 10 7.25Z"
    fill="currentColor" />
  <path
    d="M10 14.5C10.5523 14.5 11 14.0523 11 13.5C11 12.9477 10.5523 12.5 10 12.5C9.44772 12.5 9 12.9477 9 13.5C9 14.0523 9.44772 14.5 10 14.5Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17ZM10 15.5C13.0376 15.5 15.5 13.0376 15.5 10C15.5 6.96243 13.0376 4.5 10 4.5C6.96243 4.5 4.5 6.96243 4.5 10C4.5 13.0376 6.96243 15.5 10 15.5Z"
    fill="currentColor" />
</template>
