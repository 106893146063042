<template>
  <path
    d="M3.77896 9.27096C3.55757 9.72355 3.5 10.0236 3.5 10.1055C3.5 10.1873 3.55757 10.4874 3.77896 10.94C3.98809 11.3675 4.31225 11.866 4.76168 12.3387C5.65224 13.2753 7.02612 14.1055 9 14.1055C9.41421 14.1055 9.75 14.4413 9.75 14.8555C9.75 15.2697 9.41421 15.6055 9 15.6055C6.5556 15.6055 4.80448 14.5606 3.67461 13.3723C3.11382 12.7824 2.70392 12.1559 2.43153 11.5991C2.1714 11.0673 2 10.5236 2 10.1055C2 9.68731 2.1714 9.14363 2.43153 8.61185C2.70392 8.055 3.11382 7.42849 3.67461 6.83868C4.80448 5.65033 6.5556 4.60547 9 4.60547C11.4322 4.60547 13.1197 5.5777 14.224 6.70581C15.3073 7.81237 15.8244 9.06067 15.9776 9.67357C16.0781 10.0754 15.8337 10.4826 15.4319 10.5831C15.0301 10.6835 14.6229 10.4392 14.5224 10.0374C14.4256 9.65027 14.0268 8.64857 13.1522 7.75513C12.2986 6.88323 10.9861 6.10547 9 6.10547C7.02612 6.10547 5.65224 6.93561 4.76168 7.87226C4.31225 8.34495 3.98809 8.84344 3.77896 9.27096Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9 13.1055C10.6569 13.1055 12 11.7623 12 10.1055C12 8.44861 10.6569 7.10547 9 7.10547C7.34315 7.10547 6 8.44861 6 10.1055C6 11.7623 7.34315 13.1055 9 13.1055ZM9 11.6055C9.82843 11.6055 10.5 10.9339 10.5 10.1055C10.5 9.27704 9.82843 8.60547 9 8.60547C8.17157 8.60547 7.5 9.27704 7.5 10.1055C7.5 10.9339 8.17157 11.6055 9 11.6055Z"
    fill="currentColor" />
  <path
    d="M17.2803 12.8858C17.5732 12.5929 17.5732 12.118 17.2803 11.8251C16.9874 11.5322 16.5126 11.5322 16.2197 11.8251L13.25 14.7948L12.0303 13.5751C11.7374 13.2822 11.2626 13.2822 10.9697 13.5751C10.6768 13.868 10.6768 14.3429 10.9697 14.6358L12.7197 16.3858C13.0126 16.6787 13.4874 16.6787 13.7803 16.3858L17.2803 12.8858Z"
    fill="currentColor" />
</template>
