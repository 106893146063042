<script lang="ts" setup>
import GPopover from '../../base/GPopover.vue';
import { GButtonV2 } from '@gem/uikit';
import { ref } from 'vue';

defineProps<{
  labelHeader: string;
  isShowTips?: boolean;
  isShowIllustration?: boolean;
  tipsType?: 'info' | 'warning';
  classesTips?: string;
  labelViewLive?: string;
  hideViewliveButton?: boolean;
  isLoading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'view-live'): void;
  (e: 'close'): void;
}>();

const handleClose = () => {
  emit('close');
};

const handleViewLive = () => {
  emit('view-live');
};

const popoverRef = ref();

const open = () => {
  popoverRef.value.onOpen();
};

const close = () => {
  popoverRef.value.close();
};

defineExpose({ close, open });
</script>
<template>
  <g-popover
    ref="popoverRef"
    :has-arrow="false"
    :closeable="true"
    overlay-container="#root-modal"
    wrapper-class="w-[360px] !top-[60px] !right-[10px] !left-[unset]"
    cls="p-0"
    @close="emit('close')">
    <template #content
      ><div class="bg-dark-500 rounded-[16px] p-16">
        <div class="mb-16 flex items-center">
          <p class="text-light-200 text-[16px] font-semibold leading-[24px]">
            {{ labelHeader }}
          </p>
          <span
            class="text-text-dark-300 hover:text-light-450 hover:bg-dark-250 ml-auto cursor-pointer rounded-xl transition-colors duration-200"
            @click="handleClose">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.6464 10.6464C10.8417 10.4512 11.1583 10.4512 11.3536 10.6464L21.3536 20.6464C21.5488 20.8417 21.5488 21.1583 21.3536 21.3536C21.1583 21.5488 20.8417 21.5488 20.6464 21.3536L10.6464 11.3536C10.4512 11.1583 10.4512 10.8417 10.6464 10.6464Z"
                fill="#E2E2E2" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.3536 10.6464C21.5488 10.8417 21.5488 11.1583 21.3536 11.3536L11.3536 21.3536C11.1583 21.5488 10.8417 21.5488 10.6464 21.3536C10.4512 21.1583 10.4512 20.8417 10.6464 20.6464L20.6464 10.6464C20.8417 10.4512 21.1583 10.4512 21.3536 10.6464Z"
                fill="#E2E2E2" />
            </svg>
          </span>
        </div>
        <div v-if="isShowTips" class="tips-container mb-16" :class="classesTips">
          <div
            class="flex gap-8 rounded-[16px] p-16"
            :class="{ 'bg-p-text-info-active': tipsType == 'info', 'bg-underlay-yellow-100': tipsType == 'warning' }">
            <span class="">
              <template v-if="tipsType == 'warning'">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="28" height="28" rx="8" fill="#FFB800" />
                  <path
                    d="M14 10.75C14.4142 10.75 14.75 11.0858 14.75 11.5V15C14.75 15.4142 14.4142 15.75 14 15.75C13.5858 15.75 13.25 15.4142 13.25 15V11.5C13.25 11.0858 13.5858 10.75 14 10.75Z"
                    fill="#251A00" />
                  <path
                    d="M15 17.5C15 18.0523 14.5523 18.5 14 18.5C13.4477 18.5 13 18.0523 13 17.5C13 16.9477 13.4477 16.5 14 16.5C14.5523 16.5 15 16.9477 15 17.5Z"
                    fill="#251A00" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.0001 7.5C12.9547 7.5 12.2162 8.20232 11.8477 8.94672C11.6557 9.33448 10.7599 11.0441 9.84308 12.7935L9.81534 12.8465C8.92293 14.5493 8.01717 16.2777 7.80719 16.702C7.43513 17.4537 7.32934 18.4519 7.89956 19.316C8.46914 20.1791 9.44225 20.5 10.3636 20.5L17.6364 20.5C18.5577 20.5 19.5309 20.1791 20.1005 19.316C20.6707 18.4519 20.5649 17.4537 20.1928 16.702C19.9832 16.2784 19.08 14.555 18.189 12.8548L18.1569 12.7935C17.24 11.044 16.3443 9.33454 16.1525 8.94693C15.784 8.20242 15.0455 7.5 14.0001 7.5ZM13.1919 9.61225C13.5961 8.79592 14.4041 8.79592 14.8081 9.61225C15.0102 10.0206 15.9201 11.7569 16.8297 13.4924C17.7383 15.2262 18.6466 16.9594 18.8485 17.3673C19.2525 18.1837 18.8485 19 17.6364 19L10.3636 19C9.15153 19 8.74749 18.1837 9.15153 17.3673C9.35379 16.9587 10.2647 15.2205 11.1748 13.4838C12.0829 11.7511 12.9901 10.0199 13.1919 9.61225Z"
                    fill="#251A00" />
                </svg>
              </template>
              <template v-else>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="28" height="28" rx="8" fill="#91D0FF" />
                  <path
                    d="M15 10.5C15 11.0523 14.5523 11.5 14 11.5C13.4477 11.5 13 11.0523 13 10.5C13 9.94772 13.4477 9.5 14 9.5C14.5523 9.5 15 9.94772 15 10.5Z"
                    fill="#002133" />
                  <path
                    d="M14.75 13.25C14.75 12.8358 14.4142 12.5 14 12.5C13.5858 12.5 13.25 12.8358 13.25 13.25V17.75C13.25 18.1642 13.5858 18.5 14 18.5C14.4142 18.5 14.75 18.1642 14.75 17.75V13.25Z"
                    fill="#002133" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14 21C17.866 21 21 17.866 21 14C21 10.134 17.866 7 14 7C10.134 7 7 10.134 7 14C7 17.866 10.134 21 14 21ZM14 19.5C17.0376 19.5 19.5 17.0376 19.5 14C19.5 10.9624 17.0376 8.5 14 8.5C10.9624 8.5 8.5 10.9624 8.5 14C8.5 17.0376 10.9624 19.5 14 19.5Z"
                    fill="#002133" />
                </svg>
              </template>
            </span>
            <div class="font-regular text-text-dark-500 text-[12px] leading-[20px]">
              <div class="text-light-200 text-[14px] leading-[24px]">
                <slot name="tips-content"></slot>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isShowIllustration" class="border-dark-200 mb-16 rounded-[12px] border">
          <slot name="illustration"></slot>
        </div>
        <GButtonV2
          v-if="!hideViewliveButton"
          data-test="editor-btn-after-publish"
          button-width="full"
          :loading="isLoading"
          @click="handleViewLive">
          {{ labelViewLive }}
        </GButtonV2>
      </div></template
    >
  </g-popover>
</template>
