<template>
  <path
    d="M0.666016 8C0.666016 3.58172 4.24774 0 8.66602 0H69.9993C74.4176 0 77.9993 3.58172 77.9993 8V44C77.9993 48.4183 74.4176 52 69.9993 52H8.66602C4.24775 52 0.666016 48.4183 0.666016 44V8Z"
    fill="#212121" />
  <rect x="8.66602" y="12" width="19.1111" height="28" rx="3" fill="currentColor" />
  <path
    d="M29.7773 15C29.7773 13.3431 31.1205 12 32.7773 12H45.8885C47.5453 12 48.8885 13.3431 48.8885 15V37C48.8885 38.6569 47.5453 40 45.8885 40H32.7773C31.1205 40 29.7773 38.6569 29.7773 37V15Z"
    fill="currentColor" />
  <path
    d="M50.8887 15C50.8887 13.3431 52.2318 12 53.8887 12H66.9998C68.6566 12 69.9998 13.3431 69.9998 15V37C69.9998 38.6569 68.6566 40 66.9998 40H53.8887C52.2318 40 50.8887 38.6569 50.8887 37V15Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16.9966 22.5932C17.1105 22.7175 17.1105 22.9189 16.9966 23.0432L14.2862 26L16.9966 28.9568C17.1105 29.0811 17.1105 29.2825 16.9966 29.4068C16.8827 29.5311 16.698 29.5311 16.5841 29.4068L13.6675 26.225C13.5536 26.1007 13.5536 25.8993 13.6675 25.775L16.5841 22.5932C16.698 22.4689 16.8827 22.4689 16.9966 22.5932Z"
    fill="#9E9E9E" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M60.6675 22.5932C60.7814 22.4689 60.966 22.4689 61.0799 22.5932L63.9966 25.775C64.1105 25.8993 64.1105 26.1007 63.9966 26.225L61.0799 29.4068C60.966 29.5311 60.7814 29.5311 60.6675 29.4068C60.5536 29.2825 60.5536 29.0811 60.6675 28.9568L63.3779 26L60.6675 23.0432C60.5536 22.9189 60.5536 22.7175 60.6675 22.5932Z"
    fill="#9E9E9E" />
</template>
