<template>
  <path
    d="M10.75 13.0493C11.1984 12.79 11.5 12.3052 11.5 11.75C11.5 10.9216 10.8284 10.25 10 10.25C9.17157 10.25 8.5 10.9216 8.5 11.75C8.5 12.3052 8.80165 12.79 9.25 13.0493V13.5C9.25 13.9142 9.58579 14.25 10 14.25C10.4142 14.25 10.75 13.9142 10.75 13.5V13.0493Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.25 7.09451V6.75C6.25 4.67893 7.92893 3 10 3C12.0711 3 13.75 4.67893 13.75 6.75V7.09451C15.0439 7.42755 16 8.60212 16 10V14C16 15.6569 14.6569 17 13 17H7C5.34315 17 4 15.6569 4 14V10C4 8.60212 4.95608 7.42755 6.25 7.09451ZM7.75 6.75C7.75 5.50736 8.75736 4.5 10 4.5C11.2426 4.5 12.25 5.50736 12.25 6.75V7H7.75V6.75ZM5.5 10C5.5 9.17157 6.17157 8.5 7 8.5H13C13.8284 8.5 14.5 9.17157 14.5 10V14C14.5 14.8284 13.8284 15.5 13 15.5H7C6.17157 15.5 5.5 14.8284 5.5 14V10Z"
    fill="currentColor" />
</template>
