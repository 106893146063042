<template>
  <path
    d="M8.5 7C8.08579 7 7.75 7.33579 7.75 7.75C7.75 8.16421 8.08579 8.5 8.5 8.5H11.5C11.9142 8.5 12.25 8.16421 12.25 7.75C12.25 7.33579 11.9142 7 11.5 7H8.5Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.25 4.5C3.73122 4.5 2.5 5.73122 2.5 7.25V12.75C2.5 14.2688 3.73122 15.5 5.25 15.5H14.75C16.2688 15.5 17.5 14.2688 17.5 12.75V7.25C17.5 5.73122 16.2688 4.5 14.75 4.5H5.25ZM4 7.25C4 6.55964 4.55964 6 5.25 6H14.75C15.4404 6 16 6.55964 16 7.25V12.75C16 13.4404 15.4404 14 14.75 14H5.25C4.55964 14 4 13.4404 4 12.75V7.25Z"
    fill="currentColor" />
</template>
