<template>
  <path
    d="M11.4118 2.70976L10.2232 4.90534C10.2134 4.92498 10.2134 4.94954 10.2232 4.96919L12.7724 9.67962C12.797 9.72628 12.8633 9.72628 12.8878 9.67962L16.9917 2.09824C17.0162 2.05403 16.9843 2 16.9327 2H12.603C12.1044 2 11.6476 2.27261 11.4118 2.70976Z"
    fill="#F34A70" />
  <path
    d="M7.57317 2H3.16727C3.04202 2 2.96098 2.13507 3.02238 2.24559L7.09918 9.78031L3.01992 17.3199C2.96098 17.4305 3.03957 17.5655 3.16727 17.5655H7.56335C7.66158 17.5655 7.75491 17.5115 7.80157 17.4231L11.8784 9.88837C11.9177 9.81715 11.9177 9.72873 11.8784 9.65751L7.81139 2.14244C7.76473 2.05403 7.67386 2 7.57317 2Z"
    fill="#2C7DFF" />
  <path
    d="M11.4118 16.8779L10.2232 14.6823C10.2134 14.6627 10.2134 14.6381 10.2232 14.6185L12.7724 9.90804C12.797 9.86138 12.8633 9.86138 12.8878 9.90804L16.9892 17.4894C17.0138 17.5336 16.9818 17.5877 16.9303 17.5877H12.6005C12.102 17.5877 11.6452 17.3151 11.4094 16.8779H11.4118Z"
    fill="#F34A70" />
</template>
