<template>
  <rect width="28" height="24" rx="3" fill="#4A4A4A" />
  <circle cx="8.5" cy="8" r="2" fill="#D6D6D6" />
  <path
    d="M6.69685 16.6453L8.90968 14.0595C8.9846 13.9718 9.07719 13.9008 9.18141 13.8512C9.28562 13.8017 9.39913 13.7746 9.51452 13.7718C9.62991 13.769 9.7446 13.7905 9.8511 13.835C9.95761 13.8795 10.0535 13.9458 10.1326 14.0298L11.1673 15.1271L15.212 10.2946C15.2912 10.1999 15.3908 10.1243 15.5033 10.0733C15.6157 10.0224 15.7383 9.99737 15.8618 10.0002C15.9852 10.0031 16.1065 10.0337 16.2165 10.0898C16.3265 10.1459 16.4224 10.2261 16.4972 10.3243L21.3329 16.6772C21.4254 16.7989 21.482 16.9441 21.4964 17.0962C21.5107 17.2484 21.4823 17.4016 21.4144 17.5385C21.3464 17.6754 21.2415 17.7907 21.1115 17.8713C20.9815 17.952 20.8316 17.9948 20.6786 17.995L7.32205 18C7.16495 17.9999 7.01115 17.9549 6.87885 17.8703C6.74656 17.7857 6.6413 17.6649 6.57553 17.5224C6.50976 17.3799 6.48624 17.2216 6.50774 17.0661C6.52924 16.9107 6.59487 16.7646 6.69685 16.6453Z"
    fill="#D6D6D6" />
  <rect x="34" width="28" height="24" rx="3" fill="#4A4A4A" />
  <circle cx="42.5" cy="8" r="2" fill="#D6D6D6" />
  <path
    d="M40.6969 16.6453L42.9097 14.0595C42.9846 13.9718 43.0772 13.9008 43.1814 13.8512C43.2856 13.8017 43.3991 13.7746 43.5145 13.7718C43.6299 13.769 43.7446 13.7905 43.8511 13.835C43.9576 13.8795 44.0535 13.9458 44.1326 14.0298L45.1673 15.1271L49.212 10.2946C49.2912 10.1999 49.3908 10.1243 49.5033 10.0733C49.6157 10.0224 49.7383 9.99737 49.8618 10.0002C49.9852 10.0031 50.1065 10.0337 50.2165 10.0898C50.3265 10.1459 50.4224 10.2261 50.4972 10.3243L55.3329 16.6772C55.4254 16.7989 55.482 16.9441 55.4964 17.0962C55.5107 17.2484 55.4823 17.4016 55.4144 17.5385C55.3464 17.6754 55.2415 17.7907 55.1115 17.8713C54.9815 17.952 54.8316 17.9948 54.6786 17.995L41.322 18C41.1649 17.9999 41.0111 17.9549 40.8789 17.8703C40.7466 17.7857 40.6413 17.6649 40.5755 17.5224C40.5098 17.3799 40.4862 17.2216 40.5077 17.0661C40.5292 16.9107 40.5949 16.7646 40.6969 16.6453Z"
    fill="#D6D6D6" />
</template>
