<template>
  <path
    d="M5.98214 10C5.98214 9.5562 6.34191 9.19643 6.78571 9.19643H9.19643V6.78571C9.19643 6.34191 9.5562 5.98214 10 5.98214C10.4438 5.98214 10.8036 6.34191 10.8036 6.78571V9.19643H13.2143C13.6581 9.19643 14.0179 9.5562 14.0179 10C14.0179 10.4438 13.6581 10.8036 13.2143 10.8036H10.8036V13.2143C10.8036 13.6581 10.4438 14.0179 10 14.0179C9.5562 14.0179 9.19643 13.6581 9.19643 13.2143V10.8036H6.78571C6.34191 10.8036 5.98214 10.4438 5.98214 10Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5ZM10 15.8929C13.2545 15.8929 15.8929 13.2545 15.8929 10C15.8929 6.74546 13.2545 4.10714 10 4.10714C6.74546 4.10714 4.10714 6.74546 4.10714 10C4.10714 13.2545 6.74546 15.8929 10 15.8929Z"
    fill="currentColor" />
</template>
