<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13 10C13 11.6569 11.6569 13 10 13C8.34315 13 7 11.6569 7 10C7 8.34315 8.34315 7 10 7C11.6569 7 13 8.34315 13 10ZM11.5 10C11.5 10.8284 10.8284 11.5 10 11.5C9.17157 11.5 8.5 10.8284 8.5 10C8.5 9.17157 9.17157 8.5 10 8.5C10.8284 8.5 11.5 9.17157 11.5 10Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10 4C7.52353 4 5.65153 5.22977 4.42264 6.53216C3.80748 7.18413 3.34017 7.86704 3.02329 8.45447C2.86488 8.74811 2.73973 9.02591 2.65225 9.27169C2.57143 9.49874 2.5 9.76019 2.5 10C2.5 10.2398 2.57143 10.5013 2.65225 10.7283C2.73973 10.9741 2.86488 11.2519 3.02329 11.5455C3.34017 12.133 3.80748 12.8159 4.42264 13.4678C5.65153 14.7702 7.52353 16 10 16C12.4765 16 14.3485 14.7702 15.5774 13.4678C16.1925 12.8159 16.6598 12.133 16.9767 11.5455C17.1351 11.2519 17.2603 10.9741 17.3478 10.7283C17.4286 10.5013 17.5 10.2398 17.5 10C17.5 9.76019 17.4286 9.49874 17.3478 9.27169C17.2603 9.02591 17.1351 8.74811 16.9767 8.45447C16.6598 7.86704 16.1925 7.18413 15.5774 6.53216C14.3485 5.22977 12.4765 4 10 4ZM4.00141 10.0021L4.00103 10L4.00141 9.99793C4.00509 9.97812 4.01827 9.90708 4.0654 9.77469C4.12269 9.61374 4.21422 9.40618 4.34345 9.16663C4.60183 8.68764 4.9936 8.11275 5.51365 7.56159C6.55519 6.45773 8.05819 5.5 10 5.5C11.9418 5.5 13.4448 6.45773 14.4864 7.56159C15.0064 8.11275 15.3982 8.68764 15.6566 9.16663C15.7858 9.40618 15.8773 9.61374 15.9346 9.77469C15.9817 9.90708 15.9949 9.97813 15.9986 9.99793L15.999 10L15.9986 10.0021C15.9949 10.0219 15.9817 10.0929 15.9346 10.2253C15.8773 10.3863 15.7858 10.5938 15.6566 10.8334C15.3982 11.3124 15.0064 11.8873 14.4864 12.4384C13.4448 13.5423 11.9418 14.5 10 14.5C8.05819 14.5 6.55519 13.5423 5.51365 12.4384C4.9936 11.8873 4.60183 11.3124 4.34345 10.8334C4.21422 10.5938 4.12269 10.3863 4.0654 10.2253C4.01827 10.0929 4.00509 10.0219 4.00141 10.0021Z"
    fill="currentColor" />
</template>
