<template>
  <path
    d="M13.2803 8.78033C13.5732 8.48744 13.5732 8.01256 13.2803 7.71967C12.9874 7.42678 12.5126 7.42678 12.2197 7.71967L9.25 10.6893L8.03033 9.46967C7.73744 9.17678 7.26256 9.17678 6.96967 9.46967C6.67678 9.76256 6.67678 10.2374 6.96967 10.5303L8.71967 12.2803C9.01256 12.5732 9.48744 12.5732 9.78033 12.2803L13.2803 8.78033Z"
    fill="#AAAAAA" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.25 3.5C4.73122 3.5 3.5 4.73122 3.5 6.25V13.75C3.5 15.2688 4.73122 16.5 6.25 16.5H13.75C15.2688 16.5 16.5 15.2688 16.5 13.75V6.25C16.5 4.73122 15.2688 3.5 13.75 3.5H6.25ZM5 6.25C5 5.55964 5.55964 5 6.25 5H13.75C14.4404 5 15 5.55964 15 6.25V13.75C15 14.4404 14.4404 15 13.75 15H6.25C5.55964 15 5 14.4404 5 13.75V6.25Z"
    fill="#AAAAAA" />
</template>
