<template>
  <path
    d="M21.5216 1.50646L2.50409 7.45934C2.42905 7.48435 2.38737 7.56773 2.40404 7.6511L3.87142 12.4784C3.89643 12.5535 3.9798 12.6035 4.05484 12.5785L18.0032 8.2097C18.6702 8.00127 19.2288 7.53438 19.554 6.90907C20.4211 5.2416 21.1381 3.50743 21.6884 1.70656C21.7384 1.57316 21.6383 1.47311 21.5216 1.50646Z"
    fill="white" />
  <path
    d="M16.9604 9.85219L5.14637 13.5456C5.07968 13.5707 5.03799 13.6374 5.063 13.7041L6.30527 17.781C6.33028 17.8477 6.39698 17.8894 6.45534 17.8644L13.9923 15.5049C14.5593 15.3298 15.0261 14.9296 15.3013 14.4044C16.035 12.9954 16.6436 11.528 17.1022 10.0106C17.1438 9.91055 17.0521 9.81884 16.9604 9.85219Z"
    fill="white" />
  <path
    d="M12.4679 17.3059L7.54052 18.8483C7.48216 18.865 7.45715 18.9233 7.47382 18.9817L8.52433 22.425C8.541 22.4834 8.59937 22.5167 8.65773 22.4917L9.96669 22.0832C10.4419 21.9331 10.8421 21.5996 11.0756 21.1494C11.7009 19.9572 12.2094 18.7233 12.6013 17.431C12.6263 17.3559 12.5513 17.2726 12.4679 17.3059Z"
    fill="white" />
</template>
