<template>
  <path
    d="M10.75 6C10.75 5.58579 10.4142 5.25 9.99999 5.25C9.58578 5.25 9.24999 5.58579 9.24999 6L9.24999 10C9.24999 10.1989 9.32901 10.3897 9.46966 10.5303L11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303C12.8232 12.2374 12.8232 11.7626 12.5303 11.4697L10.75 9.68934L10.75 6Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10ZM15.5 10C15.5 13.0376 13.0376 15.5 10 15.5C6.96243 15.5 4.5 13.0376 4.5 10C4.5 6.96243 6.96243 4.5 10 4.5C13.0376 4.5 15.5 6.96243 15.5 10Z"
    fill="currentColor" />
</template>
