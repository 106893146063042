import FAQs from './faqs.vue';
import Brands from './brands.vue';
import Bundle from './bundle.vue';
import Compare from './compare.vue';
import Contact from './contact.vue';
import BlogList from './blog-list.vue';
import HowToUse from './how-to-use.vue';
import Collection from './collection.vue';
import BrandStory from './brand-story.vue';
import HeroSection from './hero-section.vue';
import ProductList from './product-list.vue';
import SocialProof from './social-proof.vue';
import Testimonials from './testimonials.vue';
import CustomFooter from './custom-footer.vue';
import MobileFocused from './mobile-focused.vue';
import ProductDetail from './product-detail.vue';
import AnnouncementBar from './announcement-bar.vue';
import ProductBreakdown from './product-breakdown.vue';
import StickyAddToCart from './sticky-add-to-cart.vue';
import TrustedBadges from './trusted-guarantee.vue';
import Guarantee from './guarantee.vue';

export const buildWithSectionIcons = {
  faqs: FAQs,
  brands: Brands,
  bundle: Bundle,
  compare: Compare,
  contact: Contact,
  'blog-list': BlogList,
  'how-to-use': HowToUse,
  collection: Collection,
  'brand-story': BrandStory,
  'product-list': ProductList,
  'hero-section': HeroSection,
  'social-proof': SocialProof,
  testimonials: Testimonials,
  'custom-footer': CustomFooter,
  'mobile-focused': MobileFocused,
  'product-detail': ProductDetail,
  'announcement-bar': AnnouncementBar,
  'sticky-add-to-cart': StickyAddToCart,
  'product-breakdown': ProductBreakdown,
  'trusted-badges': TrustedBadges,
  guarantee: Guarantee,
};
