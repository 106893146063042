<template>
  <path
    d="M0.666016 8C0.666016 3.58172 4.24774 0 8.66602 0H69.9993C74.4176 0 77.9994 3.58172 77.9994 8V40C77.9994 44.4183 74.4176 48 69.9994 48H8.66603C4.24775 48 0.666016 44.4183 0.666016 40V8Z" />
  <rect x="11.332" y="10" width="56" height="18" rx="3" fill="currentColor" />
  <rect
    x="26.5"
    y="34"
    width="4"
    height="4"
    rx="2"
    class="text-dark-200 group-hover:text-dark-50 group-[.active]:text-dark-50"
    fill="currentColor" />
  <rect
    x="33.5"
    y="34"
    width="4"
    height="4"
    rx="2"
    class="text-dark-50 group-hover:text-text-dark-300 group-[.active]:text-text-dark-300"
    fill="currentColor" />
  <rect
    x="40.5"
    y="34"
    width="4"
    height="4"
    rx="2"
    class="text-dark-200 group-hover:text-dark-50 group-[.active]:text-dark-50"
    fill="currentColor" />
  <rect
    x="47.5"
    y="34"
    width="4"
    height="4"
    rx="2"
    class="text-dark-200 group-hover:text-dark-50 group-[.active]:text-dark-50"
    fill="currentColor" />
</template>
