<template>
  <path
    d="M4.85355 4.14645C4.65829 3.95118 4.34171 3.95118 4.14645 4.14645C3.95118 4.34171 3.95118 4.65829 4.14645 4.85355L6.29289 7L4.14645 9.14645C3.95118 9.34171 3.95118 9.65829 4.14645 9.85355C4.34171 10.0488 4.65829 10.0488 4.85355 9.85355L7 7.70711L9.14645 9.85355C9.34171 10.0488 9.65829 10.0488 9.85355 9.85355C10.0488 9.65829 10.0488 9.34171 9.85355 9.14645L7.70711 7L9.85355 4.85355C10.0488 4.65829 10.0488 4.34171 9.85355 4.14645C9.65829 3.95118 9.34171 3.95118 9.14645 4.14645L7 6.29289L4.85355 4.14645Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0ZM1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7Z"
    fill="currentColor" />
</template>
