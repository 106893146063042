<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.5 11.9984C14.4993 10.618 13.3797 9.49961 11.9993 9.50027C10.6189 9.50071 9.5 10.6199 9.5 12.0003C9.5 13.381 10.6193 14.5003 12 14.5003C13.3804 14.5003 14.4999 13.3818 14.5 12.0011V11.9984ZM11.9997 10.5003C12.8279 10.4998 13.4996 11.1708 13.5 11.9989V12.0005C13.5 12.8288 12.8286 13.5003 12 13.5003C11.1716 13.5003 10.5 12.8287 10.5 12.0003C10.5 11.172 11.1715 10.5005 11.9997 10.5003Z"
    fill="#AAAAAA" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.9989 7.50098C8.53122 7.50106 6.24467 10.1164 5.43624 11.2081C5.08494 11.6825 5.0872 12.3202 5.43995 12.7924C6.2535 13.8813 8.55485 16.4995 11.9989 16.4995C15.5043 16.4995 17.7754 13.8764 18.5733 12.7849C18.9177 12.3138 18.9152 11.684 18.5687 11.2154C17.7643 10.1277 15.4742 7.50089 11.9989 7.50098ZM6.23987 11.8032C7.01624 10.7548 9.04151 8.50105 11.999 8.50098C14.9631 8.50091 16.9919 10.765 17.7647 11.81C17.8515 11.9275 17.8518 12.0774 17.766 12.1947C17.0031 13.2384 14.9931 15.4995 11.9989 15.4995C9.06505 15.4995 7.02524 13.2435 6.24107 12.1939C6.15202 12.0747 6.15186 11.9221 6.23987 11.8032Z"
    fill="#AAAAAA" />
</template>
