<template>
  <g clip-path="url(#clip0_16246_4196)">
  <path d="M0 0H120V72H0V0Z" fill="#212121"/>
  <rect x="12.625" y="12.625" width="94.75" height="46.75" rx="2.375" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="10 10"/>
  <path d="M31.5 39C31.2929 39 31.125 38.8321 31.125 38.625L31.125 32.065L29.522 33.7543C29.3795 33.9046 29.1421 33.9108 28.9919 33.7682C28.8416 33.6257 28.8354 33.3883 28.978 33.2381L31.2248 30.8703C31.2933 30.7963 31.3912 30.75 31.5 30.75C31.6087 30.75 31.7066 30.7963 31.7751 30.8702L34.022 33.2381C34.1646 33.3883 34.1584 33.6257 34.0081 33.7682C33.8579 33.9108 33.6205 33.9046 33.478 33.7543L31.875 32.065L31.875 38.625C31.875 38.8321 31.7071 39 31.5 39Z" fill="currentColor"/>
  <path d="M26.625 38.25C26.8321 38.25 27 38.4179 27 38.625L27 40.125C27 40.3321 27.1679 40.5 27.375 40.5H35.625C35.8321 40.5 36 40.3321 36 40.125L36 38.625C36 38.4179 36.1679 38.25 36.375 38.25C36.5821 38.25 36.75 38.4179 36.75 38.625L36.75 40.125C36.75 40.7464 36.2462 41.25 35.625 41.25H27.375C26.7537 41.25 26.25 40.7464 26.25 40.125L26.25 38.625C26.25 38.4179 26.4179 38.25 26.625 38.25Z" fill="currentColor"/>
  <rect x="41.5" y="32" width="53" height="8" rx="4" fill="currentColor"/>
  </g>
  <defs>
  <clipPath id="clip0_16246_4196">
  <path d="M0 8C0 3.58172 3.58172 0 8 0H112C116.418 0 120 3.58172 120 8V64C120 68.4183 116.418 72 112 72H8C3.58172 72 0 68.4183 0 64V8Z" fill="white"/>
  </clipPath>
  </defs>
</template>
