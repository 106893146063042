import type { RadioProps } from '../types';

import { computed } from 'vue';
import { useRadioSelection } from './useRadioSelection';

export function useRadioSize({ props }: { props: RadioProps }) {
  const size = props.size || 'md';

  const { isSelected } = useRadioSelection({ props });

  const isMediumSize = computed(() => size === 'md');
  const radioSizeCls = computed(() => {
    if (isMediumSize.value) return ['h-20', 'w-20'];

    return [];
  });
  const radioActiveSizeCls = computed(() => {
    if (!isSelected.value) return [];

    if (isMediumSize.value) {
      return ['before:h-10', 'before:w-10'];
    }

    return [];
  });
  const radioHoverSizeCls = computed(() => {
    if (isMediumSize.value) return ['top-[-4px]', 'left-[-4px]', 'h-32', 'w-32'];
    return [];
  });

  return { isMediumSize, radioSizeCls, radioHoverSizeCls, radioActiveSizeCls };
}
