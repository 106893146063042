<template>
  <path
    d="M8.75 8.5C9.16421 8.5 9.5 8.83579 9.5 9.25V10.5H10.75C11.1642 10.5 11.5 10.8358 11.5 11.25C11.5 11.6642 11.1642 12 10.75 12H9.5V13.25C9.5 13.6642 9.16421 14 8.75 14C8.33579 14 8 13.6642 8 13.25V12H6.75C6.33579 12 6 11.6642 6 11.25C6 10.8358 6.33579 10.5 6.75 10.5H8V9.25C8 8.83579 8.33579 8.5 8.75 8.5Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.75 3.5C7.31198 3.5 6.13175 4.60376 6.0103 6.0103C4.60376 6.13175 3.5 7.31198 3.5 8.75V13.75C3.5 15.2688 4.73122 16.5 6.25 16.5H11.25C12.688 16.5 13.8682 15.3962 13.9897 13.9897C15.3962 13.8683 16.5 12.688 16.5 11.25V6.25C16.5 4.73122 15.2688 3.5 13.75 3.5H8.75ZM11.25 6H7.525C7.64082 5.42944 8.14526 5 8.75 5H13.75C14.4404 5 15 5.55964 15 6.25V11.25C15 11.8547 14.5706 12.3592 14 12.475V8.75C14 7.23122 12.7688 6 11.25 6ZM6.25 7.5C5.55964 7.5 5 8.05964 5 8.75V13.75C5 14.4404 5.55964 15 6.25 15H11.25C11.9404 15 12.5 14.4404 12.5 13.75V8.75C12.5 8.05964 11.9404 7.5 11.25 7.5H6.25Z"
    fill="currentColor" />
</template>
