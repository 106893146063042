<template>
  <g clip-path="url(#clip0_16246_3573)">
  <path d="M0 0H120V72H0V0Z" fill="#212121"/>
  <rect x="12.625" y="12.625" width="94.75" height="46.75" rx="2.375" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="10 10"/>
  <path d="M65.4688 30.875V34.375C65.4688 34.5491 65.3996 34.716 65.2765 34.8391C65.1535 34.9621 64.9865 35.0313 64.8125 35.0313H55.1875C55.0135 35.0313 54.8465 34.9621 54.7235 34.8391C54.6004 34.716 54.5312 34.5491 54.5312 34.375V30.875C54.5312 30.701 54.6004 30.5341 54.7235 30.411C54.8465 30.2879 55.0135 30.2188 55.1875 30.2188C55.3615 30.2188 55.5285 30.2879 55.6515 30.411C55.7746 30.5341 55.8438 30.701 55.8438 30.875V33.7188H64.1562V30.875C64.1562 30.701 64.2254 30.5341 64.3485 30.411C64.4715 30.2879 64.6385 30.2188 64.8125 30.2188C64.9865 30.2188 65.1535 30.2879 65.2765 30.411C65.3996 30.5341 65.4688 30.701 65.4688 30.875ZM58.2768 27.4018L59.3438 26.336V30.875C59.3438 31.0491 59.4129 31.216 59.536 31.3391C59.659 31.4621 59.826 31.5313 60 31.5313C60.174 31.5313 60.341 31.4621 60.464 31.3391C60.5871 31.216 60.6562 31.0491 60.6562 30.875V26.336L61.7232 27.4035C61.7842 27.4645 61.8567 27.5129 61.9365 27.546C62.0162 27.579 62.1017 27.596 62.188 27.596C62.2744 27.596 62.3599 27.579 62.4396 27.546C62.5194 27.5129 62.5918 27.4645 62.6529 27.4035C62.7139 27.3424 62.7624 27.27 62.7954 27.1902C62.8284 27.1104 62.8454 27.025 62.8454 26.9386C62.8454 26.8523 62.8284 26.7668 62.7954 26.6871C62.7624 26.6073 62.7139 26.5348 62.6529 26.4738L60.4654 24.2863C60.4044 24.2251 60.332 24.1766 60.2522 24.1434C60.1724 24.1103 60.0869 24.0933 60.0005 24.0933C59.9142 24.0933 59.8287 24.1103 59.7489 24.1434C59.6691 24.1766 59.5967 24.2251 59.5357 24.2863L57.3482 26.4738C57.2872 26.5348 57.2387 26.6073 57.2057 26.6871C57.1727 26.7668 57.1557 26.8523 57.1557 26.9386C57.1557 27.113 57.2249 27.2802 57.3482 27.4035C57.4715 27.5268 57.6387 27.596 57.813 27.596C57.9874 27.596 58.1546 27.5268 58.2779 27.4035L58.2768 27.4018Z" fill="currentColor"/>
  <rect x="33.5" y="41" width="53" height="8" rx="4" fill="currentColor"/>
  </g>
  <defs>
  <clipPath id="clip0_16246_3573">
  <path d="M0 3C0 1.34315 1.34315 0 3 0H117C118.657 0 120 1.34315 120 3V69C120 70.6569 118.657 72 117 72H3C1.34314 72 0 70.6569 0 69V3Z" fill="white"/>
  </clipPath>
  </defs>
</template>
