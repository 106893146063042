<template>
  <g clip-path="url(#clip0_12367_31875)">
    <path
      d="M11.5195 3.13903L10.3699 5.23667C10.3604 5.25545 10.3604 5.27891 10.3699 5.29768L12.8353 9.79799C12.8591 9.84257 12.9232 9.84257 12.947 9.79799L16.9159 2.55479C16.9396 2.51256 16.9088 2.46094 16.8589 2.46094H12.6715C12.1893 2.46094 11.7475 2.72138 11.5195 3.13903Z"
      fill="currentColor" />
    <path
      d="M7.80711 2.46094H3.54605C3.42492 2.46094 3.34654 2.58999 3.40592 2.69557L7.3487 9.89419L3.40354 17.0975C3.34654 17.2031 3.42254 17.3321 3.54605 17.3321H7.79761C7.89261 17.3321 7.98287 17.2805 8.028 17.196L11.9708 9.99742C12.0088 9.92938 12.0088 9.84491 11.9708 9.77687L8.0375 2.59703C7.99237 2.51256 7.90449 2.46094 7.80711 2.46094Z"
      fill="currentColor" />
    <path
      d="M11.5195 16.6768L10.3699 14.5791C10.3604 14.5604 10.3604 14.5369 10.3699 14.5181L12.8353 10.0178C12.8591 9.97323 12.9232 9.97323 12.947 10.0178L16.9135 17.261C16.9373 17.3032 16.9064 17.3549 16.8565 17.3549H12.6691C12.1869 17.3549 11.7451 17.0944 11.5171 16.6768H11.5195Z"
      fill="currentColor" />
  </g>
  <defs>
    <clipPath id="clip0_12367_31875">
      <rect width="13.5385" height="14.8923" fill="white" transform="translate(3.38477 2.46094)" />
    </clipPath>
  </defs>
</template>
