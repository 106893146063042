<template>
  <g clip-path="url(#clip0_9799_481866)">
    <path
      d="M0 3C0 1.34315 1.34315 0 3 0H93C94.6569 0 96 1.34315 96 3V69C96 70.6569 94.6569 72 93 72H3C1.34315 72 0 70.6569 0 69V3Z"
      fill="#333333" />
    <g clip-path="url(#clip1_9799_481866)">
      <rect x="12" y="4" width="72" height="24" rx="2" fill="#474747" />
      <rect x="12" y="31" width="72" height="24" rx="2" fill="#474747" />
      <rect x="12" y="58" width="72" height="24" rx="2" fill="#474747" />
      <path d="M0 55H96V69C96 70.6569 94.6569 72 93 72H3C1.34315 72 0 70.6569 0 69V55Z" fill="#5E5E5E" />
      <path
        d="M8 63.5C8 62.1193 9.11929 61 10.5 61H57.5C58.8807 61 60 62.1193 60 63.5C60 64.8807 58.8807 66 57.5 66H10.5C9.11929 66 8 64.8807 8 63.5Z"
        fill="#757575" />
      <path
        d="M64 63.5C64 62.1193 65.1193 61 66.5 61H85.5C86.8807 61 88 62.1193 88 63.5C88 64.8807 86.8807 66 85.5 66H66.5C65.1193 66 64 64.8807 64 63.5Z"
        fill="#757575" />
    </g>
  </g>
  <defs>
    <clipPath id="clip0_9799_481866">
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H93C94.6569 0 96 1.34315 96 3V69C96 70.6569 94.6569 72 93 72H3C1.34315 72 0 70.6569 0 69V3Z"
        fill="white" />
    </clipPath>
    <clipPath id="clip1_9799_481866">
      <rect width="96" height="72" fill="white" />
    </clipPath>
  </defs>
</template>
