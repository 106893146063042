<template>
  <path
    d="M8.7999 5.19999C8.7999 5.64182 8.44173 5.99999 7.9999 5.99999C7.55807 5.99999 7.1999 5.64182 7.1999 5.19999C7.1999 4.75817 7.55807 4.39999 7.9999 4.39999C8.44173 4.39999 8.7999 4.75817 8.7999 5.19999Z"
    fill="currentColor" />
  <path
    d="M8.5999 7.39999C8.5999 7.06862 8.33127 6.79999 7.9999 6.79999C7.66853 6.79999 7.3999 7.06862 7.3999 7.39999V11C7.3999 11.3314 7.66853 11.6 7.9999 11.6C8.33127 11.6 8.5999 11.3314 8.5999 11V7.39999Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.9999 13.6C11.0927 13.6 13.5999 11.0928 13.5999 7.99999C13.5999 4.9072 11.0927 2.39999 7.9999 2.39999C4.90711 2.39999 2.3999 4.9072 2.3999 7.99999C2.3999 11.0928 4.90711 13.6 7.9999 13.6ZM7.9999 12.4C10.43 12.4 12.3999 10.43 12.3999 7.99999C12.3999 5.56994 10.43 3.59999 7.9999 3.59999C5.56985 3.59999 3.5999 5.56994 3.5999 7.99999C3.5999 10.43 5.56985 12.4 7.9999 12.4Z"
    fill="currentColor" />
</template>
