<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 6C7.27614 6 7.5 6.22386 7.5 6.5V11.5C7.5 11.7761 7.27614 12 7 12C6.72386 12 6.5 11.7761 6.5 11.5V6.5C6.5 6.22386 6.72386 6 7 6Z"
      fill="currentColor" />
    <path
      d="M10 6C10.2761 6 10.5 6.22386 10.5 6.5V11.5C10.5 11.7761 10.2761 12 10 12C9.72386 12 9.5 11.7761 9.5 11.5V6.5C9.5 6.22386 9.72386 6 10 6Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5 2.5V3H14.5C14.7761 3 15 3.22386 15 3.5C15 3.77614 14.7761 4 14.5 4H13.5V12.5C13.5 13.8807 12.3807 15 11 15H6C4.61929 15 3.5 13.8807 3.5 12.5V4H2.5C2.22386 4 2 3.77614 2 3.5C2 3.22386 2.22386 3 2.5 3H5.5V2.5C5.5 1.67157 6.17157 1 7 1H10C10.8284 1 11.5 1.67157 11.5 2.5ZM4.5 4V12.5C4.5 13.3284 5.17157 14 6 14H11C11.8284 14 12.5 13.3284 12.5 12.5V4H4.5ZM6.5 3V2.5C6.5 2.22386 6.72386 2 7 2H10C10.2761 2 10.5 2.22386 10.5 2.5V3H6.5Z"
      fill="currentColor" />
  </svg>
</template>
