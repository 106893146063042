<template>
  <path
    d="M0 8C0 3.58172 3.58172 0 8 0H16C20.4183 0 24 3.58172 24 8V16C24 20.4183 20.4183 24 16 24H8C3.58172 24 0 20.4183 0 16V8Z"
    fill="#FFE4C6" />
  <path
    d="M12.75 15.0493C13.1984 14.79 13.5 14.3052 13.5 13.75C13.5 12.9216 12.8284 12.25 12 12.25C11.1716 12.25 10.5 12.9216 10.5 13.75C10.5 14.3052 10.8016 14.79 11.25 15.0493V15.5C11.25 15.9142 11.5858 16.25 12 16.25C12.4142 16.25 12.75 15.9142 12.75 15.5V15.0493Z"
    fill="#303030" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.25 9.09451V8.75C8.25 6.67893 9.92893 5 12 5C14.0711 5 15.75 6.67893 15.75 8.75V9.09451C17.0439 9.42755 18 10.6021 18 12V16C18 17.6569 16.6569 19 15 19H9C7.34315 19 6 17.6569 6 16V12C6 10.6021 6.95608 9.42755 8.25 9.09451ZM9.75 8.75C9.75 7.50736 10.7574 6.5 12 6.5C13.2426 6.5 14.25 7.50736 14.25 8.75V9H9.75V8.75ZM7.5 12C7.5 11.1716 8.17157 10.5 9 10.5H15C15.8284 10.5 16.5 11.1716 16.5 12V16C16.5 16.8284 15.8284 17.5 15 17.5H9C8.17157 17.5 7.5 16.8284 7.5 16V12Z"
    fill="#303030" />
</template>
