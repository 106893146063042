<script lang="ts" setup>
import { cn } from '../helpers/common';
import type { InputType, InputStyle, InputSize, InputIconPosition } from '../types/input';

import { computed, useSlots, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    inputStyle?: InputStyle;
    type?: InputType;
    size?: InputSize;
    disable?: boolean;
    placeholder?: string;
    name?: string;
    value?: any;
    error?: string;
    classes?: string;
    iconPosition?: string;
    iconClass?: InputIconPosition;
    maxLength?: number;
    suffix?: string;
    propName?: string;
    containerClasses?: string;
  }>(),
  {
    inputStyle: 'primary',
    type: 'text',
    size: 'medium',
    disable: false,
    error: '',
    iconPosition: 'first',
  },
);
const slots = useSlots();

const inputRef = ref<HTMLInputElement>();
const focus = () => {
  if (inputRef.value) {
    inputRef.value.focus();
  }
};
const clearInput = () => {
  if (inputRef.value?.value) {
    inputRef.value.value = '';
  }
};
const setValue = (value: string) => {
  if (inputRef.value) {
    inputRef.value.value = value;
  }
};
defineExpose({
  focus,
  clearInput,
  setValue,
});

//=================== Styles =====================
const iconClasses = computed(() => {
  const classes = ['absolute', 'text-center'];
  switch (props.size) {
    case 'large':
      classes.push('[&>svg]:w-16', '[&>svg]:h-16');
      break;
    case 'medium':
      classes.push('[&>svg]:w-20', '[&>svg]:h-20');
      break;
    case 'small':
      classes.push('[&>svg]:w-16', '[&>svg]:h-16', '!w-24');
      break;
  }

  if (props.iconPosition === 'last') {
    classes.push('right-0');
  }

  return classes.join(' ');
});
const inputClasses = computed(() => {
  const classes = [
    'w-full',
    'rounded-xl',
    'transition-colors',
    'duration-200',
    'outline-0',
    '!px-8',
    'caret-primary-300',
  ];

  switch (props.inputStyle) {
    case 'primary':
      if (props.disable) {
        classes.push(
          'placeholder:text-text-dark-100 border-dark-300 disabled:cursor-not-allowed disabled:border-dark-200',
        );
      } else {
        classes.push('placeholder:text-14 placeholder:text-text-light-100 bg-white border text-text-dark-400');
      }
      if (props.error !== '') {
        classes.push('!border-red-300 hover:border-red-300 focus:border-red-300');
      } else {
        classes.push('border-transparent hover:border-transparent focus:border-primary-300');
      }
      break;
    case 'secondary':
      if (props.disable) {
        classes.push(
          'placeholder:text-text-dark-100 border-dark-300 disabled:cursor-not-allowed disabled:border-dark-200',
        );
      } else {
        classes.push(
          'placeholder:text-text-dark-100 border border-dark-400 focus:border-primary-300 focus:bg-dark-400 hover:border-dark-200 hover:bg-dark-200 disabled:border-dark-200 disabled:bg-dark-200 text-light-450 bg-dark-400',
        );
      }
      break;
    case 'normalLight':
      classes.push('placeholder:text-text-light-100 text-text-light-500 focus:bg-light-300 hover:bg-light-450');
      if (props.disable) {
        classes.push('cursor-not-allowed border-light-300 bg-light-300 hover:bg-light-300 text-text-light-100');
      } else if (props.error) {
        classes.push('border-red-300 hover:border-red-300 focus:!border-red-300');
      } else {
        classes.push('bg-light-300 border-transparent border-solid focus:border-primary-300');
      }
      break;
    case 'grey':
      if (props.disable) {
        classes.push(
          'placeholder:text-text-dark-100 border-dark-300 disabled:cursor-not-allowed disabled:border-dark-200 ',
        );
      } else {
        classes.push('placeholder:text-12 placeholder:text-text-light-100 bg-white border text-text-light-500');
      }
      if (props.error !== '') {
        classes.push('!border-red-300 hover:border-red-300 focus:border-red-300');
      } else {
        classes.push(
          'border-transparent focus:border-primary-300 hover:bg-light-450 bg-light-300 active:border-primary-300 active:border focus:border-primary-300 focus:bg-light-300 ',
        );
      }
      break;
  }
  switch (props.size) {
    case 'largeV2':
      classes.push('h-40 text-14 p-8 font-regular leading-6');
      break;
    case 'large':
      classes.push('h-40 text-14 p-12 leading-4');
      break;
    case 'medium':
      classes.push('h-36 text-12 p-10 leading-4');
      break;
    case 'small':
      classes.push('h-32 text-12 p-4 leading-4');
      break;
  }
  if (slots.icon !== undefined) {
    if (props.iconPosition === 'first') {
      switch (props.size) {
        case 'small':
          classes.push('!pl-24 !pr-4');
          break;
        default:
          classes.push('!pl-32');
      }
    } else {
      switch (props.size) {
        case 'small':
          classes.push('!pr-24 !pl-4');
          break;
        default:
          classes.push('!pr-32');
      }
    }
  }

  if (props.maxLength) {
    classes.push('!pr-[48px]');
  }

  return classes.join(' ');
});

//=================== End Styles =====================

//=================== Emit Function =====================
const emit = defineEmits<{
  (e: 'change', value: string): void;
  (e: 'on-change', value: string, name: string | undefined): void;
  (e: 'handleBlur', value: string): void;
  (e: 'focus'): void;
  (e: 'enter'): void;
}>();
//=================== End Emit Function =====================

//=================== Methods ====================

const change = ($event: Event | KeyboardEvent | any) => {
  emit('change', $event?.target?.value?.trim());
};
const onChange = ($event: Event | KeyboardEvent | any) => {
  emit('on-change', $event?.target?.value.trim(), props.propName);
};
const handleOnInputBlur = ($event: Event | KeyboardEvent | any) => {
  emit('handleBlur', $event?.target?.value);
};
//=================== End Methods =====================
const parseValue = computed(() => {
  return props.value?.toString()?.replace(/&nbsp;/g, ' ');
});

const getStringLength = (str: string) => {
  return new TextEncoder().encode(str).length;
};

const maxLengthLabel = computed(() => {
  if (!props.maxLength) return '';
  const characterCount = props.value?.length ? getStringLength(props.value) : 0;
  return `${characterCount}/${props.maxLength}`;
});

const handleFocus = (e: any) => {
  e.target?.select();
  emit('focus');
};
</script>

<template>
  <div class="relative w-full" :class="containerClasses">
    <slot name="label"></slot>
    <div class="relative flex w-full items-center">
      <div v-if="!!slots['icon']" :class="cn(iconClasses, iconClass)" class="flex w-32 justify-center">
        <slot name="icon"></slot>
      </div>
      <slot name="clear"></slot>
      <input
        ref="inputRef"
        class="text-ellipsis focus:outline-none"
        data-test="editor-control-g-input-text"
        :placeholder="placeholder"
        :class="cn(inputClasses, classes)"
        :type="type"
        :value="parseValue"
        v-bind="$attrs"
        :disabled="disable"
        :maxlength="maxLength"
        @focus="handleFocus"
        @change="change"
        @input="onChange"
        @blur="handleOnInputBlur"
        @keyup.enter="() => emit('enter')" />
      <div v-if="maxLength" class="text-text-light-100 text-12 leading-20 absolute right-8">
        {{ maxLengthLabel }}
      </div>
      <div v-if="suffix" class="text-text-light-100 text-12 leading-20 absolute right-8">
        {{ suffix }}
      </div>
    </div>
    <div v-if="error !== ''" class="text-12 font-regular mt-8 flex flex-row gap-8 text-red-300">
      <g-base-icon name="error" width="16" height="16" view-box="0 0 16 16" custom-class="text-red-300"></g-base-icon
      ><span>{{ error }}</span>
    </div>
    <slot name="description"></slot>
  </div>
</template>
