<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M33.4826 67.4652C42.0334 67.4652 48.9652 60.5334 48.9652 51.9826C48.9652 43.4318 42.0334 36.5 33.4826 36.5C24.9318 36.5 18 43.4318 18 51.9826C18 60.5334 24.9318 67.4652 33.4826 67.4652ZM33.4826 70.4652C43.6903 70.4652 51.9652 62.1903 51.9652 51.9826C51.9652 41.775 43.6903 33.5 33.4826 33.5C23.275 33.5 15 41.775 15 51.9826C15 62.1903 23.275 70.4652 33.4826 70.4652Z"
    fill="currentColor" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M32 53.5L32 39.5H35L35 53.5H32Z" fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M39.0711 60.6922L32 53.6211L34.1213 51.4998L41.1924 58.5708L39.0711 60.6922Z"
    fill="currentColor" />
  <path d="M60 18.5H63L62.5 35.5H60.5L60 18.5Z" fill="currentColor" />
  <circle cx="61.5" cy="40" r="2.5" fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.6211 56.5H12L12 14.5L69 14.5V56.5H49.3789C49.0831 57.5459 48.6867 58.5496 48.2009 59.5H69C70.6569 59.5 72 58.1569 72 56.5V14.5C72 12.8431 70.6569 11.5 69 11.5H12C10.3431 11.5 9 12.8431 9 14.5V56.5C9 58.1569 10.3431 59.5 12 59.5H18.7991C18.3133 58.5496 17.9169 57.5459 17.6211 56.5Z"
    fill="currentColor" />
</template>
