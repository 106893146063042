<template>
  <path
    d="M8.02311 5C7.36526 5 6.85645 5.45433 6.85645 6.16635C6.85645 6.44249 6.63259 6.66635 6.35645 6.66635C6.0803 6.66635 5.85645 6.44249 5.85645 6.16635C5.85645 4.86625 6.84946 4 8.02311 4C9.24842 4 10.2701 4.87515 10.2701 6.16635C10.2701 6.63873 10.0723 7.03866 9.8485 7.34916C9.62544 7.65868 9.34929 7.91638 9.13981 8.10543C8.6614 8.53716 8.52343 9.01099 8.52344 9.49999C8.52344 9.77613 8.29959 9.99999 8.02345 10C7.74731 10 7.52344 9.77616 7.52344 9.50001C7.52342 8.7814 7.74469 8.01744 8.46985 7.36303C8.67483 7.17805 8.88129 6.98088 9.03722 6.7645C9.19246 6.54909 9.27006 6.3521 9.27006 6.16635C9.27006 5.48981 8.76064 5 8.02311 5Z"
    fill="currentColor" />
  <path
    d="M8.02344 11C7.74729 11 7.52344 11.2239 7.52344 11.5C7.52344 11.7761 7.74729 12 8.02344 12C8.29958 12 8.52344 11.7761 8.52344 11.5C8.52344 11.2239 8.29958 11 8.02344 11Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2Z"
    fill="currentColor" />
</template>
