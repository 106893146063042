<template>
  <g clip-path="url(#clip0_9799_481872)">
    <path
      d="M0 3C0 1.34315 1.34315 0 3 0H93C94.6569 0 96 1.34315 96 3V69C96 70.6569 94.6569 72 93 72H3C1.34315 72 0 70.6569 0 69V3Z"
      fill="#333333" />
    <g clip-path="url(#clip1_9799_481872)">
      <rect x="12" y="4" width="72" height="24" rx="2" fill="#474747" />
      <rect x="49" y="13" width="32" height="12" rx="1" fill="#5E5E5E" />
      <path
        d="M57 19C57 17.8954 57.8954 17 59 17H71C72.1046 17 73 17.8954 73 19C73 20.1046 72.1046 21 71 21H59C57.8954 21 57 20.1046 57 19Z"
        fill="#AAAAAA" />
      <rect x="12" y="31" width="72" height="24" rx="2" fill="#474747" />
      <rect x="12" y="58" width="72" height="24" rx="2" fill="#474747" />
    </g>
  </g>
  <defs>
    <clipPath id="clip0_9799_481872">
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H93C94.6569 0 96 1.34315 96 3V69C96 70.6569 94.6569 72 93 72H3C1.34315 72 0 70.6569 0 69V3Z"
        fill="white" />
    </clipPath>
    <clipPath id="clip1_9799_481872">
      <rect width="96" height="72" fill="white" />
    </clipPath>
  </defs>
</template>
