<script lang="ts" setup>
import { GButtonV2, GBaseIcon } from '@gem/uikit';

const emit = defineEmits<{
  (e: 'click'): void;
  (e: 'close'): void;
}>();
const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  content: {
    type: String,
    default: '',
    required: true,
  },
  isNew: {
    type: Boolean,
    default: false,
  },
  action: {
    type: String,
    default: null,
  },
});

const clickAction = () => {
  emit('click');
};

const closeTipBox = () => {
  emit('close');
};
</script>

<template>
  <div class="fixed left-[334px] top-[59px] z-[500] w-[300px]">
    <div class="text-light-200 bg-dark-300 relative mb-12 flex flex-col gap-12 rounded-[8px] p-16">
      <div
        class="border-r-dark-300 absolute -left-8 z-50 border-y-8 border-r-8 border-l-0 border-solid border-y-transparent"></div>
      <div>
        <div v-if="props.title" class="mb-8 flex min-w-[222px] items-center justify-between gap-12">
          <div class="flex items-center justify-center gap-8">
            <p class="text-16 whitespace-nowrap font-semibold">{{ props.title }}</p>
            <div v-if="isNew" class="bg-underlay-blue-150 text-12 text-primary-200 rounded-[8px] py-2 px-8">New</div>
          </div>
          <GButtonV2 class="h-32 px-8" size="small" type="ghost" @click="closeTipBox">
            <g-base-icon name="close16" width="16" height="16" view-box="0 0 16 16"></g-base-icon>
          </GButtonV2>
        </div>
        <p class="text-14 font-regular">{{ props.content }}</p>
      </div>
      <div>
        <GButtonV2 v-if="props.action" class="h-32 px-16" @click="clickAction">{{ props.action }}</GButtonV2>
      </div>
    </div>
  </div>
</template>
