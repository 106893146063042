<template>
  <g clip-path="url(#clip0_2044_27401)">
    <rect width="80" height="80" fill="#212121" />
    <rect x="8" width="64" height="80" rx="4" fill="#333333" />
    <rect x="14" y="6" width="52" height="28" rx="2" fill="#424242" />
    <rect x="18" y="15" width="18" height="2" rx="1" fill="#5E5E5E" />
    <rect x="18" y="19" width="22.3439" height="2" rx="1" fill="#5E5E5E" />
    <rect x="18" y="23" width="12" height="2" rx="1" fill="#5E5E5E" />
    <path
      d="M54.0787 23.1392C54.4835 23.597 54.1571 24.3182 53.546 24.3161L48.3796 24.2988C47.771 24.2967 47.4485 23.5786 47.8512 23.1224L50.4244 20.207C50.7061 19.8879 51.2036 19.8877 51.4856 20.2065L54.0787 23.1392Z"
      fill="#5E5E5E" />
    <path
      d="M60.9595 23.1341C61.3627 23.5928 61.0349 24.3125 60.4242 24.3094L51.5701 24.2646C50.9589 24.2615 50.6386 23.5375 51.0474 23.0832L55.5059 18.1274C55.7893 17.8124 56.2841 17.8153 56.5639 18.1335L60.9595 23.1341Z"
      fill="#5E5E5E" />
    <path
      d="M51.7672 17.7181C52.6637 17.6161 53.288 16.8315 53.186 15.9349C53.084 15.0383 52.2994 14.414 51.4028 14.5161C50.5062 14.6181 49.8819 15.4027 49.9839 16.2993C50.086 17.1959 50.8706 17.8202 51.7672 17.7181Z"
      fill="#5E5E5E" />
    <rect x="14" y="40" width="24.5" height="23" rx="2.53" fill="#424242" />
    <path
      d="M26.1568 54.6392C26.5616 55.097 26.2352 55.8182 25.6241 55.8161L20.4577 55.7988C19.8491 55.7967 19.5266 55.0786 19.9293 54.6224L22.5026 51.707C22.7842 51.3879 23.2817 51.3877 23.5637 51.7065L26.1568 54.6392Z"
      fill="#5E5E5E" />
    <path
      d="M33.0376 54.6341C33.4408 55.0928 33.113 55.8125 32.5023 55.8094L23.6482 55.7646C23.0371 55.7615 22.7167 55.0375 23.1255 54.5832L27.584 49.6274C27.8674 49.3124 28.3622 49.3153 28.642 49.6335L33.0376 54.6341Z"
      fill="#5E5E5E" />
    <path
      d="M23.8453 49.2181C24.7419 49.1161 25.3662 48.3315 25.2641 47.4349C25.1621 46.5383 24.3775 45.914 23.4809 46.0161C22.5843 46.1181 21.96 46.9027 22.0621 47.7993C22.1641 48.6959 22.9487 49.3202 23.8453 49.2181Z"
      fill="#5E5E5E" />
    <rect x="41.5" y="46.5" width="16" height="2" rx="1" fill="#5E5E5E" />
    <rect x="41.5" y="50.5" width="24.5" height="2" rx="1" fill="#5E5E5E" />
    <rect x="41.5" y="54.5" width="8" height="2" rx="1" fill="#5E5E5E" />
    <rect y="40" width="80" height="40" fill="url(#paint0_linear_2044_27401)" />
  </g>
  <defs>
    <linearGradient id="paint0_linear_2044_27401" x1="40" y1="40" x2="40" y2="77.25" gradientUnits="userSpaceOnUse">
      <stop stop-color="#212121" stop-opacity="0" />
      <stop offset="1" stop-color="#212121" />
    </linearGradient>
    <clipPath id="clip0_2044_27401">
      <rect width="80" height="80" fill="white" />
    </clipPath>
  </defs>
</template>
