<template>
  <path
    d="M12.0004 2.3999C12.4974 2.3999 12.9004 2.80285 12.9004 3.2999V3.8999C12.9004 4.39696 12.4974 4.7999 12.0004 4.7999C11.5033 4.7999 11.1004 4.39696 11.1004 3.8999V3.2999C11.1004 2.80285 11.5033 2.3999 12.0004 2.3999Z"
    fill="currentColor" />
  <path
    d="M6.6971 4.99978C6.34563 4.6483 5.77578 4.6483 5.42431 4.99978C5.07284 5.35125 5.07284 5.9211 5.42431 6.27257L5.84857 6.69683C6.20005 7.0483 6.76989 7.0483 7.12137 6.69683C7.47284 6.34536 7.47284 5.77551 7.12137 5.42404L6.6971 4.99978Z"
    fill="currentColor" />
  <path
    d="M2.40039 11.6999C2.40039 11.2028 2.80333 10.7999 3.30039 10.7999H3.90039C4.39745 10.7999 4.80039 11.2028 4.80039 11.6999C4.80039 12.197 4.39745 12.5999 3.90039 12.5999H3.30039C2.80333 12.5999 2.40039 12.197 2.40039 11.6999Z"
    fill="currentColor" />
  <path
    d="M19.2004 11.6999C19.2004 11.2028 19.6033 10.7999 20.1004 10.7999H20.7004C21.1974 10.7999 21.6004 11.2028 21.6004 11.6999C21.6004 12.197 21.1974 12.5999 20.7004 12.5999H20.1004C19.6033 12.5999 19.2004 12.197 19.2004 11.6999Z"
    fill="currentColor" />
  <path
    d="M18.7886 6.48468C19.1401 6.1332 19.1401 5.56336 18.7886 5.21188C18.4371 4.86041 17.8673 4.86041 17.5158 5.21188L17.0916 5.63615C16.7401 5.98762 16.7401 6.55747 17.0916 6.90894C17.443 7.26041 18.0129 7.26041 18.3644 6.90894L18.7886 6.48468Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.76884 7.19779C10.1059 4.86076 13.8949 4.86076 16.232 7.19779C17.3707 8.33653 17.9847 9.7216 17.9847 11.1545C17.9847 12.5874 17.3707 13.9725 16.232 15.1113C16.2106 15.1327 16.189 15.1538 16.1673 15.1747C15.6 15.721 15.3004 16.2144 15.3004 16.6636V17.9998C15.3004 19.6566 13.9573 20.9998 12.3004 20.9998H11.7004C10.0435 20.9998 8.7004 19.6566 8.7004 17.9998V16.6636C8.7004 16.2144 8.40078 15.721 7.83345 15.1747C7.81178 15.1538 7.79024 15.1327 7.76884 15.1113C6.6301 13.9725 6.01606 12.5874 6.01606 11.1545C6.01606 9.7216 6.6301 8.33653 7.76884 7.19779ZM14.9592 8.47058C13.3251 6.8365 10.6757 6.8365 9.04163 8.47058C8.19481 9.3174 7.81606 10.2599 7.81606 11.1545C7.81606 12.0492 8.19481 12.9916 9.04163 13.8385C9.05505 13.8519 9.06852 13.8651 9.08205 13.8781C9.52133 14.3012 10.0274 14.8807 10.3005 15.5999H13.7003C13.9734 14.8807 14.4795 14.3012 14.9188 13.8781C14.9323 13.8651 14.9458 13.8519 14.9592 13.8385C15.806 12.9916 16.1847 12.0492 16.1847 11.1545C16.1847 10.2599 15.806 9.3174 14.9592 8.47058ZM13.5004 17.3999H10.5004V17.9998C10.5004 18.6625 11.0377 19.1998 11.7004 19.1998H12.3004C12.9631 19.1998 13.5004 18.6625 13.5004 17.9998V17.3999Z"
    fill="currentColor" />
</template>
