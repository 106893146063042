<template>
  <path
    d="M9.77692 3.96421C9.86796 3.64558 9.68346 3.31349 9.36484 3.22246C9.04622 3.13142 8.71413 3.31592 8.62309 3.63454L6.22309 12.0345C6.13206 12.3532 6.31655 12.6853 6.63518 12.7763C6.9538 12.8673 7.28589 12.6828 7.37692 12.3642L9.77692 3.96421Z"
    fill="currentColor" />
  <path
    d="M5.62426 4.97515C5.85858 5.20946 5.85858 5.58936 5.62426 5.82368L3.44853 7.99941L5.62426 10.1751C5.85858 10.4095 5.85858 10.7894 5.62426 11.0237C5.38995 11.258 5.01005 11.258 4.77574 11.0237L2.17574 8.42368C1.94142 8.18936 1.94142 7.80946 2.17574 7.57515L4.77574 4.97515C5.01005 4.74084 5.38995 4.74084 5.62426 4.97515Z"
    fill="currentColor" />
  <path
    d="M10.3757 11.0237C10.1414 10.7894 10.1414 10.4095 10.3757 10.1751L12.5515 7.99941L10.3757 5.82368C10.1414 5.58936 10.1414 5.20946 10.3757 4.97515C10.6101 4.74084 10.99 4.74084 11.2243 4.97515L13.8243 7.57515C14.0586 7.80946 14.0586 8.18936 13.8243 8.42368L11.2243 11.0237C10.99 11.258 10.6101 11.258 10.3757 11.0237Z"
    fill="currentColor" />
</template>
