<script lang="ts" setup></script>

<template>
  <div class="flex">
    <div class="relative z-[1] max-h-[324px] min-h-[174px] w-full shrink grow cursor-pointer xl:max-h-[315px]">
      <div class="absolute inset-0 flex items-center justify-center bg-white">
        <GLoadingPlaceholder cls="!rounded-b-none" width="100%" height="100%"></GLoadingPlaceholder>
      </div>
    </div>
  </div>
</template>
