import caretDownIcon from './caret-down.vue';
import caretRightIcon from './caret-right.vue';
import eyeCloseIcon from './eye-close.vue';
import eyeOpenIcon from './eye-open.vue';
import layerTreeIcon from './layer-tree.vue';
import ltButtonIcon from './lt-button.vue';
import ltCountdownIcon from './lt-countdown.vue';
import ltCssCodeIcon from './lt-css-code.vue';
import ltFormCheckboxIcon from './lt-form-checkbox.vue';
import ltFormFieldIcon from './lt-form-field.vue';
import ltHeadingIcon from './lt-heading.vue';
import ltIconIcon from './lt-icon.vue';
import ltImageComparisonIcon from './lt-image-comparison.vue';
import ltImageIcon from './lt-image.vue';
import ltLineIcon from './lt-line.vue';
import ltPopupIcon from './lt-popup.vue';
import ltShopifyIcon from './lt-shopify.vue';
import ltSubmitButtonIcon from './lt-submit-button.vue';
import ltTextIcon from './lt-text.vue';
import ltThemeSection from './lt-theme-section.vue';
import ltVideoIcon from './lt-video.vue';
import threeDotIcon from './three-dot.vue';

export const layerTreeIcons = {
  'caret-down': caretDownIcon,
  'caret-right': caretRightIcon,
  'eye-close': eyeCloseIcon,
  'eye-open': eyeOpenIcon,
  'layer-tree': layerTreeIcon,
  'lt-button': ltButtonIcon,
  'lt-countdown': ltCountdownIcon,
  'lt-css-code': ltCssCodeIcon,
  'lt-form-checkbox': ltFormCheckboxIcon,
  'lt-form-field': ltFormFieldIcon,
  'lt-heading': ltHeadingIcon,
  'lt-icon': ltIconIcon,
  'lt-image-comparison': ltImageComparisonIcon,
  'lt-image': ltImageIcon,
  'lt-line': ltLineIcon,
  'lt-popup': ltPopupIcon,
  'lt-shopify': ltShopifyIcon,
  'lt-submit-button': ltSubmitButtonIcon,
  'lt-text': ltTextIcon,
  'lt-theme-section': ltThemeSection,
  'lt-video': ltVideoIcon,
  'three-dot': threeDotIcon,
};
