<template>
  <path
    d="M0 3C0 1.34315 1.34315 0 3 0H45C46.6569 0 48 1.34315 48 3V45C48 46.6569 46.6569 48 45 48H3C1.34315 48 0 46.6569 0 45V3Z"
    fill="currentColor"
    transform="translate(12,12)" />

  <rect
    x="44"
    y="16"
    width="12"
    height="12"
    rx="2.6"
    fill="currentColor"
    class="gp-icon-highlight-sub gp-icon-highlight-sub-main" />
  <rect
    x="44"
    y="30"
    width="12"
    height="12"
    rx="2.6"
    fill="currentColor"
    class="gp-icon-highlight-sub gp-icon-highlight-sub-main" />
  <rect
    x="44"
    y="44"
    width="12"
    height="12"
    rx="2.6"
    fill="currentColor"
    class="gp-icon-highlight-sub gp-icon-highlight-sub-main" />
</template>
