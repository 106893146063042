export const COLOR_PICKER_MODE = {
  BOTH: 'both',
  SOLID: 'solid',
  GRADIENT: 'gradient',
} as const;

export const THEME_DARK = 'dark';
export const THEME_LIGHT = 'light';

export type WhereInput = 'hexInput' | 'opacityInput';
