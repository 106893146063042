<template>
  <path
    d="M24.8872 8.01303C24.8714 7.87466 24.7446 7.81316 24.6496 7.79778C24.5545 7.78241 22.6535 7.76703 22.6535 7.76703C22.6535 7.76703 21.0534 6.26028 20.895 6.10653C20.7366 5.95278 20.4356 5.9989 20.3089 6.02965C20.3089 6.02965 20.0079 6.1219 19.5009 6.27565C19.4217 6.01428 19.295 5.6914 19.1207 5.36852C18.5504 4.30765 17.7266 3.75415 16.7127 3.75415C16.6493 3.75415 16.5701 3.75415 16.5068 3.76952C16.4751 3.73877 16.4434 3.70802 16.4117 3.6619C15.9681 3.20064 15.4137 2.98539 14.7483 3.00077C13.4492 3.03152 12.166 3.93865 11.1046 5.5684C10.36 6.70615 9.80557 8.13603 9.64715 9.24304C8.158 9.68891 7.11242 9.99642 7.09658 10.0118C6.352 10.2424 6.32032 10.2578 6.22527 10.9497C6.14606 11.457 4.18164 26.2325 4.18164 26.2325L20.6732 29L27.818 27.278C27.818 27.278 24.9031 8.13603 24.8872 8.01303ZM18.6771 6.52165C18.2969 6.62928 17.8692 6.76765 17.3939 6.90603C17.3781 6.27565 17.2989 5.3839 16.9979 4.61515C17.9959 4.79965 18.4712 5.8759 18.6771 6.52165ZM16.5384 7.16741C15.6671 7.42878 14.7325 7.70553 13.7819 7.99766C14.0512 7.01366 14.5582 6.02965 15.176 5.3839C15.4137 5.1379 15.7305 4.87652 16.1107 4.72277C16.4751 5.4454 16.5384 6.47553 16.5384 7.16741ZM14.7641 3.8464C15.0651 3.8464 15.3186 3.9079 15.5404 4.04627C15.1919 4.2154 14.8433 4.47677 14.5265 4.81502C13.7027 5.67603 13.069 6.99828 12.8156 8.28979C12.0235 8.52041 11.263 8.75104 10.5501 8.96629C11.0254 6.93678 12.768 3.9079 14.7641 3.8464Z"
    fill="#95BF47" />
  <path
    d="M24.6492 7.79815C24.5542 7.78278 22.6531 7.7674 22.6531 7.7674C22.6531 7.7674 21.0531 6.26065 20.8946 6.1069C20.8313 6.0454 20.7521 6.01465 20.6729 6.01465V29.0004L27.8176 27.2784C27.8176 27.2784 24.9027 8.15178 24.8868 8.01341C24.8552 7.87503 24.7443 7.81353 24.6492 7.79815Z"
    fill="#5E8E3E" />
  <path
    d="M16.7131 11.364L15.8893 14.3775C15.8893 14.3775 14.9705 13.9624 13.8615 14.0393C12.2457 14.1315 12.2298 15.1155 12.2457 15.3769C12.3407 16.7299 15.9844 17.0221 16.1903 20.1739C16.3488 22.6647 14.8279 24.356 12.6417 24.4943C10.0119 24.6481 8.57031 23.1567 8.57031 23.1567L9.12478 20.8658C9.12478 20.8658 10.5823 21.9267 11.7387 21.8652C12.4991 21.8191 12.7685 21.2194 12.7368 20.7889C12.6259 19.0362 9.64756 19.1284 9.45745 16.2533C9.29903 13.824 10.9466 11.364 14.5744 11.1334C16.0002 11.0104 16.7131 11.364 16.7131 11.364Z"
    fill="white" />
  <path
    d="M84.6272 2.00862L59.2705 9.94579C59.1704 9.97914 59.1148 10.0903 59.1371 10.2015L61.0936 16.6379C61.1269 16.738 61.2381 16.8047 61.3381 16.7713L79.936 10.9463C80.8253 10.6684 81.5701 10.0458 82.0037 9.2121C83.1598 6.9888 84.1158 4.67657 84.8495 2.27541C84.9162 2.09755 84.7828 1.96415 84.6272 2.00862Z"
    fill="#FFBB1C" />
  <path
    d="M78.5462 13.1363L62.7942 18.0609C62.7052 18.0942 62.6497 18.1831 62.683 18.2721L64.3394 23.708C64.3727 23.797 64.4616 23.8525 64.5395 23.8192L74.5888 20.6732C75.3447 20.4398 75.9672 19.9062 76.334 19.2059C77.3123 17.3272 78.1238 15.3707 78.7352 13.3475C78.7908 13.2141 78.6685 13.0918 78.5462 13.1363Z"
    fill="#E8486C" />
  <path
    d="M72.5555 23.0745L65.9857 25.1311C65.9079 25.1533 65.8745 25.2311 65.8968 25.3089L67.2975 29.9001C67.3197 29.9779 67.3975 30.0223 67.4753 29.989L69.2206 29.4443C69.8542 29.2442 70.3878 28.7995 70.6991 28.1992C71.5328 26.6096 72.2109 24.9643 72.7334 23.2413C72.7668 23.1412 72.6667 23.0301 72.5555 23.0745Z"
    fill="#476DF2" />
  <path
    d="M46.3705 14.2143C46.1702 14.0243 45.8454 14.0243 45.6451 14.2143C45.4448 14.4044 45.4448 14.7126 45.6451 14.9027L48.3825 17.5001L38.608 17.5001C38.3319 17.5001 38.108 17.724 38.108 18.0001C38.108 18.2763 38.3319 18.5001 38.608 18.5001L48.3825 18.5001L45.6451 21.0976C45.4448 21.2877 45.4448 21.5959 45.6451 21.7859C45.8454 21.976 46.1702 21.976 46.3705 21.7859L49.9975 18.3443C50.1978 18.1542 50.1978 17.8461 49.9975 17.656L46.3705 14.2143Z"
    fill="#848484" />
</template>
