<script lang="ts" setup>
import type { ModeType } from '../types/common';
import { computed } from 'vue';

type Props = {
  type?: 'primary' | 'warning';
  mode?: ModeType;
};

const props = defineProps<Props>();

const classes = computed(() => {
  const { type } = props;
  const className: string[] = [];
  if (props.mode === 'dark') {
    switch (type) {
      case 'primary':
        className.push('bg-primary-300 text-white');
        break;
      case 'warning':
        {
          className.push('bg-yellow-300 text-dark-400');
        }
        break;
    }
  } else {
    switch (type) {
      case 'primary':
        className.push('bg-primary-300 text-white');
        break;
      case 'warning':
        {
          className.push('bg-yellow-300 text-dark-400');
        }
        break;
    }
  }

  return className.join(' ');
});
</script>

<template>
  <span class="rounded-medium text-12 inline-block px-4 font-medium" :class="classes">
    <slot></slot>
  </span>
</template>
