import type { RadioProps } from '../types';

import { computed } from 'vue';

export function useRadioTone({ props }: { props: RadioProps }) {
  const tone = props.tone || 'light';

  const isLightTone = computed(() => tone === 'light');
  const isDarkTone = computed(() => tone === 'dark');

  const containerCls = computed(() => {
    if (isDarkTone.value) return ['text-white'];
    if (isLightTone.value) return ['text-black'];

    return [];
  });

  return { isLightTone, isDarkTone, containerCls };
}
