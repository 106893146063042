<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15.3757 2.09794C15.3211 1.87949 15.1532 1.70731 14.9362 1.6472C14.7192 1.5871 14.4866 1.64837 14.3274 1.80758L11.9428 4.19222C11.7875 4.34746 11.7251 4.57276 11.7784 4.78575L12.2887 6.82708L9.55812 9.55765C9.31405 9.80173 9.31405 10.1975 9.55812 10.4415C9.8022 10.6856 10.1979 10.6856 10.442 10.4415L13.1726 7.71096L15.2139 8.22129C15.4269 8.27454 15.6522 8.21213 15.8074 8.0569L18.1921 5.67226C18.3513 5.51304 18.4126 5.28049 18.3524 5.06349C18.2923 4.84649 18.1202 4.67859 17.9017 4.62398L15.8809 4.11877L15.3757 2.09794ZM15.1738 6.92279L13.4962 6.50341L13.0769 4.82588L14.4307 3.47202L14.7592 4.78575C14.8151 5.00967 14.99 5.18452 15.2139 5.2405L16.5276 5.56893L15.1738 6.92279Z"
    fill="currentColor" />
  <path
    d="M8.91636 7.25502C9.23778 7.12918 9.39634 6.76661 9.27051 6.44518C9.14467 6.12376 8.7821 5.9652 8.46067 6.09103C7.81031 6.34564 7.23367 6.75835 6.7829 7.29183C6.33212 7.8253 6.02143 8.46273 5.87893 9.14646C5.73642 9.8302 5.76659 10.5387 5.96671 11.2078C6.16683 11.8769 6.53058 12.4857 7.02508 12.9789C7.51957 13.4721 8.12921 13.8343 8.79887 14.0327C9.46852 14.2311 10.1771 14.2594 10.8604 14.1152C11.5438 13.9709 12.1804 13.6586 12.7127 13.2064C13.2451 12.7543 13.6563 12.1766 13.9092 11.5256C14.0342 11.2038 13.8747 10.8416 13.553 10.7166C13.2312 10.5916 12.8691 10.7511 12.7441 11.0729C12.5664 11.5302 12.2775 11.9361 11.9035 12.2537C11.5296 12.5714 11.0823 12.7908 10.6022 12.8921C10.1222 12.9935 9.62439 12.9736 9.15394 12.8342C8.6835 12.6948 8.25522 12.4404 7.90782 12.0939C7.56043 11.7474 7.30488 11.3197 7.1643 10.8496C7.02371 10.3796 7.00252 9.88185 7.10263 9.40151C7.20274 8.92118 7.42101 8.47337 7.73768 8.09859C8.05436 7.72382 8.45946 7.43388 8.91636 7.25502Z"
    fill="currentColor" />
  <path
    d="M9.50577 2.89255C9.85012 2.86863 10.1099 2.57009 10.086 2.22574C10.062 1.88139 9.76349 1.62164 9.41914 1.64556C7.81959 1.75669 6.28555 2.32464 4.99928 3.28195C3.71302 4.23926 2.72859 5.54568 2.16295 7.046C1.59731 8.54633 1.47425 10.1775 1.80837 11.7457C2.14249 13.3139 2.91976 14.7533 4.04783 15.8927C5.1759 17.0322 6.60735 17.8239 8.17213 18.1737C9.7369 18.5236 11.3692 18.4169 12.8752 17.8664C14.3811 17.3159 15.6973 16.3446 16.6675 15.068C17.6377 13.7915 18.2211 12.2632 18.3482 10.6649C18.3756 10.3208 18.1189 10.0196 17.7748 9.99225C17.4307 9.96487 17.1296 10.2216 17.1022 10.5657C16.994 11.9255 16.4977 13.2257 15.6723 14.3117C14.8469 15.3977 13.7271 16.224 12.446 16.6924C11.1648 17.1608 9.7761 17.2515 8.44488 16.9539C7.11365 16.6562 5.89585 15.9827 4.93614 15.0133C3.97644 14.0439 3.31518 12.8194 3.03093 11.4852C2.74667 10.1511 2.85137 8.76337 3.33259 7.48697C3.8138 6.21057 4.6513 5.09913 5.74559 4.28471C6.83988 3.47028 8.14496 2.98709 9.50577 2.89255Z"
    fill="currentColor" />
</template>
