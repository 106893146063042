<script lang="ts" setup>
import { useSlots } from 'vue';

const slots = useSlots();

const hasSlot = (name: string) => {
  return !!slots[name];
};
</script>
<template>
  <div class="bg-dark-300 flex h-32 flex-row items-center justify-between">
    <div class="flex flex-row justify-start">
      <div v-if="hasSlot('text')" class="text-14 text-light-450 p-8 pl-0 font-medium"><slot name="text"></slot></div>
      <slot name="label"></slot>
    </div>
    <div>
      <slot name="action"></slot>
    </div>
  </div>
</template>
