<template>
  <g clip-path="url(#clip0_16509_22651)">
    <path d="M14.5 2C15.3284 2 16 2.67157 16 3.5L16 12.5C16 13.3284 15.3284 14 14.5 14L14.5 2Z" fill="#F9F9F9" />
    <path
      d="M1.5 2C0.671573 2 -2.93554e-08 2.67157 -6.55671e-08 3.5L-4.5897e-07 12.5C-4.95181e-07 13.3284 0.671572 14 1.5 14L1.5 2Z"
      fill="#5E5E5E" />
    <path d="M14 14.5C14 15.3284 13.3284 16 12.5 16L3.5 16C2.67157 16 2 15.3284 2 14.5L14 14.5Z" fill="#5E5E5E" />
    <path d="M14 1.5C14 0.671573 13.3284 0 12.5 0H3.5C2.67157 0 2 0.671573 2 1.5H14Z" fill="#5E5E5E" />
  </g>
  <defs>
    <clipPath id="clip0_16509_22651">
      <rect width="16" height="16" fill="white" transform="translate(16) rotate(90)" />
    </clipPath>
  </defs>
</template>
