<template>
  <path
    d="M12.2212 4.95599C12.3349 4.55771 12.1043 4.1426 11.7061 4.02881C11.3078 3.91501 10.8927 4.14563 10.7789 4.54391L7.77887 15.0439C7.66507 15.4422 7.89569 15.8573 8.29397 15.9711C8.69225 16.0849 9.10736 15.8543 9.22115 15.456L12.2212 4.95599Z"
    fill="#AAAAAA" />
  <path
    d="M7.03033 6.21967C7.32322 6.51256 7.32322 6.98744 7.03033 7.28033L4.31066 10L7.03033 12.7197C7.32322 13.0126 7.32322 13.4874 7.03033 13.7803C6.73744 14.0732 6.26256 14.0732 5.96967 13.7803L2.71967 10.5303C2.42678 10.2374 2.42678 9.76256 2.71967 9.46967L5.96967 6.21967C6.26256 5.92678 6.73744 5.92678 7.03033 6.21967Z"
    fill="#AAAAAA" />
  <path
    d="M12.9697 13.7803C12.6768 13.4874 12.6768 13.0126 12.9697 12.7197L15.6893 10L12.9697 7.28033C12.6768 6.98744 12.6768 6.51256 12.9697 6.21967C13.2626 5.92678 13.7374 5.92678 14.0303 6.21967L17.2803 9.46967C17.5732 9.76256 17.5732 10.2374 17.2803 10.5303L14.0303 13.7803C13.7374 14.0732 13.2626 14.0732 12.9697 13.7803Z"
    fill="#AAAAAA" />
</template>
