<template>
  <path
    d="M13.1666 27.5172C13.1666 28.391 12.0096 28.8287 11.3329 28.2107L7.48124 24.6936C7.06176 24.3105 7.06176 23.6895 7.48124 23.3064L11.3329 19.7893C12.0096 19.1713 13.1666 19.609 13.1666 20.4828L13.1666 27.5172Z"
    fill="currentColor" />
  <path
    d="M20.1666 13C20.1666 11.3431 21.5098 10 23.1666 10H54.1666C55.8235 10 57.1666 11.3431 57.1666 13V35C57.1666 36.6569 55.8235 38 54.1666 38H23.1666C21.5098 38 20.1666 36.6569 20.1666 35V13Z"
    fill="currentColor" />
  <path
    d="M64.1666 20.4828C64.1666 19.609 65.3236 19.1713 66.0003 19.7893L69.852 23.3064C70.2715 23.6895 70.2715 24.3105 69.852 24.6936L66.0003 28.2107C65.3236 28.8287 64.1666 28.391 64.1666 27.5172V20.4828Z"
    fill="currentColor" />
</template>
