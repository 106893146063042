<template>
  <div class="gemx-loading-logo">
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60.9414 0.020825L2.81123 18.1572C2.5764 18.2212 2.44831 18.4772 2.51236 18.7333L6.99541 33.4344C7.05945 33.6691 7.31562 33.8185 7.55045 33.7331L50.2035 20.4189C52.2315 19.7788 53.9607 18.3706 54.9427 16.4502C57.5898 11.3721 59.7887 6.0805 61.4751 0.596921C61.6032 0.234194 61.283 -0.0858595 60.9414 0.020825Z"
        fill="#FFBB1C" />
      <path
        d="M47.0012 25.4514L10.8807 36.7173C10.6885 36.7813 10.5604 36.9947 10.6245 37.2081L14.4244 49.6261C14.4884 49.8182 14.7019 49.9462 14.8941 49.8822L37.9284 42.6916C39.6576 42.1582 41.0879 40.942 41.9418 39.3418C44.1833 35.053 46.0406 30.5723 47.4496 25.9422C47.5563 25.6435 47.2788 25.3661 47.0012 25.4514Z"
        fill="#E8486C" />
      <path
        d="M31.2744 48.7893L18.2095 52.8647C18.0387 52.9074 17.9533 53.0994 17.996 53.2701L21.1982 63.7679C21.2409 63.9386 21.433 64.0452 21.6038 63.9812L23.6105 63.3625C25.0622 62.9144 26.279 61.8902 27.0048 60.5246C28.9048 56.8974 30.4632 53.1207 31.6587 49.1947C31.7654 48.9387 31.5306 48.704 31.2744 48.7893Z"
        fill="#476DF2" />
    </svg>

  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.gemx-loading-logo {
  svg {
    path {
      &:nth-of-type(1) {
        animation: blink1 2s infinite;
      }
      &:nth-of-type(2) {
        animation: blink2 2s infinite;
      }
      &:nth-of-type(3) {
        animation: blink3 2s infinite;
      }
    }
  }
}

@keyframes blink1 {
  0% {
    opacity: 0.25;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

@keyframes blink2 {
  0% {
    opacity: 0.25;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

@keyframes blink3 {
  0% {
    opacity: 0.25;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.25;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
</style>
