<template>
  <g clip-path="url(#clip0_16509_22661)">
    <path d="M2 1.5C2 0.671573 2.67157 0 3.5 0H12.5C13.3284 0 14 0.671573 14 1.5H2Z" fill="#5E5E5E" />
    <path d="M2 14.5C2 15.3284 2.67157 16 3.5 16H12.5C13.3284 16 14 15.3284 14 14.5H2Z" fill="#F9F9F9" />
    <path d="M14.5 2C15.3284 2 16 2.67157 16 3.5L16 12.5C16 13.3284 15.3284 14 14.5 14L14.5 2Z" fill="#5E5E5E" />
    <path
      d="M1.5 2C0.671573 2 2.93554e-08 2.67157 6.55671e-08 3.5L4.5897e-07 12.5C4.95181e-07 13.3284 0.671573 14 1.5 14L1.5 2Z"
      fill="#5E5E5E" />
  </g>
  <defs>
    <clipPath id="clip0_16509_22661">
      <rect width="16" height="16" fill="white" />
    </clipPath>
  </defs>
</template>
