<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.3759 1.09791C14.3213 0.879464 14.1534 0.707279 13.9364 0.647174C13.7194 0.587069 13.4869 0.648335 13.3276 0.807554L10.943 3.19219C10.7878 3.34743 10.7254 3.57273 10.7786 3.78572L11.2889 5.82705L8.55837 8.55762C8.31429 8.8017 8.31429 9.19742 8.55837 9.4415C8.80244 9.68558 9.19817 9.68558 9.44225 9.4415L12.1728 6.71093L14.2142 7.22126C14.4271 7.27451 14.6524 7.2121 14.8077 7.05687L17.1923 4.67223C17.3515 4.51301 17.4128 4.28046 17.3527 4.06346C17.2926 3.84646 17.1204 3.67856 16.902 3.62395L14.8811 3.11874L14.3759 1.09791ZM14.174 5.92276L12.4965 5.50338L12.0771 3.82585L13.431 2.47199L13.7594 3.78572C13.8154 4.00964 13.9902 4.18449 14.2142 4.24047L15.5279 4.5689L14.174 5.92276Z"
    fill="#AAAAAA" />
  <path
    d="M7.9166 6.25498C8.23803 6.12915 8.39658 5.76658 8.27075 5.44515C8.14492 5.12373 7.78234 4.96517 7.46092 5.091C6.81056 5.34561 6.23391 5.75832 5.78314 6.29179C5.33237 6.82527 5.02168 7.4627 4.87917 8.14643C4.73666 8.83016 4.76683 9.53864 4.96695 10.2078C5.16707 10.8769 5.53083 11.4856 6.02532 11.9788C6.51982 12.4721 7.12946 12.8343 7.79911 13.0327C8.46877 13.2311 9.17731 13.2594 9.86068 13.1151C10.544 12.9709 11.1807 12.6585 11.713 12.2064C12.2453 11.7542 12.6565 11.1765 12.9095 10.5255C13.0345 10.2038 12.875 9.84162 12.5532 9.71661C12.2315 9.5916 11.8693 9.7511 11.7443 10.0728C11.5666 10.5302 11.2777 10.936 10.9038 11.2537C10.5298 11.5713 10.0826 11.7907 9.60248 11.8921C9.1224 11.9934 8.62463 11.9735 8.15419 11.8341C7.68374 11.6948 7.25546 11.4403 6.90807 11.0938C6.56067 10.7473 6.30513 10.3197 6.16454 9.84962C6.02395 9.37953 6.00276 8.88182 6.10287 8.40148C6.20299 7.92115 6.42125 7.47334 6.73793 7.09856C7.05461 6.72378 7.45971 6.43385 7.9166 6.25498Z"
    fill="#AAAAAA" />
  <path
    d="M8.50602 1.89252C8.85036 1.8686 9.11012 1.57006 9.0862 1.22571C9.06228 0.881363 8.76373 0.621607 8.41938 0.64553C6.81983 0.756655 5.28579 1.32461 3.99953 2.28192C2.71326 3.23923 1.72883 4.54565 1.16319 6.04597C0.597558 7.5463 0.47449 9.17746 0.808612 10.7457C1.14274 12.3139 1.92 13.7532 3.04807 14.8927C4.17614 16.0322 5.6076 16.8238 7.17237 17.1737C8.73714 17.5236 10.3695 17.4169 11.8754 16.8664C13.3813 16.3158 14.6976 15.3446 15.6678 14.068C16.638 12.7914 17.2213 11.2632 17.3485 9.66483C17.3759 9.32074 17.1191 9.0196 16.775 8.99222C16.4309 8.96484 16.1298 9.22158 16.1024 9.56567C15.9942 10.9255 15.4979 12.2256 14.6726 13.3117C13.8472 14.3977 12.7274 15.224 11.4462 15.6924C10.165 16.1607 8.77635 16.2515 7.44512 15.9538C6.11389 15.6562 4.89609 14.9827 3.93639 14.0133C2.97668 13.0439 2.31543 11.8193 2.03117 10.4852C1.74692 9.15104 1.85162 7.76334 2.33283 6.48694C2.81405 5.21054 3.65155 4.0991 4.74583 3.28468C5.84012 2.47025 7.1452 1.98706 8.50602 1.89252Z"
    fill="#AAAAAA" />
</template>
