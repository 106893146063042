<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  type?: string;
  disabled?: boolean;
  mark?: boolean;
  code?: boolean;
  keyboard?: boolean;
  underline?: boolean;
  delete?: boolean;
  strong?: boolean;
  italic?: boolean;
}>();

const newClasses = computed(() => {
  const className: string[] = [];
  const { disabled, mark, keyboard, underline, strong, italic } = props;

  if (disabled) {
    className.push('text-gray-300 cursor-not-allowed');
  }

  if (mark) {
    className.push('bg-yellow-200');
  }

  if (keyboard) {
    className.push('gemx-text-kbd');
  }

  if (underline) {
    className.push('underline');
  }

  if (props.delete) {
    className.push('line-through');
  }

  if (strong) {
    className.push('font-bold');
  }

  if (italic) {
    className.push('italic');
  }

  switch (props.type) {
    case 'secondary':
      className.push('text-gray-400');
      break;
    case 'success':
      className.push('text-green-400');
      break;
    case 'warning':
      className.push('text-yellow-300');
      break;
    case 'danger':
      className.push('text-red-400');
      break;
  }

  return className.join(' ');
});
</script>
<template>
  <div>
    <span v-bind="$attrs" :class="newClasses">
      <template v-if="code">
        <code class="gemx-text-code"><slot /></code>
      </template>
      <slot v-else />
    </span>
  </div>
</template>

<style scoped>
.gemx-text-code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.gemx-text-kbd {
  margin: 0 0.2em;
  padding: 0.15em 0.4em 0.1em;
  font-size: 90%;
  background: rgba(150, 150, 150, 0.06);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-width: 2px;
  border-radius: 3px;
}
</style>
