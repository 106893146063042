<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.7998 10.4C6.46843 10.4 6.1998 10.6687 6.1998 11C6.1998 11.3314 6.46843 11.6 6.7998 11.6H9.19981C9.53118 11.6 9.79981 11.3314 9.79981 11C9.79981 10.6687 9.53118 10.4 9.19981 10.4H6.7998Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.9998 2.40002C4.78478 2.40002 3.7998 3.385 3.7998 4.60002V11.4C3.7998 12.6151 4.78478 13.6 5.9998 13.6H9.99981C11.2148 13.6 12.1998 12.6151 12.1998 11.4V4.60002C12.1998 3.385 11.2148 2.40002 9.99981 2.40002H5.9998ZM4.9998 4.60002C4.9998 4.04774 5.44752 3.60002 5.9998 3.60002H9.99981C10.5521 3.60002 10.9998 4.04774 10.9998 4.60002V11.4C10.9998 11.9523 10.5521 12.4 9.99981 12.4H5.9998C5.44752 12.4 4.9998 11.9523 4.9998 11.4V4.60002Z"
      fill="currentColor" />
  </svg>
</template>
