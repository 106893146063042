<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.02889 5.25C8.20657 5.25 7.57056 5.81791 7.57056 6.70794C7.57056 7.05311 7.29073 7.33294 6.94556 7.33294C6.60038 7.33294 6.32056 7.05311 6.32056 6.70794C6.32056 5.08282 7.56183 4 9.02889 4C10.5605 4 11.8376 5.09394 11.8376 6.70794C11.8376 7.29841 11.5903 7.79833 11.3106 8.18645C11.0318 8.57335 10.6866 8.89548 10.4248 9.13178C9.82675 9.67145 9.65428 10.2637 9.6543 10.875C9.65431 11.2202 9.37449 11.5 9.02931 11.5C8.68414 11.5 8.40431 11.2202 8.4043 10.875C8.40427 9.97674 8.68087 9.0218 9.58731 8.20379C9.84353 7.97257 10.1016 7.7261 10.2965 7.45562C10.4906 7.18636 10.5876 6.94012 10.5876 6.70794C10.5876 5.86226 9.9508 5.25 9.02889 5.25Z"
      fill="#AAAAAA" />
    <path
      d="M8.4043 13.375C8.4043 13.0298 8.68412 12.75 9.0293 12.75C9.37448 12.75 9.6543 13.0298 9.6543 13.375C9.6543 13.7202 9.37448 14 9.0293 14C8.68412 14 8.4043 13.7202 8.4043 13.375Z"
      fill="#AAAAAA" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.25 9C0.25 4.16751 4.16751 0.25 9 0.25C13.8325 0.25 17.75 4.16751 17.75 9C17.75 13.8325 13.8325 17.75 9 17.75C4.16751 17.75 0.25 13.8325 0.25 9ZM9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5Z"
      fill="#AAAAAA" />
  </svg>
</template>
