<script lang="ts" setup>
import { ref } from 'vue';

const props = defineProps<{
  layoutSetting?: string;
  shopDomain?: string;
}>();

const currentOptions = ref({
  showHeader: false,
  showFooter: false,
});

const emit = defineEmits(['changeLayoutSetting']);

if (props.layoutSetting) {
  currentOptions.value = JSON.parse(props.layoutSetting);
}

const changeFooter = (value: boolean) => {
  currentOptions.value.showFooter = value;
  emit('changeLayoutSetting', JSON.stringify(currentOptions.value));
};

const changeHeader = (value: boolean) => {
  currentOptions.value.showHeader = value;
  emit('changeLayoutSetting', JSON.stringify(currentOptions.value));
};
</script>
<template>
  <div class="rounded-xl bg-white p-16">
    <div class="text-14 text-dark-400 mb-4 font-medium leading-[24px]">Shopify theme header & footer</div>
    <div class="text-12 text-text-light-300 border-light-400 mb-16 border-b pb-16 font-normal">
      To edit those sections, please follow instruction in
      <a
        class="text-primary-300 cursor-pointer no-underline"
        href="https://help.gempages.net/faqs/edit-footer-header/"
        target="_blank"
        >this article</a
      >
    </div>
    <div class="mb-16">
      <div class="flex items-center justify-between">
        <GCheckbox
          input-classes="!h-16 !w-16"
          text="Use Shopify theme header"
          :value="currentOptions.showHeader"
          @on-change="changeHeader" />
        <GCheckbox
          input-classes="!h-16 !w-16"
          text="Use Shopify theme footer"
          :value="currentOptions.showFooter"
          @on-change="changeFooter" />
      </div>
    </div>
    <div class="border-light-500 rounded-[6px] border">
      <div
        v-if="currentOptions.showHeader"
        class="bg-light border-1 border-light-400 flex h-[64px] items-center justify-between border-b px-[32px]">
        <svg width="84" height="8" viewBox="0 0 84 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="22" height="8" rx="3" fill="#9E9E9E" />
          <rect x="28" width="56" height="8" rx="3" fill="#E1E1E1" />
        </svg>
        <svg width="192" height="8" viewBox="0 0 192 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="56" height="8" rx="3" fill="#E1E1E1" />
          <rect x="68" width="56" height="8" rx="3" fill="#E1E1E1" />
          <rect x="136" width="56" height="8" rx="3" fill="#E1E1E1" />
        </svg>
      </div>
      <div class="text-text-light-300 text-12 flex h-[201px] items-center justify-center bg-white">GemPages Design</div>
      <div
        v-if="currentOptions.showFooter"
        class="bg-light border-1 border-light-400 flex h-[64px] items-center justify-between border-t px-[32px]">
        <svg width="192" height="8" viewBox="0 0 192 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="56" height="8" rx="3" fill="#E1E1E1" />
          <rect x="68" width="56" height="8" rx="3" fill="#E1E1E1" />
          <rect x="136" width="56" height="8" rx="3" fill="#E1E1E1" />
        </svg>
        <svg width="82" height="8" viewBox="0 0 82 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="22" height="8" rx="3" fill="#E1E1E1" />
          <rect x="30" width="22" height="8" rx="3" fill="#E1E1E1" />
          <rect x="60" width="22" height="8" rx="3" fill="#E1E1E1" />
        </svg>
      </div>
    </div>
  </div>
</template>
