<template>
  <path
    d="M1.25 10C1.25 9.17157 1.92157 8.5 2.75 8.5C3.57843 8.5 4.25 9.17157 4.25 10C4.25 10.8284 3.57843 11.5 2.75 11.5C1.92157 11.5 1.25 10.8284 1.25 10Z"
    fill="currentColor" />
  <path
    d="M6.08333 10C6.08333 9.17157 6.75491 8.5 7.58333 8.5C8.41176 8.5 9.08333 9.17157 9.08333 10C9.08333 10.8284 8.41176 11.5 7.58333 11.5C6.75491 11.5 6.08333 10.8284 6.08333 10Z"
    fill="currentColor" />
  <path
    d="M10.9167 10C10.9167 9.17157 11.5883 8.5 12.4167 8.5C13.2451 8.5 13.9167 9.17157 13.9167 10C13.9167 10.8284 13.2451 11.5 12.4167 11.5C11.5883 11.5 10.9167 10.8284 10.9167 10Z"
    fill="currentColor" />
  <path
    d="M15.75 10C15.75 9.17157 16.4216 8.5 17.25 8.5C18.0784 8.5 18.75 9.17157 18.75 10C18.75 10.8284 18.0784 11.5 17.25 11.5C16.4216 11.5 15.75 10.8284 15.75 10Z"
    fill="currentColor" />
</template>
