<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0.833984 8C0.833984 3.85787 4.19185 0.5 8.33398 0.5H69.6673C73.8094 0.5 77.1673 3.85787 77.1673 8V40C77.1673 44.1421 73.8095 47.5 69.6673 47.5H8.33399C4.19186 47.5 0.833984 44.1421 0.833984 40V8Z" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0.833984 8C0.833984 3.85787 4.19185 0.5 8.33398 0.5H69.6673C73.8094 0.5 77.1673 3.85787 77.1673 8V40C77.1673 44.1421 73.8095 47.5 69.6673 47.5H8.33399C4.19186 47.5 0.833984 44.1421 0.833984 40V8Z" />
  <rect x="11" y="10" width="56" height="28" rx="3" fill="currentColor" />
  <rect
    x="26.5"
    y="28"
    width="4"
    height="4"
    rx="2"
    class="text-dark-50 group-hover:text-text-dark-300 group-[.active]:text-text-dark-300"
    fill="currentColor" />
  <rect
    x="33.5"
    y="28"
    width="4"
    height="4"
    rx="2"
    class="text-text-dark-100 group-hover:text-light-200 group-[.active]:text-light-200"
    fill="currentColor" />
  <rect
    x="40.5"
    y="28"
    width="4"
    height="4"
    rx="2"
    class="text-dark-50 group-hover:text-text-dark-300 group-[.active]:text-text-dark-300"
    fill="currentColor" />
  <rect
    x="47.5"
    y="28"
    width="4"
    height="4"
    rx="2"
    class="text-dark-50 group-hover:text-text-dark-300 group-[.active]:text-text-dark-300"
    fill="currentColor" />
</template>
