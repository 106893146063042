<script lang="ts" setup>
import { GButtonV2 } from '@gem/uikit';
import { isMacOs } from '@gem/common';

type Props = {
  hasUndo?: boolean;
  hasRedo?: boolean;
};

const emits = defineEmits<{
  (e: 'undo'): void;
  (e: 'redo'): void;
}>();
const props = withDefaults(defineProps<Props>(), {
  hasUndo: false,
  hasRedo: false,
});

const textByOs = isMacOs() ? '⌘' : 'Ctrl';

const handleUndo = () => {
  if (props.hasUndo) {
    emits('undo');
  }
};

const handleRedo = () => {
  if (props.hasRedo) {
    emits('redo');
  }
};
</script>

<template>
  <div class="bg-dark-400 ml-8 inline-flex overflow-hidden rounded-xl">
    <g-tooltip placement="bottom-center">
      <GButtonV2
        data-test="editor-btn-undo"
        type="tertiary"
        only-icon="undo"
        no-rounded="right"
        :disable="!hasUndo"
        @click="handleUndo"></GButtonV2>
      <template #content>
        <span>Undo &nbsp; {{ textByOs }}+Z</span>
      </template>
    </g-tooltip>
    <div class="bg-dark-500 w-[1px]"></div>
    <g-tooltip placement="bottom-center">
      <GButtonV2
        data-test="editor-btn-redo"
        type="tertiary"
        only-icon="redo"
        no-rounded="left"
        :disable="!hasRedo"
        @click="handleRedo"></GButtonV2>
      <template #content>
        <span>Redo &nbsp; {{ textByOs }}+Shift+Z</span>
      </template>
    </g-tooltip>
  </div>
</template>
