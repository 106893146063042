<template>
  <path
    d="M15.7464 3.91421C15.9417 3.71895 15.9417 3.40237 15.7464 3.20711L14.6858 2.14645C14.4905 1.95118 14.1739 1.95118 13.9787 2.14645L13.0215 3.10356L14.7893 4.87133L15.7464 3.91421Z"
    fill="currentColor" />
  <path
    d="M14.0822 5.57844L12.3144 3.81067L9.61546 6.50964C9.24209 6.88301 9.03152 7.38889 9.02969 7.91692L9.02727 8.61474C9.02679 8.75348 9.13939 8.86608 9.27813 8.8656L9.97595 8.86318C10.504 8.86135 11.0099 8.65078 11.3832 8.27741L14.0822 5.57844Z"
    fill="currentColor" />
  <path
    d="M5.5 5.56066C5.5 4.8703 6.05964 4.31066 6.75 4.31066H10C10.4142 4.31066 10.75 3.97487 10.75 3.56066C10.75 3.14645 10.4142 2.81066 10 2.81066H6.75C5.23122 2.81066 4 4.04188 4 5.56066V15.0607C4 16.5794 5.23122 17.8107 6.75 17.8107H13.25C14.7688 17.8107 16 16.5794 16 15.0607V7.81066C16 7.39645 15.6642 7.06066 15.25 7.06066C14.8358 7.06066 14.5 7.39645 14.5 7.81066V15.0607C14.5 15.751 13.9404 16.3107 13.25 16.3107H6.75C6.05964 16.3107 5.5 15.751 5.5 15.0607V5.56066Z"
    fill="currentColor" />
  <path
    d="M7.75 10.3107C7.33579 10.3107 7 10.6464 7 11.0607C7 11.4749 7.33579 11.8107 7.75 11.8107H12.25C12.6642 11.8107 13 11.4749 13 11.0607C13 10.6464 12.6642 10.3107 12.25 10.3107H7.75Z"
    fill="currentColor" />
  <path
    d="M7 14.0607C7 13.6464 7.33579 13.3107 7.75 13.3107H10.5001C10.9143 13.3107 11.2501 13.6464 11.2501 14.0607C11.2501 14.4749 10.9143 14.8107 10.5001 14.8107H7.75C7.33579 14.8107 7 14.4749 7 14.0607Z"
    fill="currentColor" />
</template>
