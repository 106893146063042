<template>
  <path
    d="M13.448 7.5C14.0003 7.5 14.448 7.05228 14.448 6.5C14.448 5.94772 14.0003 5.5 13.448 5.5C12.8957 5.5 12.448 5.94772 12.448 6.5C12.448 7.05228 12.8957 7.5 13.448 7.5Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.7239 3C10.7048 3 9.72962 3.41477 9.02273 4.14885L3.76912 9.56605C2.72965 10.6455 2.74582 12.3585 3.80546 13.4181L6.70337 16.316C7.66668 17.2793 9.22393 17.294 10.2052 16.3491L15.9523 10.7778C16.5885 10.1651 16.948 9.31995 16.948 8.43673V6.25C16.948 4.45507 15.4929 3 13.698 3H11.7239ZM10.1032 5.18931C10.5273 4.74886 11.1125 4.5 11.7239 4.5H13.698C14.6645 4.5 15.448 5.2835 15.448 6.25V8.43673C15.448 8.91231 15.2544 9.36741 14.9119 9.69729L9.16478 15.2686C8.77225 15.6466 8.14935 15.6407 7.76403 15.2554L4.86612 12.3575C4.38446 11.8758 4.37712 11.0972 4.8496 10.6065L10.1032 5.18931Z"
    fill="currentColor" />
</template>
