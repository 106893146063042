<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1 12.8126H2V14.2501C2 14.3881 2.11193 14.5001 2.25 14.5001H3.6875V15.5001H2.25C1.55964 15.5001 1 14.9404 1 14.2501V12.8126ZM1 9.93755H2V7.06255H1V9.93755ZM1 4.18755H2V2.75005C2 2.61198 2.11193 2.50005 2.25 2.50005H3.6875V1.50005H2.25C1.55964 1.50005 1 2.0597 1 2.75005V4.18755ZM6.5625 1.50005V2.50005H9.4375V1.50005H6.5625ZM12.3125 1.50005V2.50005H13.75C13.8881 2.50005 14 2.61198 14 2.75005V4.18755H15V2.75005C15 2.0597 14.4404 1.50005 13.75 1.50005H12.3125ZM15 7.06255H14V9.93755H15V7.06255ZM15 12.8126H14V14.2501C14 14.3881 13.8881 14.5001 13.75 14.5001H12.3125V15.5001H13.75C14.4404 15.5001 15 14.9404 15 14.2501V12.8126ZM9.4375 15.5001V14.5001H6.5625V15.5001H9.4375Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.8978 1.49611L14.4967 1.49609C14.6293 1.49609 14.7565 1.54877 14.8502 1.64254C14.944 1.73631 14.9967 1.86349 14.9967 1.99609V4.5987C14.9967 4.87485 14.7728 5.0987 14.4967 5.0987C14.2205 5.0987 13.9967 4.87485 13.9967 4.5987V3.21339L10.0642 7.19273C9.87014 7.38915 9.55356 7.39103 9.35714 7.19692C9.16073 7.00282 9.15885 6.68625 9.35295 6.48983L13.2996 2.4961L11.8979 2.49611C11.6217 2.49611 11.3979 2.27225 11.3979 1.99611C11.3979 1.71997 11.6217 1.49611 11.8978 1.49611ZM6.65609 9.85825C6.85111 10.0538 6.8507 10.3703 6.65519 10.5654L2.71138 14.4991L4.09838 14.4991C4.37452 14.4991 4.59838 14.723 4.59838 14.9991C4.59838 15.2752 4.37452 15.4991 4.09838 15.4991L1.50209 15.4991C1.36948 15.4991 1.24231 15.4464 1.14854 15.3527C1.05477 15.2589 1.00209 15.1317 1.00209 14.9991L1.00209 12.368C1.00209 12.0919 1.22595 11.868 1.50209 11.868C1.77823 11.868 2.00209 12.0919 2.00209 12.368V13.7942L5.94899 9.85735C6.1445 9.66234 6.46108 9.66274 6.65609 9.85825Z"
    fill="currentColor" />
</template>
