<script lang="ts" setup></script>
<template>
  <div
    id="header"
    class="gemx-header bg-dark-500 text-text-light-300 fixed top-0 flex h-[57px] w-full items-center justify-between pr-8">
    <div class="animate-fadeIn flex">
      <div class="relative z-10 flex">
        <div class=""><slot name="logo"> </slot></div>
        <div class="">
          <slot name="library"> </slot>
        </div>

        <div class="m:block hidden">
          <slot name="devices"> </slot>
        </div>
      </div>
      <div class="m:block ml-8 hidden">
        <slot name="version-history"></slot>
      </div>
      <div class="">
        <slot name="history"> </slot>
      </div>
      <slot name="layer-tree"></slot>
    </div>
    <div class="animate-fadeIn flex-1">
      <div class="m:block hidden"><slot class="" name="page"> </slot></div>
    </div>

    <div class="animate-fadeInRight relative z-10 flex h-40 items-center gap-8">
      <slot name="more-action"></slot>
      <div class="lss:block hidden">
        <slot name="save-and-preview"> </slot>
      </div>

      <slot class="" name="assign"> </slot>
      <slot name="save"> </slot>
      <slot name="publish"> </slot>
      <slot name="gettingStarted"> </slot>
    </div>
  </div>
</template>
