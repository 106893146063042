<template>
  <path
    d="M0 8C0 3.58172 3.58172 0 8 0H110C114.418 0 118 3.58172 118 8V44C118 48.4183 114.418 52 110 52H8C3.58172 52 0 48.4183 0 44V8Z"
    fill="#212121" />
  <path
    d="M0 8H30C31.6569 8 33 9.34315 33 11V41C33 42.6569 31.6569 44 30 44H0V8Z"
    fill="url(#paint0_linear_1395_43598)" />
  <rect x="37" y="8" width="44" height="36" rx="3" fill="currentColor" />
  <path
    d="M85 11C85 9.34315 86.3431 8 88 8H118V44H88C86.3431 44 85 42.6569 85 41V11Z"
    fill="url(#paint1_linear_1395_43598)" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.33543 26.225C9.22152 26.1007 9.22152 25.8993 9.33543 25.775L12.2521 22.5932C12.366 22.4689 12.5507 22.4689 12.6646 22.5932C12.7785 22.7175 12.7785 22.9189 12.6646 23.0432L9.95415 26L12.6646 28.9568C12.7785 29.0811 12.7785 29.2825 12.6646 29.4068C12.5507 29.5311 12.366 29.5311 12.2521 29.4068L9.33543 26.225Z"
    fill="#9E9E9E" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M108.665 25.775C108.778 25.8993 108.778 26.1007 108.665 26.225L105.748 29.4068C105.634 29.5311 105.449 29.5311 105.335 29.4068C105.222 29.2825 105.222 29.0811 105.335 28.9568L108.046 26L105.335 23.0432C105.222 22.9189 105.222 22.7175 105.335 22.5932C105.449 22.4689 105.634 22.4689 105.748 22.5932L108.665 25.775Z"
    fill="#9E9E9E" />
  <defs>
    <linearGradient
      id="paint0_linear_1395_43598"
      x1="33.5103"
      y1="26"
      x2="2.48972e-07"
      y2="26"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="currentColor" />
      <stop offset="1" stop-color="#212121" />
    </linearGradient>
    <linearGradient id="paint1_linear_1395_43598" x1="84.4898" y1="26" x2="118" y2="26" gradientUnits="userSpaceOnUse">
      <stop stop-color="currentColor" />
      <stop offset="1" stop-color="#212121" />
    </linearGradient>
  </defs>
</template>
