export const scrollToWithCallback = (containerId: string, { top, behavior }: ScrollToOptions, callback: () => void) => {
  const container = document.getElementById(containerId);
  if (!container) {
    return;
  }

  const onScrollEnd = () => {
    container.removeEventListener('scrollend', onScrollEnd);
    callback();
  };
  container.addEventListener('scrollend', onScrollEnd);

  container.scrollTo({
    top,
    behavior,
  });
};
