<template>
  <path
    d="M8 2C6.61934 2 5.5 3.11948 5.5 4.50025L5.5 6.5C5.5 6.77615 5.27614 7 5 7C4.72385 7 4.5 6.77614 4.5 6.5L4.5 4.50025C4.5 2.5673 6.06695 1 8 1C9.93305 1 11.5 2.56729 11.5 4.50023L11.5 6.5C11.5 6.77615 11.2761 7 11 7C10.7239 7 10.5 6.77614 10.5 6.5L10.5 4.50023C10.5 3.11947 9.38066 2 8 2Z"
    fill="currentColor" />
  <path
    d="M5.00001 9C5.27615 9 5.50001 9.22386 5.5 9.5L5.5 11.4998C5.5 12.8805 6.61934 14 8 14C9.38066 14 10.5 12.8805 10.5 11.4998L10.5 9.5C10.5 9.22385 10.7239 9 11 9C11.2761 9 11.5 9.22386 11.5 9.5L11.5 11.4998C11.5 13.4327 9.93305 15 8 15C6.06695 15 4.5 13.4327 4.5 11.4998L4.5 9.5C4.50001 9.22385 4.72386 9 5.00001 9Z"
    fill="currentColor" />
  <path
    d="M7.5 11C7.5 11.2761 7.72386 11.5 8 11.5C8.27614 11.5 8.5 11.2761 8.5 11L8.5 5C8.5 4.72386 8.27614 4.5 8 4.5C7.72386 4.5 7.5 4.72386 7.5 5L7.5 11Z"
    fill="currentColor" />
</template>
