<template>
  <path
    d="M11 13.5C11 14.0523 10.5523 14.5 10 14.5C9.44772 14.5 9 14.0523 9 13.5C9 12.9477 9.44772 12.5 10 12.5C10.5523 12.5 11 12.9477 11 13.5Z"
    fill="currentColor" />
  <path
    d="M10.75 6.25001C10.75 5.8358 10.4142 5.50001 10 5.5C9.5858 5.49999 9.25001 5.83578 9.25 6.24999L9.24994 10.75C9.24993 11.1642 9.58572 11.5 9.99993 11.5C10.4141 11.5 10.7499 11.1642 10.7499 10.75L10.75 6.25001Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17ZM10 15.5C13.0376 15.5 15.5 13.0376 15.5 10C15.5 6.96243 13.0376 4.5 10 4.5C6.96243 4.5 4.5 6.96243 4.5 10C4.5 13.0376 6.96243 15.5 10 15.5Z"
    fill="currentColor" />
</template>
