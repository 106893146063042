<template>
  <path
    d="M9.99997 6.25C10.2761 6.25001 10.5 6.47388 10.5 6.75002L10.4999 10.25C10.4999 10.5262 10.2761 10.75 9.99994 10.75C9.7238 10.75 9.49994 10.5261 9.49994 10.25L9.49996 6.74998C9.49996 6.47384 9.72382 6.24999 9.99997 6.25Z"
    fill="#AAAAAA" />
  <path
    d="M10.6667 12.6667C10.6667 13.0349 10.3682 13.3333 10 13.3333C9.63181 13.3333 9.33333 13.0349 9.33333 12.6667C9.33333 12.2985 9.63181 12 10 12C10.3682 12 10.6667 12.2985 10.6667 12.6667Z"
    fill="#AAAAAA" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10ZM16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10Z"
    fill="#AAAAAA" />
</template>
