<template>
  <path
    d="M0 8C0 3.58172 3.58172 0 8 0H108C112.418 0 116 3.58172 116 8V64C116 68.4183 112.418 72 108 72H8C3.58172 72 0 68.4183 0 64V8Z"
    fill="#212121" />
  <path
    d="M16 24C16 19.5817 19.5817 16 24 16H92C96.4183 16 100 19.5817 100 24V48C100 52.4183 96.4183 56 92 56H24C19.5817 56 16 52.4183 16 48V24Z"
    fill="#5E5E5E" />
  <rect x="24" y="28" width="34" height="6" rx="3" fill="#AAAAAA" />
  <rect x="24" y="38" width="67" height="6" rx="3" fill="#AAAAAA" />
</template>
