<template>
  <path
    d="M6.75 3.5C6.19772 3.5 5.75 3.94772 5.75 4.5V5.5C5.75 6.05228 6.19772 6.5 6.75 6.5H7.75C8.30228 6.5 8.75 6.05228 8.75 5.5V4.5C8.75 3.94772 8.30228 3.5 7.75 3.5H6.75Z"
    fill="currentColor" />
  <path
    d="M6.75 8.5C6.19772 8.5 5.75 8.94772 5.75 9.5V10.5C5.75 11.0523 6.19772 11.5 6.75 11.5H7.75C8.30228 11.5 8.75 11.0523 8.75 10.5V9.5C8.75 8.94772 8.30228 8.5 7.75 8.5H6.75Z"
    fill="currentColor" />
  <path
    d="M5.75 14.5C5.75 13.9477 6.19772 13.5 6.75 13.5H7.75C8.30228 13.5 8.75 13.9477 8.75 14.5V15.5C8.75 16.0523 8.30228 16.5 7.75 16.5H6.75C6.19772 16.5 5.75 16.0523 5.75 15.5V14.5Z"
    fill="currentColor" />
  <path
    d="M12.25 3.5C11.6977 3.5 11.25 3.94772 11.25 4.5V5.5C11.25 6.05228 11.6977 6.5 12.25 6.5H13.25C13.8023 6.5 14.25 6.05228 14.25 5.5V4.5C14.25 3.94772 13.8023 3.5 13.25 3.5H12.25Z"
    fill="currentColor" />
  <path
    d="M11.25 9.5C11.25 8.94772 11.6977 8.5 12.25 8.5H13.25C13.8023 8.5 14.25 8.94772 14.25 9.5V10.5C14.25 11.0523 13.8023 11.5 13.25 11.5H12.25C11.6977 11.5 11.25 11.0523 11.25 10.5V9.5Z"
    fill="currentColor" />
  <path
    d="M12.25 13.5C11.6977 13.5 11.25 13.9477 11.25 14.5V15.5C11.25 16.0523 11.6977 16.5 12.25 16.5H13.25C13.8023 16.5 14.25 16.0523 14.25 15.5V14.5C14.25 13.9477 13.8023 13.5 13.25 13.5H12.25Z"
    fill="currentColor" />
</template>
