<template>
  <path
    d="M4.7999 8.50005C4.7999 9.16279 4.26264 9.70005 3.5999 9.70005C2.93716 9.70005 2.3999 9.16279 2.3999 8.50005C2.3999 7.83731 2.93716 7.30005 3.5999 7.30005C4.26264 7.30005 4.7999 7.83731 4.7999 8.50005Z"
    fill="#AAAAAA" />
  <path
    d="M9.1999 8.50005C9.1999 9.16279 8.66264 9.70005 7.9999 9.70005C7.33716 9.70005 6.7999 9.16279 6.7999 8.50005C6.7999 7.83731 7.33716 7.30005 7.9999 7.30005C8.66264 7.30005 9.1999 7.83731 9.1999 8.50005Z"
    fill="#AAAAAA" />
  <path
    d="M12.3999 9.70005C13.0626 9.70005 13.5999 9.16279 13.5999 8.50005C13.5999 7.83731 13.0626 7.30005 12.3999 7.30005C11.7372 7.30005 11.1999 7.83731 11.1999 8.50005C11.1999 9.16279 11.7372 9.70005 12.3999 9.70005Z"
    fill="#AAAAAA" />
</template>
