<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M54 11H12C10.8954 11 10 11.8954 10 13V35C10 36.1046 10.8954 37 12 37H54C55.1046 37 56 36.1046 56 35V13C56 11.8954 55.1046 11 54 11ZM12 10C10.3431 10 9 11.3431 9 13V35C9 36.6569 10.3431 38 12 38H54C55.6569 38 57 36.6569 57 35V13C57 11.3431 55.6569 10 54 10H12Z"
    fill="currentColor"
    class="gp-icon-highlight" />
  <path d="M19 11H47V37H19V11Z" fill="currentColor" />
  <path
    d="M29.5 20C29.5 21.1046 28.6046 22 27.5 22C26.3954 22 25.5 21.1046 25.5 20C25.5 18.8954 26.3954 18 27.5 18C28.6046 18 29.5 18.8954 29.5 20Z"
    fill="currentColor"
    class="gp-icon-highlight" />
  <path
    d="M25.6969 28.6453L27.9097 26.0595C27.9846 25.9718 28.0772 25.9008 28.1814 25.8512C28.2856 25.8017 28.3991 25.7746 28.5145 25.7718C28.6299 25.769 28.7446 25.7905 28.8511 25.835C28.9576 25.8795 29.0535 25.9458 29.1326 26.0298L30.1673 27.1271L34.212 22.2946C34.2912 22.1999 34.3908 22.1243 34.5033 22.0733C34.6157 22.0224 34.7383 21.9974 34.8618 22.0002C34.9852 22.0031 35.1065 22.0337 35.2165 22.0898C35.3265 22.1459 35.4224 22.2261 35.4972 22.3243L40.3329 28.6772C40.4254 28.7989 40.482 28.9441 40.4964 29.0962C40.5107 29.2484 40.4823 29.4016 40.4144 29.5385C40.3464 29.6754 40.2415 29.7907 40.1115 29.8713C39.9815 29.952 39.8316 29.9948 39.6786 29.995L26.322 30C26.1649 29.9999 26.0111 29.9549 25.8789 29.8703C25.7466 29.7857 25.6413 29.6649 25.5755 29.5224C25.5098 29.3799 25.4862 29.2216 25.5077 29.0661C25.5292 28.9107 25.5949 28.7646 25.6969 28.6453Z"
    fill="currentColor"
    class="gp-icon-highlight" />
</template>
