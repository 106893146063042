<template>
  <rect x="0.833313" y="0.5" width="76.3333" height="51" rx="2.5" fill="#212121" />
  <rect x="8.33331" y="12" width="61.3333" height="28" rx="3" fill="#4A4A4A" />
  <path
    d="M18.0644 21.3594C18.1744 21.1365 18.4924 21.1365 18.6024 21.3594L19.9274 24.0441C19.9711 24.1327 20.0556 24.1941 20.1533 24.2083L23.1161 24.6388C23.3621 24.6745 23.4604 24.9769 23.2823 25.1505L21.1384 27.2402C21.0677 27.3092 21.0355 27.4085 21.0522 27.5058L21.5583 30.4566C21.6003 30.7016 21.3431 30.8885 21.123 30.7728L18.473 29.3796C18.3856 29.3337 18.2812 29.3337 18.1938 29.3796L15.5438 30.7728C15.3238 30.8885 15.0665 30.7016 15.1086 30.4566L15.6147 27.5058C15.6313 27.4085 15.5991 27.3092 15.5284 27.2402L13.3845 25.1505C13.2064 24.9769 13.3047 24.6745 13.5508 24.6388L16.5135 24.2083C16.6112 24.1941 16.6957 24.1327 16.7394 24.0441L18.0644 21.3594Z"
    fill="#AAAAAA" />
  <rect x="26.3333" y="23" width="23.3333" height="6" rx="3" fill="#AAAAAA" />
  <path
    d="M59.928 27.376C59.7836 27.5413 59.5496 27.5413 59.4053 27.376L57.5256 25.2228C57.2928 24.956 57.4577 24.5 57.787 24.5H61.5463C61.8756 24.5 62.0405 24.956 61.8076 25.2228L59.928 27.376Z"
    fill="#AAAAAA" />
  <rect x="0.833313" y="0.5" width="76.3333" height="51" rx="2.5" />
</template>
