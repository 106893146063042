<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.4 8.50001C10.4 9.8255 9.32548 10.9 8 10.9C6.67452 10.9 5.6 9.8255 5.6 8.50001C5.6 7.17453 6.67452 6.10001 8 6.10001C9.32548 6.10001 10.4 7.17453 10.4 8.50001ZM9.2 8.50001C9.2 9.16275 8.66274 9.70001 8 9.70001C7.33726 9.70001 6.8 9.16275 6.8 8.50001C6.8 7.83727 7.33726 7.30001 8 7.30001C8.66274 7.30001 9.2 7.83727 9.2 8.50001Z"
    fill="#F9F9F9" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8 3.70001C6.01882 3.70001 4.52122 4.68383 3.53812 5.72574C3.04598 6.24731 2.67214 6.79365 2.41863 7.26359C2.2919 7.4985 2.19178 7.72074 2.1218 7.91737C2.05714 8.099 2 8.30817 2 8.50001C2 8.69186 2.05714 8.90102 2.1218 9.08266C2.19178 9.27928 2.2919 9.50152 2.41863 9.73644C2.67214 10.2064 3.04598 10.7527 3.53812 11.2743C4.52122 12.3162 6.01882 13.3 8 13.3C9.98118 13.3 11.4788 12.3162 12.4619 11.2743C12.954 10.7527 13.3279 10.2064 13.5814 9.73644C13.7081 9.50152 13.8082 9.27928 13.8782 9.08266C13.9429 8.90102 14 8.69186 14 8.50001C14 8.30817 13.9429 8.099 13.8782 7.91737C13.8082 7.72074 13.7081 7.4985 13.5814 7.26359C13.3279 6.79365 12.954 6.24731 12.4619 5.72574C11.4788 4.68383 9.98118 3.70001 8 3.70001ZM3.20113 8.50167L3.20082 8.50001L3.20113 8.49836C3.20407 8.48251 3.21462 8.42568 3.25232 8.31977C3.29815 8.191 3.37138 8.02496 3.47476 7.83331C3.68147 7.45013 3.99488 6.99021 4.41092 6.54928C5.24416 5.6662 6.44655 4.90001 8 4.90001C9.55345 4.90001 10.7558 5.6662 11.5891 6.54928C12.0051 6.99021 12.3185 7.45013 12.5252 7.83331C12.6286 8.02496 12.7019 8.191 12.7477 8.31977C12.7854 8.42568 12.7959 8.48251 12.7989 8.49836L12.7992 8.50001L12.7989 8.50167C12.7959 8.51751 12.7854 8.57435 12.7477 8.68026C12.7019 8.80902 12.6286 8.97506 12.5252 9.16671C12.3185 9.5499 12.0051 10.0098 11.5891 10.4507C10.7558 11.3338 9.55345 12.1 8 12.1C6.44655 12.1 5.24416 11.3338 4.41092 10.4507C3.99488 10.0098 3.68147 9.5499 3.47476 9.16671C3.37138 8.97506 3.29815 8.80902 3.25232 8.68026C3.21462 8.57435 3.20407 8.51751 3.20113 8.50167Z"
    fill="#F9F9F9" />
</template>
