<template>
  <path
    d="M0 8C0 3.58172 3.58172 0 8 0H58C62.4183 0 66 3.58172 66 8V40C66 44.4183 62.4183 48 58 48H8C3.58172 48 0 44.4183 0 40V8Z"
    fill="#212121" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15 9.86046C16.9417 7.91402 21.7963 11.3201 15 15.6998C8.20369 11.3205 13.0583 7.91402 15 9.86046Z"
    fill="#AAAAAA" />
  <g clip-path="url(#clip0_1179_39551)">
    <path
      d="M17.4856 23.0539C17.9886 23.0538 18.3711 23.56 18.2221 24.0484L17.6875 25.8424C17.4094 26.7453 16.9101 27.1808 16.1595 27.1808C15.0384 27.1808 14.124 27.122 13.0837 26.6182C13.0354 26.5948 13.0047 26.5458 13.0047 26.4922V23.3239C13.0047 23.2261 13.107 23.1928 13.2132 23.1582C13.2951 23.1315 13.3793 23.1041 13.4206 23.0458L14.79 21.1056C15.0467 20.742 15.6051 20.7182 15.8895 21.0686C16.0373 21.2495 16.0817 21.4936 16.0072 21.715L15.6073 22.5532C15.4997 22.778 15.6685 23.054 15.9229 23.0539H17.4856Z"
      fill="#AAAAAA" />
    <path
      d="M12.1 22.9999C12.3209 22.9999 12.5 23.179 12.5 23.3999L12.5 26.5999C12.5 26.8208 12.3209 26.9999 12.1 26.9999H11.7C11.5895 26.9999 11.5 26.9104 11.5 26.7999V23.1999C11.5 23.0894 11.5895 22.9999 11.7 22.9999H12.1Z"
      fill="#AAAAAA" />
  </g>
  <path
    d="M14.8207 32.4064C14.894 32.2578 15.106 32.2578 15.1794 32.4064L16.0627 34.1962C16.0918 34.2552 16.1481 34.2962 16.2133 34.3056L18.1885 34.5926C18.3525 34.6165 18.418 34.8181 18.2993 34.9338L16.87 36.3269C16.8229 36.3729 16.8014 36.4391 16.8125 36.504L17.1499 38.4712C17.178 38.6345 17.0065 38.7591 16.8597 38.682L15.0931 37.7532C15.0348 37.7226 14.9652 37.7226 14.907 37.7532L13.1403 38.682C12.9936 38.7591 12.8221 38.6345 12.8501 38.4712L13.1875 36.504C13.1986 36.4391 13.1771 36.3729 13.13 36.3269L11.7008 34.9338C11.582 34.8181 11.6476 34.6165 11.8116 34.5926L13.7868 34.3056C13.8519 34.2962 13.9082 34.2552 13.9374 34.1962L14.8207 32.4064Z"
    fill="#AAAAAA" />
  <rect x="25" y="9.5" width="30" height="5" rx="2.5" fill="#474747" />
  <rect x="25" y="21.5" width="30" height="5" rx="2.5" fill="#474747" />
  <rect x="25" y="33.5" width="30" height="5" rx="2.5" fill="#474747" />
  <defs>
    <clipPath id="clip0_1179_39551">
      <rect width="8" height="8" fill="white" transform="translate(11 20)" />
    </clipPath>
  </defs>
</template>
