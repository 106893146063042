<template>
  <g clip-path="url(#clip0_8244_2231)">
    <rect x="11" y="-8" width="50" height="50" rx="3" fill="currentColor" />
    <rect x="11" y="44" width="50" height="50" rx="3" fill="currentColor" />
  </g>
  <defs>
    <clipPath id="clip0_8244_2231">
      <rect width="72" height="72" rx="3" fill="white" />
    </clipPath>
  </defs>
</template>
