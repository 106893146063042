<template>
  <path
    d="M4.00001 5.75C4.00001 4.23122 5.23122 3 6.75001 3H13.25C14.7688 3 16 4.23122 16 5.75V6.625C16 7.03921 15.6642 7.375 15.25 7.375C14.8358 7.375 14.5 7.03921 14.5 6.625V5.75C14.5 5.05964 13.9404 4.5 13.25 4.5H6.75001C6.05965 4.5 5.50001 5.05964 5.50001 5.75V6.625C5.50001 7.03921 5.16422 7.375 4.75001 7.375C4.33579 7.375 4.00001 7.03921 4.00001 6.625V5.75Z"
    fill="currentColor" />
  <path
    d="M4.75001 12.625C5.16422 12.625 5.50001 12.9608 5.50001 13.375V14.25C5.50001 14.9404 6.05965 15.5 6.75001 15.5H13.25C13.9404 15.5 14.5 14.9404 14.5 14.25V13.375C14.5 12.9608 14.8358 12.625 15.25 12.625C15.6642 12.625 16 12.9608 16 13.375V14.25C16 15.7688 14.7688 17 13.25 17H6.75001C5.23122 17 4.00001 15.7688 4.00001 14.25V13.375C4.00001 12.9608 4.33579 12.625 4.75001 12.625Z"
    fill="currentColor" />
  <path
    d="M9.7504 10C9.7504 10.4142 9.41462 10.75 9.0004 10.75H6.56066L7.28034 11.4697C7.57323 11.7626 7.57323 12.2374 7.28034 12.5303C6.98744 12.8232 6.51257 12.8232 6.21968 12.5303L4.21967 10.5303C4.07901 10.3897 3.99999 10.1989 4 9.99996C4.00001 9.80104 4.07905 9.61026 4.21972 9.46962L6.22013 7.46962C6.51305 7.17675 6.98792 7.1768 7.28079 7.46972C7.57365 7.76265 7.5736 8.23752 7.28068 8.53038L6.56092 9.25H9.0004C9.41462 9.25 9.7504 9.58579 9.7504 10Z"
    fill="currentColor" />
  <path
    d="M11 10.75C10.5858 10.75 10.25 10.4142 10.25 10C10.25 9.58579 10.5858 9.25 11 9.25H13.4395L12.7197 8.53038C12.4268 8.23752 12.4268 7.76265 12.7196 7.46972C13.0125 7.1768 13.4874 7.17675 13.7803 7.46962L15.7807 9.46962C15.9214 9.61026 16.0004 9.80104 16.0004 9.99996C16.0004 10.1989 15.9214 10.3897 15.7807 10.5303L13.7807 12.5303C13.4878 12.8232 13.013 12.8232 12.7201 12.5303C12.4272 12.2374 12.4272 11.7626 12.7201 11.4697L13.4397 10.75H11Z"
    fill="currentColor" />
</template>
