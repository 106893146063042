<template>
  <path
    d="M3.47916 1.25C3.82434 1.25 4.10416 1.52982 4.10416 1.875L4.10416 18.125C4.10416 18.4702 3.82434 18.75 3.47916 18.75C3.13399 18.75 2.85416 18.4702 2.85416 18.125L2.85416 1.875C2.85416 1.52982 3.13399 1.25 3.47916 1.25Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.72916 6.25C4.72916 5.21447 5.56863 4.375 6.60416 4.375H12.8542C13.8897 4.375 14.7292 5.21447 14.7292 6.25V7.5C14.7292 8.53553 13.8897 9.375 12.8542 9.375H6.60416C5.56863 9.375 4.72916 8.53553 4.72916 7.5V6.25ZM6.60416 5.625C6.25899 5.625 5.97916 5.90482 5.97916 6.25V7.5C5.97916 7.84518 6.25899 8.125 6.60416 8.125H12.8542C13.1993 8.125 13.4792 7.84518 13.4792 7.5V6.25C13.4792 5.90482 13.1993 5.625 12.8542 5.625H6.60416Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.60416 10.625C5.56863 10.625 4.72916 11.4645 4.72916 12.5V13.75C4.72916 14.7855 5.56863 15.625 6.60416 15.625H16.6042C17.6397 15.625 18.4792 14.7855 18.4792 13.75V12.5C18.4792 11.4645 17.6397 10.625 16.6042 10.625H6.60416ZM5.97916 12.5C5.97916 12.1548 6.25899 11.875 6.60416 11.875H16.6042C16.9493 11.875 17.2292 12.1548 17.2292 12.5V13.75C17.2292 14.0952 16.9493 14.375 16.6042 14.375H6.60416C6.25899 14.375 5.97916 14.0952 5.97916 13.75V12.5Z"
    fill="currentColor" />
</template>
