<template>
  <g clip-path="url(#clip0_10818_4212)">
    <path
      d="M0 2.25C0 1.00736 1.00736 0 2.25 0H61.75C62.9926 0 64 1.00736 64 2.25V45.75C64 46.9926 62.9926 48 61.75 48H2.25C1.00736 48 0 46.9926 0 45.75V2.25Z"
      fill="#333333" />
    <path
      d="M6 10.5C6 9.25736 7.00736 8.25 8.25 8.25H21.7501C22.9927 8.25 24.0001 9.25736 24.0001 10.5V24.0001C24.0001 25.2427 22.9927 26.2501 21.7501 26.2501H8.25C7.00736 26.2501 6 25.2427 6 24.0001V10.5Z"
      fill="#424242" />
    <path
      d="M10.3125 13.9788L13.4344 12.893C13.4344 12.893 13.814 13.7784 15.0031 13.7784C16.1923 13.7784 16.5677 12.875 16.5677 12.875L19.6875 13.9788L19.1683 16.7014H17.6026V21.625H12.3907V16.6998H10.8328L10.3125 13.9788Z"
      fill="#757575" />
    <path
      d="M27 10.5C27 9.25736 28.0074 8.25 29.25 8.25H55.7499C56.9926 8.25 57.9999 9.25736 57.9999 10.5C57.9999 11.7426 56.9926 12.75 55.7499 12.75H29.25C28.0074 12.75 27 11.7426 27 10.5Z"
      fill="#424242" />
    <path
      d="M27 18C27 16.7574 28.0074 15.75 29.25 15.75H35.25C36.4926 15.75 37.5 16.7574 37.5 18C37.5 19.2426 36.4926 20.25 35.25 20.25H29.25C28.0074 20.25 27 19.2426 27 18Z"
      fill="#424242" />
    <path
      d="M39 18C39 16.7574 40.0074 15.75 41.25 15.75H47.25C48.4926 15.75 49.5 16.7574 49.5 18C49.5 19.2426 48.4926 20.25 47.25 20.25H41.25C40.0074 20.25 39 19.2426 39 18Z"
      fill="#424242" />
    <rect width="64" height="13.5" transform="translate(0 34.5)" fill="#5E5E5E" />
    <path
      d="M6 41.25C6 40.0074 7.00736 39 8.25 39H16.75C17.9926 39 19 40.0074 19 41.25C19 42.4926 17.9926 43.5 16.75 43.5H8.25C7.00736 43.5 6 42.4926 6 41.25Z"
      fill="#757575" />
    <path
      d="M22 41.25C22 40.0074 23.0074 39 24.25 39H28.75C29.9926 39 31 40.0074 31 41.25C31 42.4926 29.9926 43.5 28.75 43.5H24.25C23.0074 43.5 22 42.4926 22 41.25Z"
      fill="#AAAAAA" />
    <path
      d="M32.5 41.25C32.5 40.0074 33.5074 39 34.75 39H39.25C40.4926 39 41.5 40.0074 41.5 41.25C41.5 42.4926 40.4926 43.5 39.25 43.5H34.75C33.5074 43.5 32.5 42.4926 32.5 41.25Z"
      fill="#AAAAAA" />
    <path
      d="M44.5 41.25C44.5 40.0074 45.5074 39 46.75 39H55.75C56.9926 39 58 40.0074 58 41.25C58 42.4926 56.9926 43.5 55.75 43.5H46.75C45.5074 43.5 44.5 42.4926 44.5 41.25Z"
      fill="#757575" />
  </g>
  <defs>
    <clipPath id="clip0_10818_4212">
      <path
        d="M0 2.25C0 1.00736 1.00736 0 2.25 0H61.75C62.9926 0 64 1.00736 64 2.25V45.75C64 46.9926 62.9926 48 61.75 48H2.25C1.00736 48 0 46.9926 0 45.75V2.25Z"
        fill="white" />
    </clipPath>
  </defs>
</template>
