<template>
  <svg width="78" height="48" viewBox="0 0 78 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.833984 8C0.833984 3.85787 4.19185 0.5 8.33398 0.5H69.6673C73.8094 0.5 77.1673 3.85787 77.1673 8V40C77.1673 44.1421 73.8095 47.5 69.6673 47.5H8.33399C4.19186 47.5 0.833984 44.1421 0.833984 40V8Z"
      fill="#212121" />

    <rect x="12" y="10" width="54" height="28" rx="3" fill="currentColor" />
    <g clip-path="url(#clip0_7274_2650)">
      <path
        d="M25 27.5172C25 28.391 23.843 28.8287 23.1663 28.2107L19.3146 24.6936C18.8951 24.3105 18.8951 23.6895 19.3146 23.3064L23.1663 19.7893C23.843 19.1713 25 19.609 25 20.4828L25 27.5172Z"
        fill="#9E9E9E" />
    </g>
    <g clip-path="url(#clip1_7274_2650)">
      <path
        d="M53 20.4828C53 19.609 54.157 19.1713 54.8337 19.7893L58.6854 23.3064C59.1049 23.6895 59.1049 24.3105 58.6854 24.6936L54.8337 28.2107C54.157 28.8287 53 28.391 53 27.5172L53 20.4828Z"
        fill="#9E9E9E" />
    </g>
    <defs>
      <clipPath id="clip0_7274_2650">
        <rect width="12" height="12" fill="white" transform="translate(28 30) rotate(-180)" />
      </clipPath>
      <clipPath id="clip1_7274_2650">
        <rect width="12" height="12" fill="white" transform="translate(50 18)" />
      </clipPath>
    </defs>
  </svg>
</template>
