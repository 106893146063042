<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.1649 3.33921C14.2246 3.15451 14.4859 3.15451 14.5455 3.33921L14.8187 4.18496C14.9163 4.48714 15.1517 4.725 15.4528 4.82584L16.2889 5.10581C16.4708 5.16675 16.4708 5.42417 16.2889 5.48511L15.4528 5.76508C15.1517 5.86591 14.9163 6.10378 14.8187 6.40596L14.5455 7.25171C14.4859 7.43641 14.2246 7.43641 14.1649 7.25171L13.8917 6.40596C13.7941 6.10378 13.5588 5.86591 13.2576 5.76508L12.4216 5.48511C12.2396 5.42417 12.2396 5.16675 12.4216 5.10581L13.2576 4.82584C13.5588 4.725 13.7941 4.48714 13.8917 4.18496L14.1649 3.33921ZM13.6815 5.29546C13.969 5.14517 14.204 4.91087 14.3552 4.6243C14.5064 4.91087 14.7414 5.14517 15.0289 5.29546C14.7414 5.44575 14.5064 5.68005 14.3552 5.96662C14.204 5.68005 13.969 5.44575 13.6815 5.29546Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.94473 4.13381C9.03422 3.85676 9.4262 3.85676 9.51569 4.13381L10.466 7.076C10.7589 7.98255 11.4649 8.69613 12.3682 8.99863L15.2557 9.96553C15.5287 10.0569 15.5287 10.4431 15.2557 10.5345L12.3682 11.5014C11.4649 11.8039 10.7589 12.5175 10.466 13.424L9.51569 16.3662C9.4262 16.6432 9.03422 16.6432 8.94473 16.3662L7.99438 13.424C7.70156 12.5175 6.99557 11.8039 6.09221 11.5014L3.20474 10.5345C2.93175 10.4431 2.93175 10.0569 3.20474 9.96553L6.0922 8.99863C6.99557 8.69613 7.70156 7.98255 7.99438 7.076L8.94473 4.13381ZM8.94597 7.38337L9.23021 6.5034L9.51445 7.38337C9.90488 8.5921 10.8462 9.54354 12.0507 9.94688L12.9559 10.25L12.0507 10.5531C10.8462 10.9565 9.90488 11.9079 9.51445 13.1166L9.23021 13.9966L8.94597 13.1166C8.55554 11.9079 7.61422 10.9565 6.40974 10.5531L5.50451 10.25L6.40974 9.94688C7.61422 9.54354 8.55554 8.5921 8.94597 7.38337Z"
    fill="currentColor" />
</template>
