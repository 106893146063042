<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.25 5C3.25 4.0335 4.0335 3.25 5 3.25H7.5C8.4665 3.25 9.25 4.0335 9.25 5V7.5C9.25 8.4665 8.4665 9.25 7.5 9.25H5C4.0335 9.25 3.25 8.4665 3.25 7.5V5ZM5 4.75C4.86193 4.75 4.75 4.86193 4.75 5V7.5C4.75 7.63807 4.86193 7.75 5 7.75H7.5C7.63807 7.75 7.75 7.63807 7.75 7.5V5C7.75 4.86193 7.63807 4.75 7.5 4.75H5Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.25 12.5C3.25 11.5335 4.0335 10.75 5 10.75H7.5C8.4665 10.75 9.25 11.5335 9.25 12.5V15C9.25 15.9665 8.4665 16.75 7.5 16.75H5C4.0335 16.75 3.25 15.9665 3.25 15V12.5ZM5 12.25C4.86193 12.25 4.75 12.3619 4.75 12.5V15C4.75 15.1381 4.86193 15.25 5 15.25H7.5C7.63807 15.25 7.75 15.1381 7.75 15V12.5C7.75 12.3619 7.63807 12.25 7.5 12.25H5Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 3.25C11.5335 3.25 10.75 4.0335 10.75 5V7.5C10.75 8.4665 11.5335 9.25 12.5 9.25H15C15.9665 9.25 16.75 8.4665 16.75 7.5V5C16.75 4.0335 15.9665 3.25 15 3.25H12.5ZM12.25 5C12.25 4.86193 12.3619 4.75 12.5 4.75H15C15.1381 4.75 15.25 4.86193 15.25 5V7.5C15.25 7.63807 15.1381 7.75 15 7.75H12.5C12.3619 7.75 12.25 7.63807 12.25 7.5V5Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.75 12.5C10.75 11.5335 11.5335 10.75 12.5 10.75H15C15.9665 10.75 16.75 11.5335 16.75 12.5V15C16.75 15.9665 15.9665 16.75 15 16.75H12.5C11.5335 16.75 10.75 15.9665 10.75 15V12.5ZM12.5 12.25C12.3619 12.25 12.25 12.3619 12.25 12.5V15C12.25 15.1381 12.3619 15.25 12.5 15.25H15C15.1381 15.25 15.25 15.1381 15.25 15V12.5C15.25 12.3619 15.1381 12.25 15 12.25H12.5Z"
      fill="currentColor" />
  </svg>
</template>
