<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.27551 5C7.54194 3.96486 8.4816 3.2 9.5999 3.2C10.7182 3.2 11.6579 3.96486 11.9243 5L12.9999 5C13.3313 5 13.5999 5.26863 13.5999 5.6C13.5999 5.93137 13.3313 6.2 12.9999 6.2L11.9243 6.2C11.6579 7.23513 10.7182 8 9.5999 8C8.4816 8 7.54194 7.23513 7.27551 6.2L2.9999 6.2C2.66853 6.2 2.3999 5.93137 2.3999 5.6C2.3999 5.26863 2.66853 5 2.9999 5L7.27551 5ZM8.3999 5.6C8.3999 4.93725 8.93716 4.4 9.5999 4.4C10.2626 4.4 10.7999 4.93726 10.7999 5.6C10.7999 6.26274 10.2626 6.8 9.5999 6.8C8.93716 6.8 8.3999 6.26274 8.3999 5.6Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.3999 12.8C7.51821 12.8 8.45787 12.0351 8.72429 11H12.9999C13.3313 11 13.5999 10.7314 13.5999 10.4C13.5999 10.0686 13.3313 9.8 12.9999 9.8H8.72429C8.45787 8.76486 7.51821 8 6.3999 8C5.2816 8 4.34194 8.76486 4.07551 9.8H2.9999C2.66853 9.8 2.3999 10.0686 2.3999 10.4C2.3999 10.7314 2.66853 11 2.9999 11H4.07551C4.34194 12.0351 5.2816 12.8 6.3999 12.8ZM7.5999 10.4C7.5999 11.0627 7.06264 11.6 6.3999 11.6C5.73716 11.6 5.1999 11.0627 5.1999 10.4C5.1999 9.73726 5.73716 9.2 6.3999 9.2C7.06264 9.2 7.5999 9.73726 7.5999 10.4Z"
    fill="currentColor" />
</template>
