<script lang="ts" setup>
import { computed } from 'vue';
import { cn } from '../helpers/common';

type Tone = 'warning' | 'primary' | 'error' | 'info';

const props = defineProps<{
  classContainer?: string;
  classBox?: string;
  tone?: Tone;
}>();

const bgContainerClass = computed(() => {
  switch (props.tone) {
    case 'warning':
      return 'bg-yellow-100';
    case 'info':
      return 'bg-primary-100';
    case 'error':
      return 'bg-underlay-red-150';
    default:
      return 'bg-yellow-100';
  }
});
</script>

<template>
  <div :class="classContainer">
    <div :class="cn('flex gap-6 rounded-[8px] py-8 px-4', bgContainerClass, classBox)">
      <div>
        <slot name="prefix"></slot>
      </div>
      <div>
        <slot name="suffix"></slot>
      </div>
      <slot name="action"></slot>
    </div>
  </div>
</template>
