<template>
  <path
    d="M9 13C9 11.3431 10.3431 10 12 10H54C55.6569 10 57 11.3431 57 13V35C57 36.6569 55.6569 38 54 38H12C10.3431 38 9 36.6569 9 35V13Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M54 11H12C10.8954 11 10 11.8954 10 13V35C10 36.1046 10.8954 37 12 37H54C55.1046 37 56 36.1046 56 35V13C56 11.8954 55.1046 11 54 11ZM12 10C10.3431 10 9 11.3431 9 13V35C9 36.6569 10.3431 38 12 38H54C55.6569 38 57 36.6569 57 35V13C57 11.3431 55.6569 10 54 10H12Z"
    fill="currentColor"
    class="gp-icon-highlight" />
  <path
    d="M29 20C29 21.1046 28.1046 22 27 22C25.8954 22 25 21.1046 25 20C25 18.8954 25.8954 18 27 18C28.1046 18 29 18.8954 29 20Z"
    fill="currentColor"
    class="gp-icon-highlight" />
  <path
    d="M25.1969 28.6453L27.4097 26.0595C27.4846 25.9718 27.5772 25.9008 27.6814 25.8512C27.7856 25.8017 27.8991 25.7746 28.0145 25.7718C28.1299 25.769 28.2446 25.7905 28.3511 25.835C28.4576 25.8795 28.5535 25.9458 28.6326 26.0298L29.6673 27.1271L33.712 22.2946C33.7912 22.1999 33.8908 22.1243 34.0033 22.0733C34.1157 22.0224 34.2383 21.9974 34.3618 22.0002C34.4852 22.0031 34.6065 22.0337 34.7165 22.0898C34.8265 22.1459 34.9224 22.2261 34.9972 22.3243L39.8329 28.6772C39.9254 28.7989 39.982 28.9441 39.9964 29.0962C40.0107 29.2484 39.9823 29.4016 39.9144 29.5385C39.8464 29.6754 39.7415 29.7907 39.6115 29.8713C39.4815 29.952 39.3316 29.9948 39.1786 29.995L25.822 30C25.6649 29.9999 25.5111 29.9549 25.3789 29.8703C25.2466 29.7857 25.1413 29.6649 25.0755 29.5224C25.0098 29.3799 24.9862 29.2216 25.0077 29.0661C25.0292 28.9107 25.0949 28.7646 25.1969 28.6453Z"
    fill="currentColor"
    class="gp-icon-highlight" />
</template>
