<template>
  <svg width="120" height="80" viewBox="0 0 120 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 8C0.5 3.85787 3.85787 0.5 8 0.5H112C116.142 0.5 119.5 3.85786 119.5 8V72C119.5 76.1421 116.142 79.5 112 79.5H8C3.85786 79.5 0.5 76.1421 0.5 72V8Z"
      fill="#212121" />
    <path
      d="M0.5 8C0.5 3.85787 3.85787 0.5 8 0.5H112C116.142 0.5 119.5 3.85786 119.5 8V72C119.5 76.1421 116.142 79.5 112 79.5H8C3.85786 79.5 0.5 76.1421 0.5 72V8Z"
      stroke="#424242" />
    <rect x="14" y="14" width="43" height="23" rx="2.37" fill="#424242" />
    <rect x="63" y="14" width="43" height="23" rx="2.37" fill="#424242" />
    <rect x="14" y="43" width="43" height="23" rx="2.37" fill="#424242" />
    <rect x="63" y="43" width="43" height="23" rx="2.37" fill="#424242" />
  </svg>
</template>
