<template>
  <path
    d="M11.6667 13C11.6667 11.3431 13.0099 10 14.6667 10H62.6667C64.3236 10 65.6667 11.3431 65.6667 13V35C65.6667 36.6569 64.3236 38 62.6667 38H14.6667C13.0099 38 11.6667 36.6569 11.6667 35V13Z"
    fill="currentColor" />
  <path
    d="M24.6667 27.5172C24.6667 28.391 23.5097 28.8287 22.833 28.2107L18.9813 24.6936C18.5618 24.3105 18.5618 23.6895 18.9813 23.3064L22.833 19.7893C23.5097 19.1713 24.6667 19.609 24.6667 20.4828L24.6667 27.5172Z"
    fill="currentColor"
    class="gp-icon-highlight" />
  <path
    d="M52.6667 20.4828C52.6667 19.609 53.8237 19.1713 54.5004 19.7893L58.3521 23.3064C58.7716 23.6895 58.7716 24.3105 58.3521 24.6936L54.5004 28.2107C53.8237 28.8287 52.6667 28.391 52.6667 27.5172V20.4828Z"
    fill="currentColor"
    class="gp-icon-highlight" />
</template>
