<template>
  <path
    d="M0 8C0 3.58172 3.58172 0 8 0H58C62.4183 0 66 3.58172 66 8V40C66 44.4183 62.4183 48 58 48H8C3.58172 48 0 44.4183 0 40V8Z"
    fill="#212121" />
  <circle cx="15.5" cy="24" r="5.25" stroke="currentColor" stroke-width="1.5" />
  <circle cx="15.5" cy="24" r="3" fill="currentColor" />
  <rect x="26.5" y="12.5" width="30" height="3.5" rx="1.75" fill="currentColor" />
  <rect x="26.5" y="19" width="30" height="3.5" rx="1.75" fill="currentColor" />
  <rect x="26.5" y="25.5" width="30" height="3.5" rx="1.75" fill="currentColor" />
  <rect x="26.5" y="32" width="18" height="3.5" rx="1.75" fill="currentColor" />
</template>
