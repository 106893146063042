<template>
  <path
    d="M0.5 8C0.5 3.85787 3.85787 0.5 8 0.5H112C116.142 0.5 119.5 3.85786 119.5 8V72C119.5 76.1421 116.142 79.5 112 79.5H8C3.85786 79.5 0.5 76.1421 0.5 72V8Z"
    fill="#212121" />
  <path
    d="M0.5 8C0.5 3.85787 3.85787 0.5 8 0.5H112C116.142 0.5 119.5 3.85786 119.5 8V72C119.5 76.1421 116.142 79.5 112 79.5H8C3.85786 79.5 0.5 76.1421 0.5 72V8Z"
    stroke="#212121" />
  <path
    d="M24 21C24 19.3431 25.3431 18 27 18H93C94.6569 18 96 19.3431 96 21V35C96 36.6569 94.6569 38 93 38H27C25.3431 38 24 36.6569 24 35V21Z"
    fill="#424242" />
  <rect x="32" y="26" width="12" height="4" rx="2" fill="#F9F9F9" />
  <rect x="48" y="26" width="40" height="4" rx="2" fill="#AAAAAA" />
  <path
    d="M24 45C24 43.3431 25.3431 42 27 42H93C94.6569 42 96 43.3431 96 45V59C96 60.6569 94.6569 62 93 62H27C25.3431 62 24 60.6569 24 59V45Z"
    fill="#424242" />
  <rect x="32" y="50" width="12" height="4" rx="2" fill="#F9F9F9" />
  <rect x="48" y="50" width="40" height="4" rx="2" fill="#AAAAAA" />
</template>
