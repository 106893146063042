<template>
  <path
    d="M4.56189 3.40217C4.75155 3.0554 5.24959 3.0554 5.43924 3.40217L5.59612 3.689C5.7613 3.99102 6.00962 4.23935 6.31164 4.40453L6.59848 4.5614C6.94525 4.75106 6.94525 5.2491 6.59848 5.43876L6.31164 5.59563C6.00962 5.76081 5.7613 6.00913 5.59612 6.31116L5.43924 6.59799C5.24959 6.94476 4.75155 6.94476 4.56189 6.59799L4.40502 6.31116C4.23983 6.00913 3.99151 5.76081 3.68949 5.59563L3.40266 5.43876C3.05589 5.2491 3.05589 4.75106 3.40266 4.5614L3.68949 4.40453C3.99151 4.23935 4.23983 3.99102 4.40502 3.689L4.56189 3.40217Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.3591 6.31142C10.215 5.37837 8.87742 5.36006 8.70782 6.28881L8.68393 6.41963C8.4558 7.66893 7.46683 8.63988 6.21355 8.845C5.29407 8.99549 5.27505 10.311 6.18979 10.488L6.27373 10.5043C7.50005 10.7416 8.45898 11.7005 8.69628 12.9268L8.72185 13.059C8.9089 14.0256 10.2922 14.0256 10.4793 13.059L10.4992 12.956C10.7394 11.7145 11.7167 10.7475 12.9606 10.5203C13.908 10.3473 13.856 8.97741 12.9037 8.83434C11.5992 8.63838 10.5605 7.61507 10.3591 6.31142ZM9.60396 11.8212C10.0105 10.8962 10.7331 10.1459 11.637 9.70395C10.7185 9.29601 9.96981 8.57174 9.53525 7.66518C9.1232 8.5439 8.4181 9.2537 7.54471 9.67212C8.46468 10.1163 9.19801 10.8796 9.60396 11.8212Z"
    fill="currentColor" />
</template>
