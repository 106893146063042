<template>
  <path
    d="M4.75 10C4.75 7.10051 7.10051 4.75 10 4.75C12.8995 4.75 15.25 7.10051 15.25 10C15.25 10.4142 15.5858 10.75 16 10.75C16.4142 10.75 16.75 10.4142 16.75 10C16.75 6.27208 13.7279 3.25 10 3.25C6.27208 3.25 3.25 6.27208 3.25 10C3.25 13.7279 6.27208 16.75 10 16.75C10.4142 16.75 10.75 16.4142 10.75 16C10.75 15.5858 10.4142 15.25 10 15.25C7.10051 15.25 4.75 12.8995 4.75 10Z"
    fill="currentColor" />
  <path
    d="M11.5373 9.80533C11.595 10.2155 11.9742 10.5013 12.3844 10.4437C12.7946 10.386 13.0804 10.0068 13.0227 9.59657C12.9457 9.04869 12.7187 8.53277 12.3668 8.10586C12.0149 7.67894 11.5517 7.35766 11.0286 7.17754C10.5055 6.99741 9.94275 6.96545 9.4026 7.0852C8.86245 7.20495 8.36593 7.47174 7.96795 7.85607C7.56996 8.2404 7.286 8.72731 7.14748 9.26295C7.00895 9.79859 7.02125 10.3621 7.18301 10.8912C7.34477 11.4203 7.64969 11.8944 8.06406 12.261C8.47843 12.6276 8.98611 12.8724 9.53098 12.9685C9.9389 13.0404 10.3279 12.7681 10.3998 12.3601C10.4717 11.9522 10.1994 11.5632 9.79145 11.4913C9.51902 11.4433 9.26518 11.3208 9.05799 11.1375C8.8508 10.9542 8.69834 10.7172 8.61746 10.4526C8.53658 10.1881 8.53044 9.90634 8.5997 9.63852C8.66896 9.3707 8.81094 9.12725 9.00993 8.93508C9.20893 8.74292 9.45718 8.60952 9.72726 8.54965C9.99734 8.48977 10.2787 8.50575 10.5403 8.59581C10.8018 8.68588 11.0334 8.84652 11.2094 9.05997C11.3853 9.27343 11.4988 9.53139 11.5373 9.80533Z"
    fill="currentColor" />
  <path
    d="M11.6109 10.9731C11.4294 10.909 11.2271 10.9549 11.091 11.0911C10.9548 11.2272 10.9089 11.4295 10.973 11.611L13.0944 17.6214C13.1557 17.7952 13.3076 17.9211 13.4897 17.9492C13.6719 17.9772 13.8547 17.9028 13.9654 17.7556L15.1371 16.1979L16.1746 17.2354C16.3699 17.4306 16.6865 17.4306 16.8817 17.2354L17.2353 16.8818C17.4305 16.6865 17.4305 16.37 17.2353 16.1747L16.1978 15.1372L17.7555 13.9655C17.9028 13.8547 17.9772 13.672 17.9491 13.4898C17.9211 13.3077 17.7951 13.1558 17.6213 13.0944L11.6109 10.9731Z"
    fill="currentColor" />
</template>
