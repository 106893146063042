<template>
  <path
    d="M14.304 6.25172C14.5811 5.94384 14.5562 5.46962 14.2483 5.19253C13.9404 4.91544 13.4662 4.94039 13.1891 5.24828L5.19253 14.1333C4.91544 14.4412 4.94039 14.9154 5.24828 15.1925C5.55616 15.4696 6.03038 15.4447 6.30747 15.1368L14.304 6.25172Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5 1C2.79086 1 1 2.79086 1 5V14.5C1 16.7091 2.79086 18.5 5 18.5H14.5C16.7091 18.5 18.5 16.7091 18.5 14.5V5C18.5 2.79086 16.7091 1 14.5 1H5ZM2.5 5C2.5 3.61929 3.61929 2.5 5 2.5H14.5C15.8807 2.5 17 3.61929 17 5V14.5C17 15.8807 15.8807 17 14.5 17H5C3.61929 17 2.5 15.8807 2.5 14.5V5Z"
    fill="currentColor" />
</template>
