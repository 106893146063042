<template>
  <rect width="77" height="52" rx="3" fill="#212121" />
  <rect x="8" y="12" width="10.6" height="28" rx="3" fill="currentColor" />
  <path
    d="M20.5996 15C20.5996 13.3431 21.9428 12 23.5996 12H28.1996C29.8565 12 31.1996 13.3431 31.1996 15V37C31.1996 38.6569 29.8565 40 28.1996 40H23.5996C21.9428 40 20.5996 38.6569 20.5996 37V15Z"
    fill="currentColor" />
  <path
    d="M33.1992 15C33.1992 13.3431 34.5424 12 36.1992 12H40.7992C42.4561 12 43.7992 13.3431 43.7992 15V37C43.7992 38.6569 42.4561 40 40.7992 40H36.1992C34.5424 40 33.1992 38.6569 33.1992 37V15Z"
    fill="currentColor" />
  <path
    d="M45.8008 15C45.8008 13.3431 47.1439 12 48.8008 12H53.4008C55.0576 12 56.4008 13.3431 56.4008 15V37C56.4008 38.6569 55.0576 40 53.4008 40H48.8008C47.1439 40 45.8008 38.6569 45.8008 37V15Z"
    fill="currentColor" />
  <path
    d="M58.4004 15C58.4004 13.3431 59.7435 12 61.4004 12H66.0004C67.6572 12 69.0004 13.3431 69.0004 15V37C69.0004 38.6569 67.6572 40 66.0004 40H61.4004C59.7435 40 58.4004 38.6569 58.4004 37V15Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.9654 22.5932C15.0793 22.7175 15.0793 22.9189 14.9654 23.0432L12.2549 26L14.9654 28.9568C15.0793 29.0811 15.0793 29.2825 14.9654 29.4068C14.8515 29.5311 14.6668 29.5311 14.5529 29.4068L11.6362 26.225C11.5223 26.1007 11.5223 25.8993 11.6362 25.775L14.5529 22.5932C14.6668 22.4689 14.8515 22.4689 14.9654 22.5932Z"
    fill="#9E9E9E" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M62.0346 22.5932C62.1485 22.4689 62.3332 22.4689 62.4471 22.5932L65.3638 25.775C65.4777 25.8993 65.4777 26.1007 65.3638 26.225L62.4471 29.4068C62.3332 29.5311 62.1485 29.5311 62.0346 29.4068C61.9207 29.2825 61.9207 29.0811 62.0346 28.9568L64.7451 26L62.0346 23.0432C61.9207 22.9189 61.9207 22.7175 62.0346 22.5932Z"
    fill="#9E9E9E" />
</template>
