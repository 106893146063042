<template>
  <path
    d="M15.5543 5.50815C15.5444 5.42166 15.4652 5.38322 15.4057 5.37361C15.3463 5.36401 14.1582 5.3544 14.1582 5.3544C14.1582 5.3544 13.1582 4.41267 13.0591 4.31658C12.9601 4.22049 12.772 4.24931 12.6928 4.26853C12.6928 4.26853 12.5047 4.32619 12.1878 4.42228C12.1383 4.25892 12.0591 4.05713 11.9502 3.85533C11.5937 3.19228 11.0789 2.84634 10.4452 2.84634C10.4056 2.84634 10.3561 2.84634 10.3165 2.85595C10.2967 2.83673 10.2769 2.81751 10.2571 2.78868C9.97984 2.5004 9.63329 2.36587 9.21744 2.37548C8.40553 2.3947 7.60353 2.96165 6.94014 3.98025C6.47478 4.69135 6.12824 5.58502 6.02923 6.2769C5.0985 6.55557 4.44502 6.74776 4.43512 6.75737C3.96976 6.90151 3.94995 6.91112 3.89055 7.34354C3.84104 7.66065 2.61328 16.8953 2.61328 16.8953L12.9205 18.625L17.386 17.5487C17.386 17.5487 15.5642 5.58502 15.5543 5.50815ZM11.673 4.57603C11.4353 4.6433 11.168 4.72978 10.871 4.81627C10.8611 4.42228 10.8115 3.86494 10.6234 3.38447C11.2472 3.49978 11.5442 4.17244 11.673 4.57603ZM10.3363 4.97963C9.79171 5.14299 9.20754 5.31596 8.61346 5.49854C8.78178 4.88353 9.09862 4.26853 9.48478 3.86494C9.63329 3.71119 9.83132 3.54783 10.069 3.45173C10.2967 3.90337 10.3363 4.54721 10.3363 4.97963ZM9.22734 2.904C9.41547 2.904 9.57389 2.94244 9.7125 3.02892C9.49468 3.13462 9.27685 3.29798 9.07882 3.50939C8.56396 4.04752 8.1679 4.87393 8.00948 5.68112C7.51442 5.82526 7.03916 5.9694 6.5936 6.10393C6.89064 4.83549 7.97978 2.94244 9.22734 2.904Z"
    fill="#95BF47" />
  <path
    d="M15.4056 5.37385C15.3462 5.36424 14.1581 5.35463 14.1581 5.35463C14.1581 5.35463 13.158 4.41291 13.059 4.31681C13.0194 4.27837 12.9699 4.25916 12.9204 4.25916V18.6252L17.3859 17.549C17.3859 17.549 15.5641 5.59486 15.5542 5.50838C15.5343 5.42189 15.465 5.38346 15.4056 5.37385Z"
    fill="#5E8E3E" />
  <path
    d="M10.4457 7.60252L9.93084 9.48596C9.93084 9.48596 9.35656 9.22651 8.66347 9.27456C7.65353 9.33221 7.64364 9.94722 7.65354 10.1106C7.71295 10.9562 9.99025 11.1388 10.119 13.1087C10.218 14.6654 9.26745 15.7225 7.90108 15.809C6.25746 15.9051 5.35645 14.9729 5.35645 14.9729L5.70299 13.5411C5.70299 13.5411 6.61391 14.2042 7.3367 14.1657C7.81196 14.1369 7.98029 13.7622 7.96048 13.4931C7.89118 12.3976 6.02972 12.4553 5.91091 10.6583C5.81189 9.14003 6.84163 7.60252 9.10902 7.45838C10.0001 7.3815 10.4457 7.60252 10.4457 7.60252Z"
    fill="white" />
</template>
