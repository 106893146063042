<template>
  <path
    d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H110C114.142 0.5 117.5 3.85786 117.5 8V44C117.5 48.1421 114.142 51.5 110 51.5H8C3.85786 51.5 0.5 48.1421 0.5 44V8Z"
    fill="#212121" />
  <rect x="8" y="8" width="70" height="36" rx="3" fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M107.665 25.775C107.778 25.8993 107.778 26.1007 107.665 26.225L104.748 29.4068C104.634 29.5311 104.449 29.5311 104.335 29.4068C104.222 29.2825 104.222 29.0811 104.335 28.9568L107.046 26L104.335 23.0432C104.222 22.9189 104.222 22.7175 104.335 22.5932C104.449 22.4689 104.634 22.4689 104.748 22.5932L107.665 25.775Z"
    fill="#9E9E9E" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.3354 26.225C17.2215 26.1007 17.2215 25.8993 17.3354 25.775L20.2521 22.5932C20.366 22.4689 20.5507 22.4689 20.6646 22.5932C20.7785 22.7175 20.7785 22.9189 20.6646 23.0432L17.9541 26L20.6646 28.9568C20.7785 29.0811 20.7785 29.2825 20.6646 29.4068C20.5507 29.5311 20.366 29.5311 20.2521 29.4068L17.3354 26.225Z"
    fill="#9E9E9E" />
  <path
    d="M82 11C82 9.34315 83.3431 8 85 8H117V44H85C83.3431 44 82 42.6569 82 41V11Z"
    fill="url(#paint0_linear_1395_43593)" />
  <defs>
    <linearGradient id="paint0_linear_1395_43593" x1="82" y1="26" x2="117" y2="26" gradientUnits="userSpaceOnUse">
      <stop stop-color="currentColor" />
      <stop offset="1" stop-color="#212121" />
    </linearGradient>
  </defs>
</template>
