<template>
  <path
    d="M0.333984 8C0.333984 3.58172 3.91571 0 8.33398 0H69.6673C74.0856 0 77.6673 3.58172 77.6673 8V44C77.6673 48.4183 74.0856 52 69.6673 52H8.33399C3.91571 52 0.333984 48.4183 0.333984 44V8Z"
    fill="#212121" />
  <rect x="8.33398" y="12" width="29.6667" height="28" rx="3" fill="currentColor" />
  <path
    d="M40 15C40 13.3431 41.3431 12 43 12H66.6667C68.3235 12 69.6667 13.3431 69.6667 15V37C69.6667 38.6569 68.3235 40 66.6667 40H43C41.3431 40 40 38.6569 40 37V15Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16.6646 22.5932C16.7785 22.7175 16.7785 22.9189 16.6646 23.0432L13.9541 26L16.6646 28.9568C16.7785 29.0811 16.7785 29.2825 16.6646 29.4068C16.5507 29.5311 16.366 29.5311 16.2521 29.4068L13.3354 26.225C13.2215 26.1007 13.2215 25.8993 13.3354 25.775L16.2521 22.5932C16.366 22.4689 16.5507 22.4689 16.6646 22.5932Z"
    fill="#9E9E9E" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M60.3354 22.5932C60.4493 22.4689 60.634 22.4689 60.7479 22.5932L63.6646 25.775C63.7785 25.8993 63.7785 26.1007 63.6646 26.225L60.7479 29.4068C60.634 29.5311 60.4493 29.5311 60.3354 29.4068C60.2215 29.2825 60.2215 29.0811 60.3354 28.9568L63.0459 26L60.3354 23.0432C60.2215 22.9189 60.2215 22.7175 60.3354 22.5932Z"
    fill="#9E9E9E" />
</template>
