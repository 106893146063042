<template>
  <g clip-path="url(#clip0_3530_1762)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.41754 1.12473C8.53074 1.48387 8.33137 1.86678 7.97223 1.97998C6.57769 2.41956 5.32187 3.21516 4.3287 4.28828C3.33553 5.3614 2.63934 6.67494 2.30882 8.09927C1.97831 9.52361 2.0249 11.0095 2.444 12.4103C2.8631 13.8112 3.64022 15.0785 4.69867 16.0873C5.75712 17.0961 7.06032 17.8114 8.47966 18.1628C9.899 18.5141 11.3854 18.4893 12.7922 18.0908C14.1991 17.6923 15.4777 16.9338 16.5018 15.8902C17.526 14.8467 18.2604 13.5541 18.6325 12.1401C18.7283 11.7759 19.1012 11.5584 19.4654 11.6542C19.8295 11.75 20.0471 12.1229 19.9512 12.4871C19.5188 14.1304 18.6653 15.6326 17.4751 16.8454C16.2848 18.0582 14.7989 18.9396 13.1639 19.4028C11.529 19.866 9.80149 19.8948 8.15199 19.4865C6.50249 19.0782 4.98796 18.2468 3.75787 17.0744C2.52778 15.902 1.62464 14.4292 1.13758 12.8012C0.650517 11.1732 0.59637 9.44634 0.98048 7.79103C1.36459 6.13572 2.17368 4.60918 3.32791 3.36204C4.48213 2.11491 5.9416 1.19029 7.56228 0.679428C7.92142 0.566223 8.30433 0.765592 8.41754 1.12473Z"
      fill="currentColor" />
  </g>
  <defs>
    <clipPath id="clip0_3530_1762">
      <rect width="20" height="20" fill="white" transform="translate(0.5)" />
    </clipPath>
  </defs>
</template>
