<template>
  <g clip-path="url(#clip0_9799_481869)">
    <path
      d="M0 3C0 1.34315 1.34315 0 3 0H93C94.6569 0 96 1.34315 96 3V69C96 70.6569 94.6569 72 93 72H3C1.34315 72 0 70.6569 0 69V3Z"
      fill="#333333" />
    <g clip-path="url(#clip1_9799_481869)">
      <rect x="12" y="4" width="72" height="24" rx="2" fill="#474747" />
      <rect x="12" y="31" width="72" height="24" rx="2" fill="#474747" />
      <rect x="12" y="58" width="72" height="24" rx="2" fill="#474747" />
      <path
        d="M90 5.5C90 4.67157 90.6716 4 91.5 4C92.3284 4 93 4.67157 93 5.5V32.5C93 33.3284 92.3284 34 91.5 34C90.6716 34 90 33.3284 90 32.5V5.5Z"
        fill="#757575" />
    </g>
  </g>
  <defs>
    <clipPath id="clip0_9799_481869">
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H93C94.6569 0 96 1.34315 96 3V69C96 70.6569 94.6569 72 93 72H3C1.34315 72 0 70.6569 0 69V3Z"
        fill="white" />
    </clipPath>
    <clipPath id="clip1_9799_481869">
      <rect width="96" height="72" fill="white" />
    </clipPath>
  </defs>
</template>
