<template>
  <path
    d="M5.25 3.5C4.2835 3.5 3.5 4.2835 3.5 5.25V6.75C3.5 7.16421 3.83579 7.5 4.25 7.5C4.66421 7.5 5 7.16421 5 6.75V5.25C5 5.11193 5.11193 5 5.25 5H6.75C7.16421 5 7.5 4.66421 7.5 4.25C7.5 3.83579 7.16421 3.5 6.75 3.5H5.25Z"
    fill="#AAAAAA" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.25 3.5C9.73122 3.5 8.5 4.73122 8.5 6.25V8.75C8.5 10.2688 9.73122 11.5 11.25 11.5H13.75C15.2688 11.5 16.5 10.2688 16.5 8.75V6.25C16.5 4.73122 15.2688 3.5 13.75 3.5H11.25ZM10 6.25C10 5.55964 10.5596 5 11.25 5H13.75C14.4404 5 15 5.55964 15 6.25V8.75C15 9.44036 14.4404 10 13.75 10H11.25C10.5596 10 10 9.44036 10 8.75V6.25Z"
    fill="#AAAAAA" />
  <path
    d="M4.25 12.5C4.66421 12.5 5 12.8358 5 13.25V14.75C5 14.8881 5.11193 15 5.25 15H6.75C7.16421 15 7.5 15.3358 7.5 15.75C7.5 16.1642 7.16421 16.5 6.75 16.5H5.25C4.2835 16.5 3.5 15.7165 3.5 14.75V13.25C3.5 12.8358 3.83579 12.5 4.25 12.5Z"
    fill="#AAAAAA" />
  <path
    d="M16.5 13.25C16.5 12.8358 16.1642 12.5 15.75 12.5C15.3358 12.5 15 12.8358 15 13.25V14.75C15 14.8881 14.8881 15 14.75 15H13.25C12.8358 15 12.5 15.3358 12.5 15.75C12.5 16.1642 12.8358 16.5 13.25 16.5H14.75C15.7165 16.5 16.5 15.7165 16.5 14.75V13.25Z"
    fill="#AAAAAA" />
  <path
    d="M4.25 8.25C4.66421 8.25 5 8.58579 5 9V11C5 11.4142 4.66421 11.75 4.25 11.75C3.83579 11.75 3.5 11.4142 3.5 11V9C3.5 8.58579 3.83579 8.25 4.25 8.25Z"
    fill="#AAAAAA" />
  <path
    d="M9 15C8.58579 15 8.25 15.3358 8.25 15.75C8.25 16.1642 8.58579 16.5 9 16.5H11C11.4142 16.5 11.75 16.1642 11.75 15.75C11.75 15.3358 11.4142 15 11 15H9Z"
    fill="#AAAAAA" />
</template>
