<template>
  <g clip-path="url(#clip0_16509_22636)">
    <path
      d="M1.5 14C0.671573 14 -2.93554e-08 13.3284 -6.55671e-08 12.5L-4.5897e-07 3.5C-4.95181e-07 2.67157 0.671572 2 1.5 2L1.5 14Z"
      fill="#F9F9F9" />
    <path d="M14.5 14C15.3284 14 16 13.3284 16 12.5L16 3.5C16 2.67157 15.3284 2 14.5 2L14.5 14Z" fill="#5E5E5E" />
    <path d="M2 1.5C2 0.671573 2.67157 0 3.5 0H12.5C13.3284 0 14 0.671573 14 1.5H2Z" fill="#5E5E5E" />
    <path d="M2 14.5C2 15.3284 2.67157 16 3.5 16L12.5 16C13.3284 16 14 15.3284 14 14.5L2 14.5Z" fill="#5E5E5E" />
  </g>
  <defs>
    <clipPath id="clip0_16509_22636">
      <rect width="16" height="16" fill="white" transform="translate(0 16) rotate(-90)" />
    </clipPath>
  </defs>
</template>
