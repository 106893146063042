<template>
  <path
    d="M0 8C0 3.58172 3.58172 0 8 0H112C116.418 0 120 3.58172 120 8V72C120 76.4183 116.418 80 112 80H8C3.58172 80 0 76.4183 0 72V8Z"
    fill="#212121" />
  <path
    d="M24 25C24 23.3431 25.3431 22 27 22H55C56.6569 22 58 23.3431 58 25V55C58 56.6569 56.6569 58 55 58H27C25.3431 58 24 56.6569 24 55V25Z"
    fill="#333333" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M45.5 31.9121H36.5V33.1394H45.5V31.9121ZM37.3182 33.9576H44.6818V40.503H37.3182V33.9576ZM39.7727 37.2303V34.7757H42.2273V37.2303L41 36.4121L39.7727 37.2303Z"
    fill="#757575" />
  <rect x="28" y="46" width="26" height="4" rx="2" fill="#5E5E5E" />
  <path
    d="M62 25C62 23.3431 63.3431 22 65 22H93C94.6569 22 96 23.3431 96 25V55C96 56.6569 94.6569 58 93 58H65C63.3431 58 62 56.6569 62 55V25Z"
    fill="#333333" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M83.5 31.9121H74.5V33.1394H83.5V31.9121ZM75.3182 33.9576H82.6818V40.503H75.3182V33.9576ZM77.7727 37.2303V34.7757H80.2273V37.2303L79 36.4121L77.7727 37.2303Z"
    fill="#757575" />
  <rect x="66" y="46" width="26" height="4" rx="2" fill="#5E5E5E" />
</template>
