<script lang="ts" setup>
import { onDeactivated, onMounted, provide, ref, useSlots, watch } from 'vue';
import { scrollToWithCallback } from '../helpers/scroll';

type Props = {
  width?: number;
  tabHeight?: number;
  classLabel?: string;
  classContent?: string;
  scrollTop?: number;
  scrollToElement?: string;
  features: {
    trackingCode?: boolean;
  };
};

type ItemScroll = {
  id: string;
};

defineEmits<{
  (e: 'change', position: number, id: string, items: ItemScroll[]): void;
  (e: 'check', value: any): void;
}>();

const props = withDefaults(defineProps<Props>(), {
  width: 180,
  classLabel: '',
  classContent: '',
});

const slots = useSlots();
const tabActive = ref(0);
const itemIds = ref<ItemScroll[]>([]);
const prefixId = Math.random().toString(36).substring(2, 9);
const onScrollingTo = ref(false);
const observer = ref<IntersectionObserver | null>();

watch(
  () => props.tabHeight,
  () => {
    getPropsId();
  },
);

provide('prefixId', prefixId);

const changeTabActive = (index: number) => {
  tabActive.value = index;

  const target = document.getElementById(itemIds.value?.[index]?.id);
  if (!target) return;
  onScrollingTo.value = true;
  const top = target?.offsetTop - 20 ?? 0;
  scrollToWithCallback('tabScrollContainer', { top, behavior: 'smooth' }, () => {
    onScrollingTo.value = false;
  });
};

const initIntersectionObserver = () => {
  const rootContainer = document.getElementById('tabScrollContainer');
  if (!rootContainer) return;

  observer.value = new IntersectionObserver(handleThresholdReach, {
    root: rootContainer,
    rootMargin: '0px',
    threshold: [0, 0.5, 0.7, 1],
  });

  for (const target of itemIds.value) {
    if (!props.features?.trackingCode && target.id.includes('tracking-code')) continue;

    const observeDom = document.getElementById(target.id);
    if (!observeDom) continue;
    observer.value.observe(observeDom);
  }
};

const handleThresholdReach = (entries: IntersectionObserverEntry[]) => {
  if (onScrollingTo.value) {
    return;
  }
  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];
    const entryTargetHeight = entry.target.clientHeight;
    const rootContainerHeight = document.getElementById('tabScrollContainer')?.clientHeight ?? 0;
    const threshold = rootContainerHeight > entryTargetHeight ? 0.7 : 0.5;

    if (entry.isIntersecting && entry.intersectionRatio > threshold) {
      const targetId = entry.target.id;
      tabActive.value = itemIds.value.findIndex((i) => i.id === targetId);
      break;
    }
  }
};

const unsubscribeIntersectionObserver = () => {
  if (observer.value) {
    for (const target of itemIds.value) {
      if (!props.features?.trackingCode && target.id.includes('tracking-code')) continue;

      const observeDom = document.getElementById(target.id);
      if (!observeDom) continue;
      observer.value.unobserve(observeDom);
    }
    observer.value.disconnect();
    observer.value = null;
  }
};

onMounted(() => {
  getPropsId();
  handleScrollToElement();
  initIntersectionObserver();
});

onDeactivated(() => {
  unsubscribeIntersectionObserver();
});

const getPropsId = () => {
  if (slots?.default) {
    itemIds.value.length = 0;

    slots?.default().forEach((item: any) => {
      const id = item.props.id;
      itemIds.value.push({ id: `${prefixId}-${id}` });
    });
  }
};

const handleScrollToElement = () => {
  if (!props.scrollToElement) return;
  changeTabActive(itemIds.value.findIndex((i) => i.id.includes(props.scrollToElement || '')));
};
</script>

<template>
  <div id="tabScroll" class="flex">
    <div v-if="slots?.default" class="text-14 text-text-light-300 fixed h-full font-medium" :class="classLabel">
      <div
        v-for="(label, index) in slots?.default()"
        :key="label.props?.id"
        class="block cursor-pointer py-10 pl-16"
        :class="{ 'text-primary-300': tabActive === index }"
        @click="changeTabActive(index)">
        {{ (label?.children as any)?.label?.()?.[0]?.children }}
      </div>
    </div>

    <div :class="classContent" :style="{ 'padding-left': width + 'px' }" class="w-full pr-16">
      <slot></slot>
    </div>
  </div>
</template>
