<script lang="ts" setup>
import { ref } from 'vue';

const props = defineProps<{
  lazyloadImage?: string;
  shopDomain?: string;
}>();

const currentLazyloadImage = ref('false');

if (props.lazyloadImage) {
  currentLazyloadImage.value = props.lazyloadImage ?? 'false';
}

const emit = defineEmits(['changeLazyloadImage']);

const onChangeLazyloadImage = (value: boolean) => {
  emit('changeLazyloadImage', String(value));
};
</script>
<template>
  <div class="text-dark-400 w-full rounded-xl bg-white p-16">
    <div class="text-14 mb-4 font-medium leading-[24px]">Lazyload</div>
    <div class="mb-16">
      <div class="flex items-center justify-between">
        <GCheckbox
          input-classes="!h-16 !w-16"
          text="Lazyload"
          :value="currentLazyloadImage"
          @on-change="onChangeLazyloadImage" />
      </div>
    </div>
  </div>
</template>
