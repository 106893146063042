<template>
  <path
    d="M12.9412 8.94194C13.1853 8.69786 13.1853 8.30214 12.9412 8.05806C12.6971 7.81398 12.3014 7.81398 12.0573 8.05806L9.49927 10.6161L8.44121 9.55806C8.19713 9.31398 7.8014 9.31398 7.55733 9.55806C7.31325 9.80214 7.31325 10.1979 7.55733 10.4419L9.05733 11.9419C9.3014 12.186 9.69713 12.186 9.94121 11.9419L12.9412 8.94194Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.8849 2.19271C10.3615 1.82476 9.63713 1.82476 9.11377 2.19271C7.63503 3.23234 5.3662 4.40727 3.72663 4.88111C3.06341 5.07278 2.49675 5.65618 2.51611 6.34626C2.67742 12.0962 4.07236 16.7739 9.67873 18.4109C9.88634 18.4715 10.1123 18.4715 10.3199 18.4109C15.9263 16.7739 17.3213 12.0962 17.4826 6.34626C17.5019 5.65618 16.9353 5.07278 16.272 4.88111C14.6325 4.40727 12.3636 3.23234 10.8849 2.19271ZM4.07368 6.08197C5.87715 5.56076 8.27045 4.31361 9.83269 3.21528C9.92473 3.15057 10.0739 3.15057 10.166 3.21528C11.7282 4.31361 14.1215 5.56076 15.925 6.08197C16.0327 6.1131 16.1302 6.17809 16.1891 6.24591C16.2213 6.28306 16.2306 6.30638 16.233 6.31498C16.1538 9.12514 15.7726 11.5082 14.8301 13.352C13.9228 15.1272 12.4588 16.4767 9.99934 17.2022C7.53985 16.4767 6.07592 15.1272 5.16855 13.352C4.2261 11.5082 3.84484 9.12514 3.76572 6.31498C3.76806 6.30638 3.77738 6.28306 3.80961 6.24591C3.86844 6.17809 3.96595 6.1131 4.07368 6.08197ZM16.2336 6.31874C16.2336 6.31874 16.2337 6.31901 16.2336 6.31874V6.31874Z"
    fill="currentColor" />
</template>
