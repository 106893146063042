<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.5 5.75C3.5 4.50736 4.50736 3.5 5.75 3.5H8.5C9.05228 3.5 9.5 3.94771 9.5 4.5V8.5C9.5 9.05228 9.05229 9.5 8.5 9.5H4.5C3.94772 9.5 3.5 9.05229 3.5 8.5V5.75ZM5.75 5C5.33579 5 5 5.33579 5 5.75V8H8V5H5.75Z"
    fill="#AAAAAA" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.5 14.25C3.5 15.4926 4.50736 16.5 5.75 16.5H8.5C9.05228 16.5 9.5 16.0523 9.5 15.5V11.5C9.5 10.9477 9.05229 10.5 8.5 10.5H4.5C3.94772 10.5 3.5 10.9477 3.5 11.5V14.25ZM5.75 15C5.33579 15 5 14.6642 5 14.25V12H8V15H5.75Z"
    fill="#AAAAAA" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.25 16.5C15.4926 16.5 16.5 15.4926 16.5 14.25V11.5C16.5 10.9477 16.0523 10.5 15.5 10.5H11.5C10.9477 10.5 10.5 10.9477 10.5 11.5V15.5C10.5 16.0523 10.9477 16.5 11.5 16.5H14.25ZM15 14.25C15 14.6642 14.6642 15 14.25 15H12V12H15V14.25Z"
    fill="#AAAAAA" />
</template>
