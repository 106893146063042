<template>
  <path
    d="M8.125 7.5C8.47018 7.5 8.75 7.77982 8.75 8.125V14.375C8.75 14.7202 8.47018 15 8.125 15C7.77982 15 7.5 14.7202 7.5 14.375V8.125C7.5 7.77982 7.77982 7.5 8.125 7.5Z"
    fill="currenColor" />
  <path
    d="M11.875 7.5C12.2202 7.5 12.5 7.77982 12.5 8.125V14.375C12.5 14.7202 12.2202 15 11.875 15C11.5298 15 11.25 14.7202 11.25 14.375V8.125C11.25 7.77982 11.5298 7.5 11.875 7.5Z"
    fill="currenColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.75 3.125V3.75H17.5C17.8452 3.75 18.125 4.02982 18.125 4.375C18.125 4.72018 17.8452 5 17.5 5H16.25V15.625C16.25 17.3509 14.8509 18.75 13.125 18.75H6.875C5.14911 18.75 3.75 17.3509 3.75 15.625V5H2.5C2.15482 5 1.875 4.72018 1.875 4.375C1.875 4.02982 2.15482 3.75 2.5 3.75H6.25V3.125C6.25 2.08947 7.08947 1.25 8.125 1.25H11.875C12.9105 1.25 13.75 2.08947 13.75 3.125ZM5 5V15.625C5 16.6605 5.83947 17.5 6.875 17.5H13.125C14.1605 17.5 15 16.6605 15 15.625V5H5ZM7.5 3.75V3.125C7.5 2.77982 7.77982 2.5 8.125 2.5H11.875C12.2202 2.5 12.5 2.77982 12.5 3.125V3.75H7.5Z"
    fill="currenColor" />
</template>
