<template>
  <rect x="1.50293" y="2.20947" width="19.2822" height="2.14247" rx="1.07123" fill="#F9F9F9" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M23.2526 2.33934C23.3394 2.26124 23.4801 2.26124 23.5669 2.33934L25.1875 3.79792L26.8081 2.33934C26.8949 2.26124 27.0356 2.26124 27.1224 2.33934C27.2092 2.41745 27.2092 2.54408 27.1224 2.62218L25.3446 4.22218C25.2579 4.30029 25.1171 4.30029 25.0304 4.22218L23.2526 2.62218C23.1658 2.54408 23.1658 2.41745 23.2526 2.33934Z"
    fill="#F9F9F9" />
  <rect x="1.50293" y="10.668" width="19.2822" height="2.14247" rx="1.07123" fill="#F9F9F9" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M23.2526 10.7978C23.3394 10.7197 23.4801 10.7197 23.5669 10.7978L25.1875 12.2564L26.8081 10.7978C26.8949 10.7197 27.0356 10.7197 27.1224 10.7978C27.2092 10.8759 27.2092 11.0026 27.1224 11.0807L25.3446 12.6807C25.2579 12.7588 25.1171 12.7588 25.0304 12.6807L23.2526 11.0807C23.1658 11.0026 23.1658 10.8759 23.2526 10.7978Z"
    fill="#F9F9F9" />
  <rect x="1.50293" y="18.5264" width="19.2822" height="2.14247" rx="1.07123" fill="#F9F9F9" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M23.2526 18.6562C23.3394 18.5781 23.4801 18.5781 23.5669 18.6562L25.1875 20.1148L26.8081 18.6562C26.8949 18.5781 27.0356 18.5781 27.1224 18.6562C27.2092 18.7343 27.2092 18.861 27.1224 18.9391L25.3446 20.5391C25.2579 20.6172 25.1171 20.6172 25.0304 20.5391L23.2526 18.9391C23.1658 18.861 23.1658 18.7343 23.2526 18.6562Z"
    fill="#F9F9F9" />
</template>
