<script lang="ts" setup>
import { computed } from 'vue';
import type { ButtonType } from '../types/button';
import { cn } from '../helpers/common';

const emit = defineEmits(['click']);
const props = withDefaults(
  defineProps<{
    buttonType?: 'button' | 'submit';
    type?: ButtonType;
    size?: 'large' | 'medium' | 'semi-medium' | 'small';
    loading?: boolean;
    disable?: boolean;
    onlyIcon?: boolean;
    buttonClasses?: string;
    class?: any;
    hideAttributeDisabled?: boolean;
    active?: boolean;
    loadingClass?: string;
    loadingIcon?: string;
    lightMode?: boolean;
  }>(),
  {
    buttonType: 'button',
    type: 'primary',
    size: 'large',
    lightMode: false,
  },
);

//=================== Style ======================
const computedClass = computed(() => {
  const classes = [
    'flex',
    'whitespace-nowrap',
    'items-center',
    'justify-center',
    'font-medium',
    'rounded-xl',
    'transition-colors',
    'duration-200',
    'overflow-hidden',
  ];
  if (props.loading) {
    classes.push('cursor-default');
  } else if (props.disable) {
    classes.push('!cursor-not-allowed');
  } else {
    classes.push('cursor-pointer');
  }
  switch (props.type) {
    case 'moreSetting':
      if (props.loading) {
        classes.push('bg-dark-400 border border-dark-400 text-light-450');
      } else if (props.disable) {
        classes.push('bg-dark-400 border border-dark-400  text-text-dark-100');
      } else if (props.active) {
        classes.push('bg-primary-300 border border-primary-300 text-light-100');
      } else {
        classes.push('bg-dark-400 border border-dark-400 text-light-450 hover:bg-dark-100');
      }
      break;
    case 'primary':
      if (props.loading) {
        classes.push('bg-primary-300 border border-primary-300 text-light-100');
      } else if (props.disable) {
        classes.push('bg-light-300 border border-light-300 text-text-light-100');
      } else {
        classes.push('bg-primary-300 border border-primary-300 text-text-dark-500 hover:bg-primary-300');
      }
      break;
    case 'secondary':
      classes.push(
        `bg-text-light-500 border border-transparent text-text-dark-500 hover:bg-dark-250 ${
          props.lightMode ? 'text-text-light-500 hover:bg-light-450 group-hover/button:bg-light-450 bg-light-300' : ''
        }`,
      );
      if (props.loading) {
        classes.push(
          'dark:bg-dark-300 border border-dark-300 dark:text-light-100 bg-light-300 bg-light-300 text-dark-400 hover:bg-light-450',
        );
      } else if (props.disable) {
        classes.push(
          'dark:bg-dark-300 border border-dark-300 dark:text-text-dark-100 !text-text-light-100 bg-light-300',
        );
      } else if (props.active) {
        classes.push(
          'dark:bg-dark-200 border border-dark-200 dark:text-light-100 dark:hover:bg-dark-200 bg-light-300 text-dark-400 hover:bg-light-450',
        );
      } else {
        classes.push('');
      }
      break;

    case 'darkBlue':
      if (props.loading) {
        classes.push(
          'dark:bg-primary-300/15 border border-primary-300/0 dark:text-light-100 bg-light-300 bg-light-300 text-dark-400 hover:bg-light-450',
        );
      } else if (props.disable) {
        classes.push('dark:bg-primary-300/15 border border-primary-300/0 dark:text-text-dark-100 bg-light-300');
      } else if (props.active) {
        classes.push(
          'dark:bg-dark-200 border border-dark-200 dark:text-light-100 dark:hover:bg-dark-200 bg-light-300 text-dark-400 hover:bg-light-450',
        );
      } else {
        classes.push(
          'bg-primary-300/15 text-primary-250 border border-primary-300/0 hover:bg-dark-200 hover:border-dark-200',
        );
      }
      break;

    case 'tertiary':
      classes.push(
        `bg-dark-300 border border-transparent text-light-200  hover:bg-dark-150 ${
          props.lightMode ? 'text-dark-400 bg-transparent hover:bg-light-250 group-hover/button:bg-light-250' : ''
        }`,
      );
      if (props.loading) {
        classes.push(
          `relative before:-z-0 rounded-xl text-light-200 hover:before:bg-light-100/20 bg-dark-300 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] before:rounded-xl ${
            props.lightMode ? 'text-dark-400' : ''
          }`,
        );
      } else if (props.disable) {
        classes.push(`rounded-xl !text-text-dark-100 bg-dark-300 ${props.lightMode ? '!text-text-light-100' : ''}`);
      } else if (props.active) {
        classes.push('dark:bg-dark-250 bg-dark-150 border border-transparent');
      } else {
        classes.push(
          `relative before:z-0 rounded-xl hover:bg-dark-150 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] before:rounded-xl`,
        );
      }
      break;
    case 'amber':
      if (props.loading) {
        classes.push(
          'relative before:!z-0 rounded-xl text-dark-400 hover:before:bg-light-100/20 bg-yellow-300 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] before:rounded-xl',
        );
      } else if (props.disable) {
        classes.push('rounded-xl text-text-light-100');
      } else {
        classes.push(
          'relative before:!z-0 rounded-xl text-dark-400 hover:before:bg-light-100/20 bg-yellow-300 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] before:rounded-xl',
        );
      }
      break;
    case 'darkGhost':
      if (props.loading) {
        classes.push(
          'relative before:-z-0 rounded-xl text-light-450 hover:before:bg-light-100/20 bg-dark-500 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] before:rounded-xl',
        );
      } else if (props.disable) {
        classes.push('rounded-xl text-text-light-100');
      } else {
        classes.push(
          'relative before:z-0 rounded-xl text-light-450 hover:before:bg-light-100/20 bg-dark-500 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:rounded-xl',
        );
      }
      break;
    case 'grey':
      if (props.loading) {
        classes.push('bg-light-300 border border-light-300 text-dark-400');
      } else if (props.disable) {
        classes.push('bg-light-300 border border-light-300 text-text-light-100');
      } else {
        classes.push('bg-light-300 border border-light-300 text-dark-400 hover:bg-light-450');
      }
      break;
    case 'outline':
      if (props.loading) {
        classes.push('border border-dark-100 bg-transparent text-dark-400');
      } else if (props.disable) {
        classes.push('border border-dark-100 bg-transparent text-text-light-100');
      } else {
        classes.push('border border-dark-100 bg-transparent text-dark-400 hover:bg-light-450');
      }
      break;
    case 'invert':
      if (props.loading) {
        classes.push(
          ' dark:bg-dark-400 dark:hover:bg-light-100  dark:hover:bg-opacity-20 hover:bg-light-450  dark:text-light-450 bg-light-100 border border-light-100  text-dark-400',
        );
      } else if (props.disable) {
        classes.push(
          'dark:bg-dark-400 border border-light-100  bg-light-100 dark:text-text-dark-100 text-text-light-100',
        );
      } else {
        classes.push(
          'dark:bg-dark-400 dark:text-light-450 dark:hover:bg-light-100  dark:hover:bg-opacity-20  hover:bg-light-450 bg-light-100 border border-light-100 text-dark-400',
        );
      }
      break;
    case 'ghost':
      classes.push(
        `bg-transparent border border-transparent text-light-450  hover:bg-dark-250 ${
          props.lightMode ? 'text-dark-400 hover:bg-light-450 group-hover/button:bg-light-450' : ''
        }`,
      );
      if (props.loading) {
        classes.push(`!bg-transparent !border !border-transparent`);
      } else if (props.disable) {
        classes.push(
          `border border-transparent hover:bg-transparent hover:border-transparent  ${
            props.lightMode ? '!text-text-light-100' : '!text-text-dark-100'
          }
          `,
        );
      } else if (props.active) {
        classes.push('dark:bg-dark-250 bg-dark-250 border border-transparent');
      } else {
        classes.push(`dark:hover:!bg-dark-250`);
      }
      break;
    case 'danger':
      if (props.loading) {
        classes.push(
          'bg-red-300 relative before:z-0 text-light-100 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] text-light-100 hover:before:bg-light-100/20',
        );
      } else if (props.disable) {
        classes.push('bg-dark-400 border border-dark-400 text-text-dark-100');
      } else {
        classes.push(
          'relative before:z-0 bg-red-300 before:h-full before:w-full before:absolute before:top-0 before:left-0 before:z-[-1] text-light-100 hover:before:bg-light-100/20 hover:text-light-450',
        );
      }
      break;
    case 'dangerGhost':
      if (props.loading) {
        classes.push('bg-transparent border border-transparent text-red-200');
      } else if (props.disable) {
        classes.push('bg-transparent border border-transparent text-text-dark-100');
      } else {
        classes.push('bg-transparent border border-transparent text-red-200 hover:bg-red-300/20');
      }
      break;
  }
  switch (props.size) {
    case 'large':
      classes.push('h-40 text-14 leading-normal');

      if (!props.onlyIcon) {
        classes.push('px-16');
      }
      break;
    case 'medium':
      classes.push('h-36 text-14 leading-normal');

      if (!props.onlyIcon) {
        classes.push('px-16');
      }
      break;
    case 'semi-medium':
      classes.push('h-32 text-14 leading-6');

      if (!props.onlyIcon) {
        classes.push('px-16');
      }
      break;
    case 'small':
      classes.push('h-24 text-12 leading-normal');

      if (!props.onlyIcon) {
        classes.push('px-16');
      }
      break;
  }
  if (props.onlyIcon) {
    classes.push('aspect-square');
  }
  return classes.join(' ');
});

//=================== Methods =====================
const handleClick = (e: Event) => {
  if (props.loading) {
    return;
  }
  emit('click', e);
};
</script>
<template>
  <button
    :data-type="type"
    :type="buttonType"
    :class="
      cn(
        'cursor-pointer select-none outline-none disabled:cursor-not-allowed',
        computedClass,
        buttonClasses,
        props.class,
      )
    "
    data-test="editor-control-GButtonV2"
    :disabled="disable && !hideAttributeDisabled"
    @click="handleClick">
    <span
      v-if="loading"
      :class="
        cn(
          {
            'mr-8': !onlyIcon,
            'w-16': size == 'large',
            'w-14': size == 'medium',
            'w-13': size == 'semi-medium',
            'w-12': size == 'small',
          },
          loadingClass,
        )
      ">
      <g-base-icon
        class="animate-spin delay-[100s] ease-in-out"
        :name="loadingIcon ? loadingIcon : 'loading'"
        width="16"
        height="16"></g-base-icon>
    </span>
    <template v-if="!loading || !onlyIcon">
      <slot name="prefix"></slot>
      <slot></slot>
      <slot name="suffix"></slot>
    </template>
  </button>
</template>
