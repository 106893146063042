<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.5 5.99857C9.49926 4.61819 8.37969 3.49977 6.99933 3.50044C5.61894 3.50088 4.5 4.62003 4.5 6.00044C4.5 7.38115 5.61929 8.50044 7 8.50044C8.38045 8.50044 9.49985 7.382 9.5 6.00124V5.99857ZM6.99973 4.50044C7.82787 4.49999 8.49956 5.17097 8.5 5.9991V6.0007C8.5 6.82896 7.8286 7.50044 7 7.50044C6.17157 7.50044 5.5 6.82886 5.5 6.00044C5.5 5.17216 6.17146 4.50066 6.99973 4.50044Z"
    fill="#AAAAAA" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.99893 1.50114C3.53122 1.50122 1.24467 4.11658 0.436236 5.20825C0.0849418 5.68262 0.0872007 6.32038 0.439953 6.79254C1.2535 7.88147 3.55485 10.4997 6.99894 10.4997C10.5043 10.4997 12.7754 7.87652 13.5733 6.78505C13.9177 6.31398 13.9152 5.68417 13.5687 5.2156C12.7643 4.1279 10.4742 1.50106 6.99893 1.50114ZM1.23987 5.80338C2.01624 4.755 4.04151 2.50121 6.99896 2.50114C9.96311 2.50107 11.9919 4.76517 12.7647 5.81019C12.8515 5.92767 12.8518 6.07755 12.766 6.19488C12.0031 7.23851 9.9931 9.4997 6.99894 9.4997C4.06505 9.4997 2.02524 7.24365 1.24107 6.19402C1.15202 6.07484 1.15186 5.92222 1.23987 5.80338Z"
    fill="#AAAAAA" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.4609 0.616295C13.6731 0.870861 13.6387 1.2492 13.3841 1.46134L1.38411 11.4613C1.12954 11.6735 0.751206 11.6391 0.539067 11.3845C0.326929 11.13 0.361323 10.7516 0.615889 10.5395L12.6159 0.539473C12.8705 0.327334 13.2488 0.361729 13.4609 0.616295Z"
    fill="#AAAAAA" />
</template>
