<script lang="ts" setup>
import type { ModeType } from '../types/common';
import { computed } from 'vue';

type Props = {
  type?: 'neutral' | 'success' | 'warning' | 'danger' | 'informative';
  mode?: ModeType;
  isNumber?: boolean;
};

const props = defineProps<Props>();

const classes = computed(() => {
  const { type, mode } = props;
  const className: string[] = [];

  switch (type) {
    case 'neutral':
      if (mode === 'dark') {
        className.push('bg-dark-500 text-dark-500');
      } else if (mode === 'light') {
        className.push('bg-light-200 text-text-light-500');
      }
      break;
    case 'success':
      if (mode === 'dark') {
        className.push('bg-green-300 text-dark-500');
      } else if (mode === 'light') {
        className.push('bg-green-100 text-green-400');
      }
      break;
    case 'warning':
      if (mode === 'dark') {
        className.push('bg-yellow-300 text-dark-500');
      } else if (mode === 'light') {
        className.push('bg-yellow-100 text-yellow-400');
      }
      break;
    case 'danger':
      if (mode === 'dark') {
        className.push('bg-red-300 text-dark-500');
      } else if (mode === 'light') {
        className.push('bg-red-100 text-red-400');
      }
      break;
    case 'informative':
      if (mode === 'dark') {
        className.push('bg-primary-300 text-dark-500');
      } else if (mode === 'light') {
        className.push('bg-primary-100 text-primary-400');
      }
      break;
  }

  return className.join(' ');
});
</script>

<template>
  <span
    class="text-12 rounded-medium font-regular inline-block h-20 px-4 leading-5"
    :class="[classes, { '!rounded-full !px-6': isNumber }]">
    <slot></slot>
  </span>
</template>
