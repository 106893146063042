<template>
  <path
    d="M8.125 7.5C8.47018 7.5 8.75 7.77982 8.75 8.125V14.375C8.75 14.7202 8.47018 15 8.125 15C7.77982 15 7.5 14.7202 7.5 14.375V8.125C7.5 7.77982 7.77982 7.5 8.125 7.5Z"
    fill="currentColor" />
  <path
    d="M11.875 7.5C12.2202 7.5 12.5 7.77982 12.5 8.125V14.375C12.5 14.7202 12.2202 15 11.875 15C11.5298 15 11.25 14.7202 11.25 14.375V8.125C11.25 7.77982 11.5298 7.5 11.875 7.5Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.75 2.875V3.75H17.5C17.8452 3.75 18.125 4.02982 18.125 4.375C18.125 4.72018 17.8452 5 17.5 5H16.25V16.125C16.25 17.5747 15.0747 18.75 13.625 18.75H6.375C4.92525 18.75 3.75 17.5747 3.75 16.125V5H2.5C2.15482 5 1.875 4.72018 1.875 4.375C1.875 4.02982 2.15482 3.75 2.5 3.75H6.25V2.875C6.25 1.97754 6.97754 1.25 7.875 1.25H12.125C13.0225 1.25 13.75 1.97754 13.75 2.875ZM5 5V16.125C5 16.8844 5.61561 17.5 6.375 17.5H13.625C14.3844 17.5 15 16.8844 15 16.125V5H5ZM7.5 3.75V2.875C7.5 2.66789 7.66789 2.5 7.875 2.5H12.125C12.3321 2.5 12.5 2.66789 12.5 2.875V3.75H7.5Z"
    fill="currentColor" />
</template>
