<template>
  <path
    d="M15.0455 3.8397C14.9859 3.655 14.7246 3.655 14.6649 3.8397L14.3917 4.68545C14.2941 4.98763 14.0588 5.22549 13.7576 5.32633L12.9216 5.6063C12.7396 5.66724 12.7396 5.92466 12.9216 5.9856L13.7576 6.26557C14.0588 6.3664 14.2941 6.60426 14.3917 6.90645L14.6649 7.7522C14.7246 7.9369 14.9859 7.9369 15.0455 7.7522L15.3187 6.90645C15.4163 6.60426 15.6517 6.3664 15.9528 6.26557L16.7889 5.9856C16.9708 5.92466 16.9708 5.66724 16.7889 5.6063L15.9528 5.32633C15.6517 5.22549 15.4163 4.98763 15.3187 4.68545L15.0455 3.8397Z"
    fill="url(#paint0_linear_5189_39651)" />
  <path
    d="M10.0157 4.6343C9.9262 4.35725 9.53422 4.35725 9.44473 4.6343L8.49438 7.57649C8.20156 8.48304 7.49557 9.19662 6.5922 9.49912L3.70474 10.466C3.43175 10.5574 3.43175 10.9436 3.70474 11.035L6.59221 12.0019C7.49557 12.3044 8.20156 13.0179 8.49438 13.9245L9.44473 16.8667C9.53422 17.1437 9.9262 17.1437 10.0157 16.8667L10.966 13.9245C11.2589 13.0179 11.9649 12.3044 12.8682 12.0019L15.7557 11.035C16.0287 10.9436 16.0287 10.5574 15.7557 10.466L12.8682 9.49912C11.9649 9.19662 11.2589 8.48304 10.966 7.57649L10.0157 4.6343Z"
    fill="url(#paint1_linear_5189_39651)" />
  <defs>
    <linearGradient
      id="paint0_linear_5189_39651"
      x1="16.9254"
      y1="13.9119"
      x2="3.49971"
      y2="13.8965"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="#874CFD" />
      <stop offset="1" stop-color="#3C38E1" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_5189_39651"
      x1="16.9254"
      y1="13.9119"
      x2="3.49971"
      y2="13.8965"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="#874CFD" />
      <stop offset="1" stop-color="#3C38E1" />
    </linearGradient>
  </defs>
</template>
