<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 14.5C1.5 14.2239 1.72386 14 2 14L15 14C15.2761 14 15.5 14.2239 15.5 14.5C15.5 14.7761 15.2761 15 15 15L2 15C1.72386 15 1.5 14.7761 1.5 14.5Z"
      fill="currentColor"></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.5 13.5C4.67157 13.5 4 12.8284 4 12L4 7C4 6.17157 4.67157 5.5 5.5 5.5L6.5 5.5C7.32843 5.5 8 6.17157 8 7L8 12C8 12.8284 7.32843 13.5 6.5 13.5L5.5 13.5ZM5 12C5 12.2761 5.22386 12.5 5.5 12.5L6.5 12.5C6.77614 12.5 7 12.2761 7 12L7 7C7 6.72386 6.77614 6.5 6.5 6.5L5.5 6.5C5.22386 6.5 5 6.72386 5 7L5 12Z"
      fill="currentColor"></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 12C9 12.8284 9.67157 13.5 10.5 13.5L11.5 13.5C12.3284 13.5 13 12.8284 13 12L13 4C13 3.17157 12.3284 2.5 11.5 2.5L10.5 2.5C9.67157 2.5 9 3.17157 9 4L9 12ZM10.5 12.5C10.2239 12.5 10 12.2761 10 12L10 4C10 3.72386 10.2239 3.5 10.5 3.5L11.5 3.5C11.7761 3.5 12 3.72386 12 4L12 12C12 12.2761 11.7761 12.5 11.5 12.5L10.5 12.5Z"
      fill="currentColor"></path>
  </svg>
</template>
