<template>
  <rect x="0.666626" width="77.3333" height="52" rx="3" fill="#212121" />
  <rect x="8.66663" y="12" width="61.3333" height="28" rx="3" fill="#4A4A4A" />
  <rect x="12.6666" y="23" width="23.3333" height="6" rx="3" fill="#AAAAAA" />
  <g clip-path="url(#clip0_69_74184)">
    <path
      d="M43.731 21.3594C43.8411 21.1365 44.159 21.1365 44.2691 21.3594L45.594 24.0441C45.6377 24.1327 45.7222 24.1941 45.8199 24.2083L48.7827 24.6388C49.0287 24.6745 49.127 24.9769 48.9489 25.1505L46.8051 27.2402C46.7344 27.3092 46.7021 27.4085 46.7188 27.5058L47.2249 30.4566C47.2669 30.7016 47.0097 30.8885 46.7896 30.7728L44.1396 29.3796C44.0522 29.3337 43.9478 29.3337 43.8604 29.3796L41.2105 30.7728C40.9904 30.8885 40.7331 30.7016 40.7752 30.4566L41.2813 27.5058C41.298 27.4085 41.2657 27.3092 41.195 27.2402L39.0511 25.1505C38.8731 24.9769 38.9713 24.6745 39.2174 24.6388L42.1801 24.2083C42.2779 24.1941 42.3623 24.1327 42.406 24.0441L43.731 21.3594Z"
      fill="#AAAAAA" />
  </g>
  <path
    d="M60.2613 27.376C60.1169 27.5413 59.8829 27.5413 59.7386 27.376L57.8589 25.2228C57.6261 24.956 57.791 24.5 58.1203 24.5H61.8796C62.2089 24.5 62.3738 24.956 62.1409 25.2228L60.2613 27.376Z"
    fill="#AAAAAA" />
  <defs>
    <clipPath id="clip0_69_74184">
      <rect width="12" height="12" fill="white" transform="translate(37.9999 20)" />
    </clipPath>
  </defs>
</template>
