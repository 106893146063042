<script lang="ts" setup>
import { GBaseIcon, GPopover } from '@gem/uikit';
import type { GSidebarTabItemProps } from '../types';
import GSidebarTabItemButton from './GSidebarTabItemButton.vue';

const emit = defineEmits<{
  (e: 'clickItem', event: Event): void;
}>();
const props = withDefaults(defineProps<GSidebarTabItemProps>(), {
  name: 'Item',
  icon: 'more-setting-20',
  iconViewBox: '0 0 20 20',
  childrens: undefined,
  link: '',
  active: false,
  width: '40px',
  highlight: false,
});

const handleClick = (e: Event) => {
  emit('clickItem', e);
};

const handleActionChildrenItem = (e: Event, key?: string, callback?: GSidebarTabItemProps['onClick']) => {
  if (callback) {
    e.preventDefault();
    e.stopPropagation();
    callback(key);
  }
};
</script>

<template>
  <template v-if="!childrens">
    <GSidebarTabItemButton v-bind="props" :active="active" @click="handleClick" />
  </template>
  <template v-else>
    <GPopover
      :padding-right="4"
      trigger="click"
      :prevent-default="true"
      :stop-propagation="true"
      :closeable="true"
      :overlay="false"
      placement="right-end"
      cls="bg-dark-400 shadow-dark-device p-8 min-w-[178px]"
      :has-arrow="false">
      <template #default="{ open, close }">
        <GSidebarTabItemButton v-bind="props" :active="active || open" @click="close" />
      </template>
      <template #content="{ close }">
        <a
          v-for="chid in childrens"
          :key="chid.icon"
          :href="chid.link"
          target="_blank"
          class="hover:bg-dark-250 text-text-dark-500 group flex cursor-pointer items-center gap-x-12 rounded-xl p-8 no-underline"
          @click="
            (e) => {
              close();
              handleActionChildrenItem(e, chid.key, chid.onClick);
            }
          ">
          <GBaseIcon
            :name="(chid?.icon as any)"
            width="20"
            height="20"
            view-box="0 0 20 20"
            class="text-text-dark-300 group-hover:text-light-200"></GBaseIcon>
          <div class="group-hover:text-light-200 font-regular text-[12px] leading-[20px]">
            {{ chid.name }}
          </div>
        </a>
      </template>
    </GPopover>
  </template>
</template>
