<template>
  <rect width="66" height="48" rx="3" fill="#212121" />
  <path d="M9 13C9 11.3431 10.3431 10 12 10H54C55.6569 10 57 11.3431 57 13V21H9V13Z" fill="currentColor" />
  <path
    d="M13 15.5C13 14.6716 13.6716 14 14.5 14H39.5C40.3284 14 41 14.6716 41 15.5C41 16.3284 40.3284 17 39.5 17H14.5C13.6716 17 13 16.3284 13 15.5Z"
    fill="currentColor"
    class="gp-icon-highlight-sub" />
  <path
    d="M43 15.5C43 14.6716 43.6716 14 44.5 14H51.5C52.3284 14 53 14.6716 53 15.5C53 16.3284 52.3284 17 51.5 17H44.5C43.6716 17 43 16.3284 43 15.5Z"
    class="gp-icon-highlight-sub"
    fill="currentColor" />
  <rect x="9.5" y="10.5" width="47" height="27" rx="2.5" stroke="#424242" />
</template>
