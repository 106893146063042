<template>
  <path
    d="M0 8C0 3.58172 3.58172 0 8 0H108C112.418 0 116 3.58172 116 8V64C116 68.4183 112.418 72 108 72H8C3.58172 72 0 68.4183 0 64V8Z"
    fill="#212121" />
  <path
    d="M19 24C19 19.5817 22.5817 16 27 16H89C93.4183 16 97 19.5817 97 24V48C97 52.4183 93.4183 56 89 56H27C22.5817 56 19 52.4183 19 48V24Z"
    fill="#5E5E5E" />
  <circle cx="52.5" cy="32" r="2" fill="#AAAAAA" />
  <path
    d="M50.6969 40.6453L52.9097 38.0595C52.9846 37.9718 53.0772 37.9008 53.1814 37.8512C53.2856 37.8017 53.3991 37.7746 53.5145 37.7718C53.6299 37.769 53.7446 37.7905 53.8511 37.835C53.9576 37.8795 54.0535 37.9458 54.1326 38.0298L55.1673 39.1271L59.212 34.2946C59.2912 34.1999 59.3908 34.1243 59.5033 34.0733C59.6157 34.0224 59.7383 33.9974 59.8618 34.0002C59.9852 34.0031 60.1065 34.0337 60.2165 34.0898C60.3265 34.1459 60.4224 34.2261 60.4972 34.3243L65.3329 40.6772C65.4254 40.7989 65.482 40.9441 65.4964 41.0962C65.5107 41.2484 65.4823 41.4016 65.4144 41.5385C65.3464 41.6754 65.2415 41.7907 65.1115 41.8713C64.9815 41.952 64.8316 41.9948 64.6786 41.995L51.322 42C51.1649 41.9999 51.0111 41.9549 50.8789 41.8703C50.7466 41.7857 50.6413 41.6649 50.5755 41.5224C50.5098 41.3799 50.4862 41.2216 50.5077 41.0661C50.5292 40.9107 50.5949 40.7646 50.6969 40.6453Z"
    fill="#AAAAAA" />
</template>
