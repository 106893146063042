<template>
  <g clip-path="url(#clip0_9799_481875)">
    <path
      d="M0 3C0 1.34315 1.34315 0 3 0H93C94.6569 0 96 1.34315 96 3V69C96 70.6569 94.6569 72 93 72H3C1.34315 72 0 70.6569 0 69V3Z"
      fill="#333333" />
    <g clip-path="url(#clip1_9799_481875)">
      <rect x="12" y="4" width="72" height="24" rx="2" fill="#474747" />
      <rect x="12" y="31" width="72" height="24" rx="2" fill="#474747" />
      <rect x="16" y="35" width="24" height="16" rx="1" fill="#757575" />
      <path
        d="M44 39.5C44 38.3954 44.8954 37.5 46 37.5H74C75.1046 37.5 76 38.3954 76 39.5C76 40.6046 75.1046 41.5 74 41.5H46C44.8954 41.5 44 40.6046 44 39.5Z"
        fill="#757575" />
      <path
        d="M44 46.5C44 45.3954 44.8954 44.5 46 44.5H58C59.1046 44.5 60 45.3954 60 46.5C60 47.6046 59.1046 48.5 58 48.5H46C44.8954 48.5 44 47.6046 44 46.5Z"
        fill="#757575" />
      <rect x="12" y="58" width="72" height="24" rx="2" fill="#474747" />
    </g>
  </g>
  <defs>
    <clipPath id="clip0_9799_481875">
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H93C94.6569 0 96 1.34315 96 3V69C96 70.6569 94.6569 72 93 72H3C1.34315 72 0 70.6569 0 69V3Z"
        fill="white" />
    </clipPath>
    <clipPath id="clip1_9799_481875">
      <rect width="96" height="72" fill="white" />
    </clipPath>
  </defs>
</template>
