<template>
  <path
    d="M0 8C0 3.58172 3.58172 0 8 0H58C62.4183 0 66 3.58172 66 8V40C66 44.4183 62.4183 48 58 48H8C3.58172 48 0 44.4183 0 40V8Z"
    fill="#212121" />
  <circle cx="33" cy="13.25" r="5.25" stroke="#757575" stroke-width="1.5" />
  <circle cx="33" cy="13.25" r="3" fill="#757575" />
  <rect x="9.5" y="24.25" width="47" height="3.5" rx="1.75" fill="#424242" />
  <rect x="9.5" y="30.75" width="47" height="3.5" rx="1.75" fill="#424242" />
  <rect x="9.5" y="37.25" width="33" height="3.5" rx="1.75" fill="#424242" />
</template>
