<template>
  <path
    d="M0 8C0 3.58172 3.58172 0 8 0H69C73.4183 0 77 3.58172 77 8V44C77 48.4183 73.4183 52 69 52H8C3.58172 52 0 48.4183 0 44V8Z"
    fill="#212121" />
  <rect x="8" y="12" width="61" height="28" rx="3" fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M40.2139 19.4093L40.5137 21.0338C41.0446 21.2302 41.5362 21.502 41.9741 21.8355L43.6413 21.2559C43.8668 21.1774 44.1164 21.2695 44.237 21.4756L45.4315 23.5167C45.5568 23.7308 45.5062 24.0044 45.3126 24.1595L44.0134 25.2001C44.0546 25.4611 44.0759 25.7283 44.0759 26.0002C44.0759 26.2721 44.0546 26.5393 44.0134 26.8002L45.3126 27.8408C45.5062 27.9959 45.5568 28.2695 45.4315 28.4836L44.237 30.5247C44.1164 30.7308 43.8668 30.8228 43.6413 30.7444L41.9743 30.1648C41.5364 30.4984 41.0447 30.7702 40.5137 30.9667L40.2139 32.591C40.1702 32.8281 39.9634 33.0003 39.7222 33.0003H37.2778C37.0366 33.0003 36.8298 32.8281 36.7861 32.591L36.4864 30.9669C35.9552 30.7704 35.4634 30.4985 35.0254 30.1649L33.3587 30.7444C33.1332 30.8228 32.8836 30.7308 32.763 30.5247L31.5685 28.4836C31.4432 28.2695 31.4938 27.9959 31.6874 27.8408L32.9862 26.8005C32.945 26.5395 32.9237 26.2722 32.9237 26.0002C32.9237 25.7282 32.945 25.4609 32.9863 25.1998L31.6874 24.1595C31.4938 24.0044 31.4432 23.7308 31.5685 23.5167L32.763 21.4756C32.8836 21.2695 33.1332 21.1774 33.3587 21.2559L35.0257 21.8354C35.4636 21.5019 35.9553 21.2301 36.4863 21.0337L36.7861 19.4093C36.8298 19.1721 37.0366 19 37.2778 19H39.7222C39.9634 19 40.1702 19.1721 40.2139 19.4093ZM38.4999 28.4158C39.8735 28.4158 40.9871 27.3345 40.9871 26.0006C40.9871 24.6668 39.8735 23.5854 38.4999 23.5854C37.1262 23.5854 36.0126 24.6668 36.0126 26.0006C36.0126 27.3345 37.1262 28.4158 38.4999 28.4158Z"
    fill="#9E9E9E" />
</template>
