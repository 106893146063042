<!-- <template>
  <path
    fill="#E2E2E2"
    d="M13.707 6.293a1 1 0 0 1 0 1.414L11.414 10l2.293 2.293a1 1 0 0 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10L6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 0ZM0 10C0 4.477 4.477 0 10 0s10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10Zm10-8a8 8 0 1 0 0 16a8 8 0 0 0 0-16Z" />
</template> -->
<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.81282 3.81282C7.22991 0.395728 12.7701 0.395728 16.1872 3.81282C19.6043 7.22991 19.6043 12.7701 16.1872 16.1872C12.7701 19.6043 7.22991 19.6043 3.81282 16.1872C0.395728 12.7701 0.395728 7.22991 3.81282 3.81282ZM14.8432 4.27291C11.8965 1.77533 7.47681 1.91659 4.6967 4.6967C1.91659 7.47681 1.77533 11.8965 4.27291 14.8432L14.8432 4.27291ZM5.15679 15.7271C8.10352 18.2247 12.5232 18.0834 15.3033 15.3033C18.0834 12.5232 18.2247 8.10352 15.7271 5.15679L5.15679 15.7271Z"
    fill="currentColor" />
</template>
