<template>
  <path
    d="M0 8C0 3.58172 3.58172 0 8 0H69C73.4183 0 77 3.58172 77 8V44C77 48.4183 73.4183 52 69 52H8C3.58172 52 0 48.4183 0 44V8Z"
    fill="#212121" />
  <rect x="8" y="12" width="13.75" height="28" rx="3" fill="currentColor" />
  <path
    d="M23.75 15C23.75 13.3431 25.0931 12 26.75 12H34.5C36.1569 12 37.5 13.3431 37.5 15V37C37.5 38.6569 36.1569 40 34.5 40H26.75C25.0931 40 23.75 38.6569 23.75 37V15Z"
    fill="currentColor" />
  <path
    d="M39.5 15C39.5 13.3431 40.8431 12 42.5 12H50.25C51.9069 12 53.25 13.3431 53.25 15V37C53.25 38.6569 51.9069 40 50.25 40H42.5C40.8431 40 39.5 38.6569 39.5 37V15Z"
    fill="currentColor" />
  <path
    d="M55.25 15C55.25 13.3431 56.5931 12 58.25 12H66C67.6569 12 69 13.3431 69 15V37C69 38.6569 67.6569 40 66 40H58.25C56.5931 40 55.25 38.6569 55.25 37V15Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.9654 22.5932C15.0793 22.7175 15.0793 22.9189 14.9654 23.0432L12.2549 26L14.9654 28.9568C15.0793 29.0811 15.0793 29.2825 14.9654 29.4068C14.8515 29.5311 14.6668 29.5311 14.5529 29.4068L11.6362 26.225C11.5223 26.1007 11.5223 25.8993 11.6362 25.775L14.5529 22.5932C14.6668 22.4689 14.8515 22.4689 14.9654 22.5932Z"
    fill="#9E9E9E" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M62.0346 22.5932C62.1485 22.4689 62.3332 22.4689 62.4471 22.5932L65.3638 25.775C65.4777 25.8993 65.4777 26.1007 65.3638 26.225L62.4471 29.4068C62.3332 29.5311 62.1485 29.5311 62.0346 29.4068C61.9207 29.2825 61.9207 29.0811 62.0346 28.9568L64.7451 26L62.0346 23.0432C61.9207 22.9189 61.9207 22.7175 62.0346 22.5932Z"
    fill="#9E9E9E" />
</template>
