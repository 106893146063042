<template>
  <svg width="120" height="80" viewBox="0 0 120 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0H112C116.418 0 120 3.58172 120 8V72C120 76.4183 116.418 80 112 80H8C3.58172 80 0 76.4183 0 72V8Z"
      fill="#212121" />
    <rect x="14" y="14" width="92" height="23" rx="2.37" fill="#424242" />
    <rect x="14" y="43" width="43" height="23" rx="2.37" fill="#424242" />
    <rect x="63" y="43" width="43" height="23" rx="2.37" fill="#424242" />
  </svg>
</template>
