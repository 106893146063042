<template>
  <div
    :class="{ ' bg-light-300': isLightMode, ' bg-dark-300': !isLightMode }"
    class="absolute top-0 left-0 z-[999999] !block h-full w-full opacity-100">
    <div class="flex h-full items-center justify-center">
      <div class="flex flex-col items-center">
        <LogoLoading />
        <div class="bg-light-500 rounded-small mt-24 h-4 w-[184px]">
          <div class="bg-primary-300 rounded-small h-4" :style="{ width: loadingCount + '%' }"></div>
        </div>
      </div>
      <div class="text-text-light-300 text-14 absolute bottom-[68px] left-[50%] -translate-x-1/2">{{ title }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import LogoLoading from './LogoLoading.vue';

type Props = {
  message?: string[];
  isLightMode?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  isLightMode: false,
  message: () => [
    'If the editor is frozen, please contact our support via live chat',
    'The Image element allows you to add images to your website within a few clicks',
  ],
});

const loadingCount = ref<number>(0);
const currentStep = ref<number>(1);
const title = ref<string>('Loading Core Editor');

onMounted(() => {
  showLoading();
});

const showLoading = () => {
  let dataMessagesClone = props.message;
  if (props.message) {
    dataMessagesClone = [props.message[0]];
  }
  if (dataMessagesClone != undefined) {
    const time = 1500;
    for (let i = 0; i < dataMessagesClone.length; i++) {
      const timer = i * time;
      setStatus(i, timer);
    }
  }
  return false;
};

const setStatus = (i: number, timer: number) => {
  setTimeout(() => {
    const message = props.message[i];
    currentStep.value = i + 1;
    title.value = message;
    setLoadingProgressBar(1, 97, timer + 1500);
  }, timer);
};

const setLoadingProgressBar = (start = 1, end = 97, duration = 1500) => {
  let startTimestamp: any = null;
  const step = (timestamp: any) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    const count = Math.floor(progress * (end - start) + start);
    loadingCount.value = isNaN(count) ? 0 : count;
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
};
</script>
