<script lang="ts" setup>
import GDropdown from '../../elements/Dropdown.vue';
import GTooltip from '../../base/GTooltip.vue';
import { computed } from 'vue';

type Device = {
  label?: string;
  id: string;
  icon?: string;
};

type Props = {
  idSelected: string;
  devices: Device[];
  selectedClass?: string;
  defaultOptionClass?: string;
  isNotResponsive?: boolean;
  wrapperClass?: string;
  iconClass?: string;
};

const emits = defineEmits<{
  (e: 'select', id?: string): void;
}>();

const props = defineProps<Props>();
const currentDevice = computed(() => props.devices.find((d) => d.id === props.idSelected));
const onSelected = (deviceId: string, close: Function) => {
  emits('select', deviceId);
  close();
};
</script>

<template>
  <div class="ml-8 inline-flex gap-4">
    <g-dropdown
      class="ls:hidden bg-dark-400"
      :class="{
        '!hidden': isNotResponsive,
      }"
      trigger="click"
      placement="left"
      class-popover="rounded-none !ring-0 !right-[calc(50%_-_60px)] left-inherit group w-[156px] !bg-dark-400 shadow-dark-device">
      <template #default="{ isOpening }">
        <div
          class="after:rounded-b-medium hover:bg-dark-250 bg-dark-400 group relative flex h-40 cursor-pointer items-center justify-start rounded-xl p-4 pr-8 after:absolute after:bottom-0 after:w-full after:border-b-2 after:border-transparent after:content-['']"
          :class="{ 'outline-primary-300 outline-double outline-1': isOpening }">
          <span
            class="bg-dark-250 group-hover:bg-dark-150 inline-flex h-full w-[32px] items-center justify-center rounded-[6px]">
            <g-base-icon :name="currentDevice?.icon" width="20px" height="20px" class="text-white"></g-base-icon>
          </span>
          <button class="ml-4">
            <svg
              v-if="!isOpening"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.71967 8.46967C6.01256 8.17678 6.48744 8.17678 6.78033 8.46967L10.25 11.9393L13.7197 8.46967C14.0126 8.17678 14.4874 8.17678 14.7803 8.46967C15.0732 8.76256 15.0732 9.23744 14.7803 9.53033L10.7803 13.5303C10.4874 13.8232 10.0126 13.8232 9.71967 13.5303L5.71967 9.53033C5.42678 9.23744 5.42678 8.76256 5.71967 8.46967Z"
                fill="#AAAAAA" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.5303 12.2803C14.2374 12.5732 13.7626 12.5732 13.4697 12.2803L10 8.81066L6.53033 12.2803C6.23744 12.5732 5.76256 12.5732 5.46967 12.2803C5.17678 11.9874 5.17678 11.5126 5.46967 11.2197L9.46967 7.21967C9.76256 6.92678 10.2374 6.92678 10.5303 7.21967L14.5303 11.2197C14.8232 11.5126 14.8232 11.9874 14.5303 12.2803Z"
                fill="#AAAAAA" />
            </svg>
          </button>
        </div>
      </template>
      <template #content="{ close }">
        <div class="cursor-pointer p-4">
          <div
            v-for="device in devices"
            :key="device.id"
            class="flex flex-1 items-center rounded-xl py-6 px-8 hover:bg-white/20"
            @click="onSelected(device.id, close)">
            <span class="flex pr-12 pl-[1.1px]">
              <span class="flex h-16 w-16 items-center justify-center">
                <g-base-icon
                  v-show="currentDevice?.id === device.id"
                  name="device-active"
                  width="16px"
                  height="16px"
                  viewBox="0 0 16 16"
                  class="text-green-300"></g-base-icon>
              </span>
            </span>
            <span class="text-12 text-light-200 leading-[20px]"> {{ device.label }} </span>
          </div>
        </div>
      </template>
    </g-dropdown>
    <div
      class="ls:flex bg-dark-400 hidden w-full items-center gap-4 rounded-xl p-4"
      :class="{
        '!flex': isNotResponsive,
        [wrapperClass || '']: !!wrapperClass,
      }">
      <template v-for="item in devices" :key="item.value">
        <g-tooltip placement="bottom" class="flex h-full w-full items-center justify-center">
          <div
            class="text-12 group flex flex-1 cursor-pointer select-none items-center justify-center rounded-[6px] p-[6px] text-center font-medium transition-all"
            :class="{
              'hover:bg-dark-250': currentDevice?.id !== item.id,
              'bg-dark-250 text-light-200': currentDevice?.id === item.id,
              [selectedClass || '']: idSelected === item.id,
              [defaultOptionClass || '']: idSelected !== item.id,
            }"
            :data-test="`editor-topbar-screen-${item.id}`"
            @click="() => onSelected(item.id, () => {})">
            <span v-if="item.icon">
              <g-base-icon
                :name="item.icon"
                width="20px"
                height="20px"
                :class="{
                  'group-hover:text-text-dark-300': currentDevice?.id !== item.id,
                  [iconClass || '']: iconClass,
                }"></g-base-icon>
            </span>
            <g-base-icon v-else-if="item.label" width="20" height="20" view-box="0 0 16 16"></g-base-icon>
            <span v-else class="[&_>_svg]:h-20" v-html="item.label"></span>
          </div>
          <template #content>
            <div class="text-light-100 text-12">{{ item.label }}</div>
          </template>
        </g-tooltip>
      </template>
    </div>
  </div>
</template>
