import { computed } from 'vue';

export function useDisabled(disabled: boolean) {
  const isDisabled = computed(() => disabled);

  const containerDisableCls = computed(() => {
    if (isDisabled.value) return ['opacity-50 cursor-not-allowed'];

    return ['cursor-pointer'];
  });

  return { isDisabled, containerDisableCls };
}
