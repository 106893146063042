<template>
  <path
    d="M6.76923 14.3077C7.364 14.3077 7.84615 13.8255 7.84615 13.2308C7.84615 12.636 7.364 12.1538 6.76923 12.1538C6.17446 12.1538 5.69231 12.636 5.69231 13.2308C5.69231 13.8255 6.17446 14.3077 6.76923 14.3077Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.15385 3C3.96431 3 3 3.96431 3 5.15385V13.2308C3 15.3125 4.68754 17 6.76923 17L14.8462 17C16.0357 17 17 16.0357 17 14.8462V11.6154C17 10.6802 16.404 9.88418 15.571 9.58656C16.0232 8.76789 15.902 7.71729 15.2076 7.02288L12.9231 4.73838C12.2366 4.05187 11.202 3.92567 10.3875 4.35981C10.485 4.60552 10.5386 4.87342 10.5386 5.15385V6.36147L11.0194 5.88063C11.2297 5.67035 11.5706 5.67035 11.7809 5.88063L14.0654 8.16513C14.2757 8.37541 14.2757 8.71635 14.0654 8.92663L10.5386 12.4535V13.2308V5.15385C10.5386 3.96431 9.57415 3 8.38462 3H5.15385ZM8.38462 4.61538H5.15385C4.85646 4.61538 4.61538 4.85646 4.61538 5.15385V13.2308C4.61538 14.4203 5.57969 15.3846 6.76923 15.3846C7.95877 15.3846 8.92308 14.4203 8.92308 13.2308V5.15385C8.92308 4.85646 8.682 4.61538 8.38462 4.61538ZM9.89191 15.3846H14.8462C15.1435 15.3846 15.3846 15.1435 15.3846 14.8462V11.6154C15.3846 11.318 15.1435 11.0769 14.8462 11.0769H14.1996L9.89191 15.3846Z"
    fill="currentColor" />
</template>
