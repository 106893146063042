<template>
  <svg width="78" height="48" viewBox="0 0 78 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.666016 8C0.666016 3.58172 4.24774 0 8.66602 0H69.9993C74.4176 0 77.9994 3.58172 77.9994 8V40C77.9994 44.4183 74.4176 48 69.9994 48H8.66603C4.24775 48 0.666016 44.4183 0.666016 40V8Z"
      fill="#212121" />
    <g clip-path="url(#clip0_7274_2662)">
      <path
        d="M13.832 27.5172C13.832 28.391 12.675 28.8287 11.9983 28.2107L8.14664 24.6936C7.72716 24.3105 7.72716 23.6895 8.14664 23.3064L11.9983 19.7893C12.675 19.1713 13.832 19.609 13.832 20.4828L13.832 27.5172Z"
        fill="currentColor" />
    </g>
    <rect x="20.832" y="10" width="37" height="28" rx="3" fill="currentColor" />
    <g clip-path="url(#clip1_7274_2662)">
      <path
        d="M64.832 20.4828C64.832 19.609 65.989 19.1713 66.6657 19.7893L70.5174 23.3064C70.9369 23.6895 70.9369 24.3105 70.5174 24.6936L66.6657 28.2107C65.989 28.8287 64.832 28.391 64.832 27.5172L64.832 20.4828Z"
        fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_7274_2662">
        <rect width="12" height="12" fill="white" transform="translate(16.832 30) rotate(-180)" />
      </clipPath>
      <clipPath id="clip1_7274_2662">
        <rect width="12" height="12" fill="white" transform="translate(61.832 18)" />
      </clipPath>
    </defs>
  </svg>
</template>
