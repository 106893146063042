<template>
  <g clip-path="url(#clip0_9402_5682)">
    <rect width="90" height="48" rx="3" fill="#3B3B3B" />
    <rect x="12" y="10" width="54" height="28" rx="3" fill="#4A4A4A" />
    <rect x="22" y="21" width="34" height="6" rx="2" fill="#F9F9F9" />
    <rect x="72" y="10" width="46" height="28" rx="3" fill="#4A4A4A" />
    <rect x="79" y="21" width="32" height="6" rx="2" fill="#757575" />
  </g>
  <defs>
    <clipPath id="clip0_9402_5682">
      <rect width="90" height="48" rx="3" fill="white" />
    </clipPath>
  </defs>
</template>
