<template>
  <path
    d="M8.5 13C8.08579 13 7.75 13.3358 7.75 13.75C7.75 14.1642 8.08579 14.5 8.5 14.5H11.5C11.9142 14.5 12.25 14.1642 12.25 13.75C12.25 13.3358 11.9142 13 11.5 13H8.5Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.5 3C5.98122 3 4.75 4.23122 4.75 5.75V14.25C4.75 15.7688 5.98122 17 7.5 17H12.5C14.0188 17 15.25 15.7688 15.25 14.25V5.75C15.25 4.23122 14.0188 3 12.5 3H7.5ZM6.25 5.75C6.25 5.05964 6.80964 4.5 7.5 4.5H12.5C13.1904 4.5 13.75 5.05964 13.75 5.75V14.25C13.75 14.9404 13.1904 15.5 12.5 15.5H7.5C6.80964 15.5 6.25 14.9404 6.25 14.25V5.75Z"
    fill="currentColor" />
</template>
