<template>
  <rect x="4.5" y="0.5" width="27" height="23" rx="2.5" fill="white" />
  <circle cx="13.4167" cy="8.66667" r="1.66667" fill="#676767" />
  <path
    d="M11.914 15.8707L13.7581 13.7159C13.8205 13.6428 13.8977 13.5837 13.9845 13.5424C14.0714 13.5011 14.1659 13.4785 14.2621 13.4762C14.3583 13.4739 14.4538 13.4918 14.5426 13.5288C14.6313 13.5659 14.7113 13.6212 14.7772 13.6912L15.6394 14.6056L19.01 10.5785C19.076 10.4996 19.159 10.4366 19.2527 10.3941C19.3464 10.3516 19.4486 10.3308 19.5515 10.3332C19.6544 10.3356 19.7554 10.3611 19.8471 10.4078C19.9388 10.4546 20.0187 10.5214 20.081 10.6033L24.1108 15.8973C24.1878 15.9988 24.235 16.1197 24.247 16.2465C24.259 16.3734 24.2353 16.501 24.1786 16.6151C24.122 16.7292 24.0346 16.8252 23.9263 16.8924C23.8179 16.9596 23.693 16.9953 23.5655 16.9955L12.435 16.9997C12.3041 16.9996 12.176 16.9621 12.0657 16.8916C11.9555 16.8211 11.8677 16.7205 11.8129 16.6017C11.7581 16.4829 11.7385 16.351 11.7564 16.2214C11.7744 16.0919 11.8291 15.9702 11.914 15.8707Z"
    fill="#676767" />
  <rect x="4.5" y="0.5" width="27" height="23" rx="2.5" stroke="#D6D6D6" />
  <rect x="38.5" y="0.5" width="27" height="23" rx="2.5" fill="white" />
  <circle cx="47.4167" cy="8.66667" r="1.66667" fill="#676767" />
  <path
    d="M45.914 15.8707L47.7581 13.7159C47.8205 13.6428 47.8977 13.5837 47.9845 13.5424C48.0714 13.5011 48.1659 13.4785 48.2621 13.4762C48.3583 13.4739 48.4538 13.4918 48.5426 13.5288C48.6313 13.5659 48.7113 13.6212 48.7772 13.6912L49.6394 14.6056L53.01 10.5785C53.076 10.4996 53.159 10.4366 53.2527 10.3941C53.3464 10.3516 53.4486 10.3308 53.5515 10.3332C53.6544 10.3356 53.7554 10.3611 53.8471 10.4078C53.9388 10.4546 54.0187 10.5214 54.081 10.6033L58.1108 15.8973C58.1878 15.9988 58.235 16.1197 58.247 16.2465C58.259 16.3734 58.2353 16.501 58.1786 16.6151C58.122 16.7292 58.0346 16.8252 57.9263 16.8924C57.8179 16.9596 57.693 16.9953 57.5655 16.9955L46.435 16.9997C46.3041 16.9996 46.176 16.9621 46.0657 16.8916C45.9555 16.8211 45.8677 16.7205 45.8129 16.6017C45.7581 16.4829 45.7385 16.351 45.7564 16.2214C45.7744 16.0919 45.8291 15.9702 45.914 15.8707Z"
    fill="#676767" />
  <rect x="38.5" y="0.5" width="27" height="23" rx="2.5" stroke="#D6D6D6" />
</template>
