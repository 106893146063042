<template>
  <path
    d="M1 2C1 1.44772 1.44772 1 2 1H6C6.55228 1 7 1.44772 7 2V6C7 6.55228 6.55228 7 6 7H2C1.44772 7 1 6.55228 1 6V2Z"
    fill="#F9F9F9" />
  <path
    d="M9 10C9 9.44772 9.44772 9 10 9H14C14.5523 9 15 9.44772 15 10V14C15 14.5523 14.5523 15 14 15H10C9.44772 15 9 14.5523 9 14V10Z"
    fill="#F9F9F9" />
  <path
    d="M2 9C1.44772 9 1 9.44772 1 10V14C1 14.5523 1.44772 15 2 15H6C6.55228 15 7 14.5523 7 14V10C7 9.44772 6.55228 9 6 9H2Z"
    fill="#F9F9F9" />
</template>
