import clsx from 'clsx';
import type { ClassValue } from 'clsx';
import { extendTailwindMerge, validators } from 'tailwind-merge';
import dayjs from './dayjs';

const customTwMerge = extendTailwindMerge({
  // ↓ Add values to existing theme scale or create a new one
  theme: {
    borderRadius: ['none', 'small', 'medium', 'large', 'full'],
    spacing: ['header', 'sidebar', validators.isLength],
  },
  classGroups: {
    'font-size': [
      {
        text: ['small', 'medium', 'base', validators.isNumber],
      },
    ],
    'font-family': [{ font: ['sans'] }],
    'font-weight': [
      {
        font: ['regular', 'medium', 'semibold', 'bold'],
      },
    ],
    shadow: [{ shadow: ['2dp', '4dp', 'gs-theme-change'] }],
    z: [{ z: ['auto', 'iframe', 'context-menu', 'sidebar', 'header', 'layer', validators.isInteger] }],
  },
});

export const cn = (...inputs: ClassValue[]) => {
  return customTwMerge(clsx(inputs));
};

export function toDateString(targetString?: string, currentDate?: Date) {
  if (!targetString) {
    return '';
  }
  return dayjs(currentDate || new Date()).to(dayjs(targetString));
}
